import React from 'react';
import Helmet from 'react-helmet';

import BookModal from '../components/modals/BookModal';

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
    
}

export default class DecomposedPackagePage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            appointmentType: "decomposed",
            mobile: ($(window).width() <= 736)? true : false
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.showBookModal = this.showBookModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });              
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    showBookModal(){
        this.setState(()=> ({showModal: true}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Decomposed Package | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/decomposedpackage"/>
                </Helmet>
                <article id="main"  >

                  <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                      <div className="landing2-bg" style={imgDiv}>  
                        <div style={overlay}>
                            <img id="landingimage" className="lazy" src={require("../images/decomposed-wide.jpg")} />
                        </div>                    
                      </div>
                      <div id="bannertext">
                          <div className="bannermidtext">
                          <header className="heavy-text">
                            <h2>Decomposed
                            </h2>
                          </header>
                          <br/>
                          <p>Escape The <span className="mirage-teal">Mansion</span> of a Demented Composer</p>
                          </div>
                      </div>
                  </section>
                  
                        <article className="wrapper style3 special no-border" style={{padding:'0em 2em 1em 2em'}}>
                           <section>                    
                                <div className="inner">
                                  <section style={{margin:'0em 0em 2em 0em'}}>
                                      <div className="row events-occasions" >
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  2-5
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Players</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  60
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">minutes</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  14+
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Ages</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  $30
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Per Player</span>
                                              </p>
                                          </div>
                                      </div>
                              </section>
                                </div>
                            </section>
                        </article>

                            <section id="two" className="wrapper alt style2">
                                <section className="spotlight">
                                    <div className="image"><img src={require("../images/decomposed-seated.jpg")} alt="" /></div><div className="content">
                                       <h2>Seated VR Escape Experience</h2>
                                       <p></p>
                                       <p>Solve mind-bending puzzles and impossible contraptions all from the comfort of your own seat. You and your friends have been 
                                       transformed into a ghost, and the only way to get your real body back is to escape.
                                       </p>
                                       <a id="gamepage-book" className="button small special" onClick={this.showBookModal} alt="" >Book Decomposed</a>

                                    </div>
                                </section>
                                <section className="spotlight">
                                    <div className="image"><img src={require("../images/decomposed-elevator.jpg")} alt="" /></div><div className="content">
                                       <h2>Journey Through Han's Mansion</h2>
                                       <p></p>
                                       <p>With every twist and turn, you question what is real and where you truly are. An atmospheric experience only possible in Virtual Reality.
                                        </p>
                                    </div>
                                </section>
                                <section className="spotlight">
                                    <div className="image"><img src={require("../images/decomposed-button.jpg")} alt="" /></div><div className="content">
                                       <h2>Mind-Bending puzzles</h2>
                                       <p></p>
                                       <p>The normal laws of reality don't apply in Han's Mansion. You will find contraptions, puzzles, and labyrinths that defy all human logic, yet
                                       make perfect sense. Can you work together to escape the Mansion, or will you remain trapped forever?
                                        </p>
                                    </div>
                                </section>
                            </section>

                    <section className="wrapper style5">
                        <div className="inner">
                          <section>
                          <header className="major align-center ">
                          <h3 style={{color:'#2E3842'}}>Experience Features</h3>
                          </header>
                          <div className="row game-features">
                            <div className="col-sm-12 col-md-4 $(medium) align-center">
                              <img className="lazy" src={require("../images/vrheadset.png")}/>
                              <h3>Virtual<br/>Multiplayer</h3>
                              <p>Explore and interact in the same virtual world, with friends or family.
                                 <a className="hide" href="" style={{borderBottom: '1px solid'}}>Learn More</a></p>
                            </div>
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/collaborative.png")}/>
                              <h3>Collaborative<br/> Adventure</h3>
                              <p>Communicate and collaborate in order to solve the challenges and puzzles that await you.</p>
                            </div>
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/topscore.png")}/>
                              <h3>Compete For<br/>Fastest Time</h3>
                              <p>The clock is ticking... will your group be the fastest to escape?</p>
                            </div>
                          </div>
                          </section>
                        </div>
                    </section>

                <article className="wrapper style3 special no-border" style={{padding:'2em 2em 1em 2em'}}>
                   <section>                    
                        <div className="inner">
                            <section style={{margin:'2em 0em 2em 0em'}}>
                                    <div className="row events-occasions" >
                                        <div className="col-sm-12 col-md-6 $(medium) align-center">
                                           <img src={require('../images/pricestamp.png')} style={{width:'50%'}}/>
                                            <br/><br/>
                                            <h5 style={{
                                                textTransform: 'capitalize',
                                                fontSize: '1.2em',
                                                }}>Price</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '1.2em',
                                                }}>$30 per player <br/><br/><br/>
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 $(medium) align-center">
                                           <img src={require('../images/moneybackguaranteestamp.png')} style={{width:'50%'}}/>
                                           <br/><br/>
                                           <h5 style={{
                                                textTransform: 'capitalize',
                                                fontSize: '1.2em',
                                                }}>Guarantee</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '1.2em'
                                            }}>We are so sure you will totally love the experience that we offer a 100% refund if you are not completely satisfied.</p>
                                        </div>
                                    </div>
                            </section>
                        </div>
                    </section>
                </article>


                <section id="one" className="wrapper style2 special">
                    <div class="inner">
                        <h2>Book Decomposed</h2>
                        <br/>
                        <a id="gamepage-book" className="button small special" onClick={this.showBookModal} alt="" >Book Now!</a>
                    </div>
                </section>

            </article>
            <BookModal 
            appointmentType="decomposed"
            isGroupReservation={false}
            showModal={this.state.showModal}
            exitModal={this.exitModal}
            />
        </div>
        )
    }
}


/*Removed Rows */

/* 
<tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-clock-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td style="
						                           vertical-align: middle;
						                           ">
						                           <div style="
						                              ">
						                              <h5 style="text-transform: capitalize;">Length</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">
						                                 30 minute reservation<br>
						                                 * 5 minute introduction &amp; virtual training <br>
						                                 * 20 minute game experience <br>
						                                 * 5 minute back-to-reality &amp; debrief
						                              </p>
						                           </div>
						                        </td>
                                             </tr>
*/

/* <tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-star-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td>
						                           <div>
						                              <h5 style="
						                                 text-transform: capitalize;
						                                 ">Difficulty</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">Available in casual or hardcore mode</p>
						                           </div>
						                        </td>
                                             </tr>
*/
