import React from 'react';
import Modal from 'react-modal';
import FacebookPlayer from 'react-facebook-player';


export default class WatchTrailerModal extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showLoading: true,
            screenWidth: $(window).width()/2
        }
        this.loaded = this.loaded.bind(this);
    }
    componentDidMount(){
    }
    loaded(){
        {this.setState(() =>({showLoading: false}));}
    }

    componentWillUpdate(){
     
    }

    render(){
        const fixWidthBug={
            width:this.state.screenWidth+'px'
        }
        return(
            <Modal
                isOpen={this.props.showModal}
                onRequestClose= {this.props.exitModal}
                ariaHideApp={false}
                closeTimeoutMS={200}
                className="videomodal"
                overlayClassName="overlay"
                >
                <div className="iziModal-wrapper">
                    {this.state.showLoading &&
                    <div class="loader">Loading...</div>}
                    <div style={fixWidthBug}>
                    <FacebookPlayer
                    appId={'118069268859718'}                           // (required) Your Facebook App ID. Ref: http://bit.ly/1GNA0AN
                    videoId={'1229920583778562'}                                  // (required) Video´s ID Ref: http://bit.ly/1ysgVu4
                    //id={ string }                                       // Element ID. Required if you wanna use more than one video in the same page.
                    //className={ string }                                // Element class.
                    /* ATTRIBUTES. Ref: http://bit.ly/29OOzWZ */
                    allowfullscreen={ true }
                    autoplay={ true }
                    width={this.state.screenWidth}
                    //showText={ true }
                    //showCaptions={ boolean }
                    /* EVENTS. Ref: http://bit.ly/29JaA7J */
                    onReady={this.loaded}                                // Returns a player object to be used for controlling
                    //onStartedPlaying={this.loaded}
                    //onPaused={ function }
                    //onFinishedPlaying={ function }
                    //onStartedBuffering={ function }
                    //onFinishedBuffering={ function }
                    //onError={ function }
                    />
                    </div>
                </div>
            </Modal>  
        )  
    }
};