import React from 'react';
import LocationChooser from './modals/LocationChooser';

const FootStyle = {
  paddingTop:"1rem",
  paddingBottom:"1rem",
  backgroundColor:"black"
}

export default class Footer extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        showModal: false,
        mobile: ($(window).width() <= 763)? true : false,
        phoneNumberStr: '1-833-568-5234',
        phoneNumber: '18335685234',
        email: 'hello@miragevr.ca'
      }
      this.openModal = this.openModal.bind(this);
      this.exitModal = this.exitModal.bind(this);
    }

    openModal(){
      this.setState(()=> ({showModal: true}));
    }

    exitModal(){
      this.setState(()=> ({showModal: false}));
    }

    componentDidMount() {
      if ( window.location.pathname.indexOf('ississauga') > -1 ){
        this.setState(()=> ({
          phoneNumberStr: '289-326-1964',
          phoneNumber: '12893261964',
          email: 'mississauga@miragevr.ca'
        }));
      } else if ( window.location.pathname.indexOf('urlington') > -1 ){
        this.setState(()=> ({
          phoneNumberStr: '289-326-1964',
          phoneNumber: '12893261964',
          email: 'hello@miragevr.ca'
        }));
      } else if ( window.location.pathname.indexOf('itchener') > -1 ){
        this.setState(()=> ({
          phoneNumberStr: '226-898-1261',
          phoneNumber: '12268981261',
          email: 'kw@miragevr.ca'
        }));
      }  
    }

    render(){
      return(
          <div>
            <footer id="footer" style={FootStyle}>
              <ul className="copyright" style={{margin:"0px"}}>
                {/* 
                <li>
                    <a href=""><span className="icon fa-angle-down"></span> More</a>
                    <ul>
                      <li><a href="/contact">Contact</a></li>
                      <li><a href="/partner">Partner With Us</a></li>
                      <li><a href="http://goo.gl/NTVK2b">Press</a></li>
                      <li><a href="/about-us">About</a></li>
                    </ul>
                </li>
                */}
                <li><a href="/contact">Contact</a></li>
                <li><a href="/partner">Partnership</a></li>
                <li><a href="http://goo.gl/NTVK2b">Press</a></li>
                <li><a href="/about-us">About</a></li>
                <li><a href="/blog">Blog</a></li>
                </ul>
                <br></br>
                <ul className="copyright" style={{margin:"0px"}}>
                <li>
                  <a href="https://www.facebook.com/entermirage" className="icon fa-facebook"><span className="label">Twitter</span></a>
                  <a href="https://www.twitter.com/entermirage" className="icon fa-twitter"><span className="label">Facebook</span></a>
                  <a href="https://www.instagram.com/entermirage" className="icon fa-instagram"><span className="label">Instagram</span></a>
                </li>
                <li className="icon fa-phone">
                  <a href={'tel:'+this.state.phoneNumber}><span className="label"> {this.state.phoneNumberStr}</span></a>
                </li>
                <li className="icon fa-envelope"> {this.state.email}</li>
                <li id="location" style={{cursor: "pointer"}} className="icon fa-map-marker"><span  onClick={this.openModal}> Directions</span></li>
                <li>© Mirage VR Inc. 2019</li>
              </ul>
            </footer>
            <LocationChooser
            showModal={this.state.showModal}
            exitModal={this.exitModal}
            mobile={this.state.mobile}
            showMap = {true}
            />
          </div>
      )
    }
}

