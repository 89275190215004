import React from 'react';
import Helmet from 'react-helmet';

import BookModal from '../components/modals/BookModal';

export default class SherwoodForestPage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            mobile: ($(window).width() <= 736)? true : false
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });              
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Sherwood Forest| Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/sherwood"/>
                </Helmet>
                <article id="main"  >
                    <header className="sherwood-bg">
                        <div className="heavy-text major">
                            <h1>Sherwood <span style={{color:'seagreen'}}>Forest</span></h1>
                        </div>
                    </header>

                    <section className="wrapper style5" style={{padding: '3em 0 0 0 ! important'}}>
                        <div className="" style={{
                            marginLeft: '3em',
                            marginRight: '3em'
                        }}>
                            <section>
                                <div className="row">
                                    <div className="col-12 col-sm-3 col-lg-3">
                                        <span className="image fit"><img src={require("../images/sherwood-5.jpg")} alt=""/></span>
                                    </div>
                                    <div className="col-12 col-sm-9 col-lg-5">
                                        <h3>Description</h3>
                                        <p>The once healthy Sherwood Forest has been cast a dark shadow. An evil being has manipulated the creatures of the forest into doing its bidding. The obelisk that has protected the forest for centuries is now being attacked by the very creatures it has been protecting. Will you be the brave souls to save Sherwood Forest?
                                        </p>
                                        <h5 style={{display:'none', textTransform: 'capitalize'}}>Tickets</h5>
                                        <p>Starting from <b>$20</b>/person<br/><br/> <span>*Available as a standalone experience or a part of the Flagship Experience Package.</span></p>
                                        <a id="gamepage-book" className="button special" onClick={this.openModal} alt="" >Book Now</a>
                                    </div>

                                    <div className="col-12 col-sm-12 col-lg-4">
                                        <h3>Detail</h3>
                                        <table style={{textTransform:'capitalize'}}>
                                            <tbody style={{ verticalAlign: 'middle'}}>
                                                <tr>
                                                    <td style={{verticalAlign: 'middle'}}><i className="icon fa-users" style={{
                                                    color: '#ed4933',
                                                    fontSize: '3em',
                                                    opacity: '0.8'
                                                    }}></i></td>
                                                    <td>
                                                        <div>
                                                            <h5 style={{
                                                                textTransform: 'capitalize'
                                                                }}>Team size</h5>
                                                            <p style={{
                                                                marginBottom: '0',
                                                                lineHeight: 'normal',
                                                                fontSize: '0.9em',
                                                                }}>Standard Mode: 2-5 players <br/>Tournament Mode: 6-10 players <br/>Party Mode: 11-20 players </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{verticalAlign: 'middle'}}>
                                                        <img src={require('../images/money-back.png')} style={{width:"53px"}}/>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <h5 style={{
                                                                textTransform: 'capitalize'
                                                                }}>Satisfaction Guarantee</h5>
                                                            <p style={{
                                                                marginBottom: '0',
                                                                lineHeight: 'normal',
                                                                fontSize: '0.9em'
                                                            }}>We are so sure that you will totally love the experience that we offer a 100% refund if otherwise</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>`
                    </div>
                </section>         
            </article>
            <BookModal 
                showModal={this.state.showModal}
                exitModal={this.exitModal}
            />
        </div>
        )
    }
}


/*Removed Rows */

/* 
<tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-clock-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td style="
						                           vertical-align: middle;
						                           ">
						                           <div style="
						                              ">
						                              <h5 style="text-transform: capitalize;">Length</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">
						                                 30 minute reservation<br>
						                                 * 5 minute introduction &amp; virtual training <br>
						                                 * 20 minute game experience <br>
						                                 * 5 minute back-to-reality &amp; debrief
						                              </p>
						                           </div>
						                        </td>
                                             </tr>
*/

/* <tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-star-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td>
						                           <div>
						                              <h5 style="
						                                 text-transform: capitalize;
						                                 ">Difficulty</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">Available in casual or hardcore mode</p>
						                           </div>
						                        </td>
                                             </tr>
*/
