import React from 'react';

const Style = {
    background:'one !important',
    border: 'none !important',
    margin: '0 !important',
    padding: '0  !important',
    cursor: 'pointer  !important',
    boxShadow: 'none  !important'
    
}

export default class FAQRevealInfo extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            visibility: false
        };
        this.question = "";
        this.answer = "";
    }
    toggle (){
        this.setState((prevState) => {
            return {
                visibility: !prevState.visibility
            };
        });
    }
    render(){
        return (
            <div className='inner' style={{alignContent:'center'}}>
                <tr id="faq">
                   <td onClick={this.toggle} style={{width:'75rem'}}>{this.props.question}</td> 
                </tr>

                <tr>
                    {this.state.visibility && <td style={{width:'75rem'}}>{this.props.answer}</td>}
                </tr>
            </div>
        );  
    }
}
