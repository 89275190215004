import React from 'react';
import Form from './ContactForm';
import Helmet from 'react-helmet';
import Collapsible from 'react-collapsible';
import ExperienceChooser from '../components/ExperienceChooser';

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
    
}

export default class BirthdayPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            mobile: ($(window).width() <= 763)? true : false,
        }

        this.playVideoMobile = this.playVideoMobile.bind(this);
        this.playVideoDesktop = this.playVideoDesktop.bind(this);
        this.videoplayer = React.createRef();
    }

    playVideoMobile(){
        posthog.capture('Play Birthday Video');
          var videowrapper = document.getElementById("mobileVideoHider");
          var playbutton = document.getElementById("watchTrailerMobile");
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          window._vis_opt_queue = window._vis_opt_queue || [];
          window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
          //this.checkLeadMagnetPopup()
          //var videoiframe = video.getElementsByTagName('iframe')[0];
          //this.setState({videourl: 'https://www.youtube.com/watch?v=eHkBBV7ccsc',playing: 'true'})
          //screenfull.request(findDOMNode(video));
          /*  if (videoiframe.requestFullscreen) {
                videoiframe.requestFullscreen();
            } else if (videoiframe.webkitRequestFullscreen) {
                videoiframe.webkitRequestFullscreen();
            } else if (videoiframe.mozRequestFullScreen) {
                videoiframe.mozRequestFullScreen();
            } else if (videoiframe.msRequestFullscreen) {
                videoiframe.msRequestFullscreen();
            }*/
    }

    playVideoDesktop(){
        posthog.capture('Play Birthday Video');
          var videowrapper = document.getElementById("desktopVideoHider");
          var playbutton = document.getElementById("watchTrailerDesktop");
          var bg = document.getElementsByClassName("fullscreen-bg")[0];
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          window._vis_opt_queue = window._vis_opt_queue || [];
          window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
          //this.checkLeadMagnetPopup()
          /*if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            }*/
    }

    componentDidMount () {

        const script = document.createElement("script");

        script.src = "https://reviewsonmywebsite.com/js/embedLoader.js?id=9b3acdde2be3481c94dd";
        script.async = true;

        document.body.appendChild(script);

        // hotjar tracking
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1024291,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }


    render(){
        return(
        <div>
            <Helmet>
                <style></style>
                <meta charSet="utf-8" />
                <link rel="canonical" href="https://www.miragevr.ca/birthday"/>
                <meta name='description' content="Looking for the best birthday party ideas? Virtual reality birthday parties are
                    sure to please all your guests! Learn more about free roaming VR parties for all ages!" />
                <title>Virtual Reality Birthday Parties - All Ages | Mirage VR </title>

            </Helmet>


                {!this.state.mobile &&
                <div className="fullscreen-bg">
                    <video id="background-video" className="background-video" src={require("../videos/10sectrailer5.mp4")} type="video/mp4" muted loop autoplay=""/>
                    <div className="fullscreen-overlay video-overlay-diagonal"></div>
                    <div className="fullscreen-overlay frame-overlay"></div>
                </div>
                }
                {!this.state.mobile &&
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                    <div className="inner">
                    <div className="likeh1"><span className="mirage-teal">Virtual Reality</span> Birthday Parties
                    </div>
                        <p>
                            Where Your Dreams Become <span className="mirage-teal">Reality</span>
                        </p>
                    </div>
                    <ul className="actions">
                            <li><div>
                                    <div id="desktopVideoHider">
                                     <video ref={this.videoplayer} id="desktopTrailerVideo" controls type="video/mp4" src={require("../videos/trailer2.mp4")} preload="metadata"/>
                                    </div>
                                    <img id="watchTrailerDesktop" src={require("../images/playbutton.png")} onClick={this.playVideoDesktop} className="small"/>
                            </div></li>
                    </ul>
                </section>
                }
                {this.state.mobile &&
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: ninetyheight}}>
                    <div className="landing2-bg" style={imgDiv}>  
                        <div style={overlay}>
                            <img id="landingimage" className="lazy" src={require("../images/gdc.jpg")} />
                        </div>                    
                    </div>
                    <div id="bannertext">
                        <div className="bannertoptext">
                        <div className="likeh1"><span className="mirage-teal">Virtual Reality</span> Birthday Parties
                        </div>
                        </div>
                        <div id="playvidbutton">
                                <div id="mobileVideoHider">
                                    <video ref={this.videoplayer} id="mobileTrailerVideo" controls type="video/mp4" src={require("../videos/trailer2.mp4")} preload="metadata"/>
                                </div>     
                                <img id="watchTrailerMobile" 
                                    src={require("../images/playbutton.png")} 
                                    onClick={this.playVideoMobile} 
                                className="small" />                  
                        </div>
                        <div className="bannerbottomtext">
                        <p id="landingdescription">
                            Where Dreams Become <span className="mirage-teal">Reality</span>
                        </p>
                        </div>
                    </div>
                </section>
                }

            <article id="main">

                <section class="wrapper style3">
                    <div class="inner">
                        <h1>The Best Birthday Party Idea - VR!</h1>
                        <br/>
                        <p>Are you looking for a birthday party idea that is fun and totally unique? Virtual Reality Birthday Parties
                           are a completely new type of experience that is guaranteed to generate excitement, laughter, and a ton of great 
                           memories. Escape the mundaness of real life and enter a fantasy world with your friends or family. A completely intuitive
                           experience where your body is the controller. Hug, dance, and high-five your friends 
                           in a completely new world, and work together to vanquish foes. 
                        </p>
                        <a id="gamepage-book" className="button small special" href="#bookbirthday" alt="" >Book Now!</a>
                    </div>
                </section>

                <Collapsible trigger={
                    <section class="collapsible wrapper style2">
                        <div class="inner">
                            <h2>Awesome Kid's Birthday Parties</h2>
                            <br/>
                            <p>Our experiences span many genres, from wielding lightsabers and riding roller coasters, to puzzle solving
                               and cooking. Mirage VR is the ultimate destination for kid's birthday parties near you.
                            </p>
                            <i className="downarrow white"/>
                        </div>
                    </section>}>  


                    <section id="two" className="wrapper alt style2">
                        <section className="spotlight">
                            <div className="image"><img src={require("../images/birthdayparties.jpg")}/></div><div className="content">
                                <div className="likeh2">Boys' Birthday Parties</div>
                                <br/>
                                <p>
                                    Enter a world filled with excitement and adrenaline, and wield magical powers to vanquish your enemies.
                                    Boys will especially enjoy the action elements of our experiences!
                                </p>
                            </div>
                        </section>
                        <section className="spotlight">
                            <div className="image"><img src={require("../images/birthdayparties-girls.jpg")}/></div><div className="content">
                                <div className="likeh2">Girls' Birthday Parties</div>
                                <br/>
                                <p>
                                    Enter a world where you can have funny dance-offs with friends. Coordinate and communicate in order
                                    to overcome obstacles. Girls will enjoy the co-operative elements of our experiences!
                                </p>
                            </div>
                        </section>
                    </section>

                </Collapsible>



                <Collapsible trigger={
                    <section class="collapsible wrapper style3">
                        <div class="inner">
                            <h2>Turbulent Teenage Birthdays</h2>
                            <br/>
                            <p>
                                There are a lot of options for birthday parties, but none are as immersive and universal as Mirage VR.
                                Be the first to host a one-of-a-kind party filled with wonder, laughter, and a collection of
                                amusing instagram videos.
                            </p>
                            <i className="downarrow white"/>
                        </div>
                    </section>}> 



                    <section id="two" className="wrapper alt style3">
                        <section className="spotlight">
                            <div className="image"><img src={require("../images/teenbirthdays.jpg")}/></div><div className="content">
                                <div className="likeh2">Cool And Incredibly Fun</div>
                                <br/>
                                <p>
                                   When it comes to choosing an activity for your birthday, you want something that is both cool and incredibly fun.
                                   You want something better than what your friends have already done, something that will have people talking for days. Welcome
                                   to Mirage VR.
                                </p>
                            </div>
                        </section>
                        <section className="spotlight">
                            <div className="image"><img src={require("../images/hotairballoon.jpg")}/></div><div className="content">
                                <div className="likeh2">Experience Over 50 immersive Worlds</div>
                                <br/>
                                <p>
                                    Immerse yourselves in a world more real than life itself, where you can battle, dance, and explore with friends.
                                    Whether you like fantasy and magic, zombie shooting, or careful puzzle solving, we have something for everyone to enjoy.
                                </p>
                            </div>
                        </section>
                    </section>

                </Collapsible>

                <Collapsible trigger={
                    <section class="collapsible wrapper style2">
                        <div class="inner">
                            <h2>Adult Birthdays Everyone Enjoys</h2>
                            <br/>
                            <p>At Mirage VR, we specialize in free-roam, full body VR experiences. That means people of all ages and backgrounds
                               will be able to immerse themselves in our virtual worlds that have no learning curve.  
                            </p>
                            <i className="downarrow white"/>
                        </div>
                    </section>}> 


                    <section id="two" className="wrapper alt style2">
                        <section className="spotlight">
                            <div className="image"><img src={require("../images/believeinmagic.jpg")}/></div><div className="content">
                                <div className="likeh2">Believe in Magic</div>
                                <br/>
                                <p>
                                    There was a time when you believed anything was possible. But as time went by, you became more grounded. At Mirage VR, we provide you an experience so immersive, you will believe
                                    in magic again. The only limit is your imagination.
                                </p>
                            </div>
                        </section>
                        <section className="spotlight">
                            <div className="image"><img src={require("../images/teambuildingvr.jpg")}/></div><div className="content">
                                <div className="likeh2">For Friends And Family</div>
                                <br/>
                                <p>
                                   Whether you're looking to celebrate your birthday with friends or family, we're here to make sure
                                   everyone is having a blast. Our staff will cater to your needs and work with you to make this
                                   the best birthday possible. Our content and customer service will have everyone feeling wonder, excitement, 
                                   and laughter, all at the same time.
                                </p>
                            </div>
                        </section>
                    </section>


                </Collapsible>
                
                <section className="wrapper style3 special">
                    <div className="inner">
                        <header className="major">
                            <div className="likeh2">What to expect</div>
                        </header>
                        <ul className="features">
                            <li className="icon fa-retweet">
                                <div className="likeh3">Try multiple VR experiences</div>
                            </li>
                            <li className="icon fa-users">
                                <div className="likeh3">Work together to accomplish missions</div>
                            </li>
                            <li className="icon fa-trophy">
                                <div className="likeh3">Party Room for food and cake</div>
                            </li>
                            <li className="icon fa-heart-o">
                                <div className="likeh3">A truly unforgettable memory</div>
                            </li>
                        </ul>
                    </div>
                </section>

                <section id="two" className="wrapper alt style2">
                    <section className="spotlight">
                        <div className="image"><img src={require("../css/new-landing/images/banner.jpg")} alt="" /></div><div className="content">
                           <div className="likeh2">Sherwood Forest</div>
                           <br/>
                           <p>Our most popular experience! Choose your hero. Be a magician, warrior, archer or healer, and work as a team to battle monsters in the mysterious Sherwood Forest!</p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/ktane.jpg")} alt="" /></div><div className="content">
                           <div className="likeh2">Keep Talking & Nobody Explodes</div>
                           <br/>
                           <p>You are locked in a virtual room with a ticking time bomb. Communicate with your team to defuse the bomb before you are blown to smithereens!</p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/tiltbrush.jpg")} alt="" /></div><div className="content">
                           <div className="likeh2">VR Pictionary</div>
                           <br/>
                           <p>Put your creativity to the test in a game of Pictionary, but in VR! The entire room is your canvas and you are only limited by your own imagination.</p>
                        </div>
                    </section>
                </section>


                <section className="pricing wrapper style5 special">
                    <div className="inner">
                        <header className="major">
                            <div className="likeh2">Experiences</div>
                        </header>
                      </div>
                </section>
                <ExperienceChooser category="Birthday" groupSize="1to40" hideCategory="true"/>
                <a id="bookbirthday"></a>
                <section className="pricing wrapper style2">
                    <div className="inner">

                        <a name="20+"/>
                        <div id="message" className="inner fit vertical">
                            <div className="likeh2">Plan Your Virtual Reality Birthday Party Today!</div>
                            <br/>
                                <p>
                                    Different birthday parties require different accommodations, and Mirage VR will work with you to plan your 
                                    birthday party. Simply fill out the form below, and a representative will respond within 24 hours. 
                                    If special accommodations are needed (such as booking the space for an entire afternoon), simply include that in the message.
                                </p>

                                <Form tag = {'birthday'} />
                        </div>
                    </div>
                </section>

                <section id="reviews" className="wrapper style5" >
                    <div className="container inner">
                        <div data-token="Dna0jiR1HC2BQtO8oTKOvGYjJWMFM70Q4QfVCNbfQBrTf8Gy0X" class="romw-reviews"></div>  
                    </div>
                </section>

            </article>
            </div>
        )
    }
}