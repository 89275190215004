import React from 'react';
import FranchiseForm from './FranchiseForm';
import Helmet from 'react-helmet';

import WatchTrailerModal from '../components/modals/WatchTrailerModal';

export default class FranchisePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showVideo: false,
            mobile: ($(window).width() <= 736)? true : false
        }
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });              
    }

    openModal(){
        this.setState(()=> ({showVideo: true}));
    }
    
    exitModal(){
        this.setState(()=> ({showVideo: false}));
    }
	
	render(){
		return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <meta name="description" content="Mirage VR is on a mission to make immersive, social VR experiences more accessible, and is currently in discussions with entertainment destinations all over the world. Partner with us here today!"/>
                    <title>Partner With Us | Mirage VR Virtual Reality Company in Ontario</title>
                    <link rel="canonical" href="https://www.miragevr.ca/partner"/>
                </Helmet>
                <WatchTrailerModal
                showModal={this.state.showVideo}
                exitModal={this.exitModal}
                />           
                <article id="main">

                    <header className="franchise-bg">
                        <div className="heavy-text" >
                            <h2><span class="mirage-teal">Partnership Opportunities</span></h2><br/>
                            <br/>
                            <p>Canada's Premier Free-Roam<br/>VR System</p>
                            <button id="fb-video-trigger" onClick={this.openModal} className="small" style={{boxShadow: "inset 0 0 0 2px #00cdcd"}}>Watch Trailer</button>
                        </div>
                    </header>

                    <section class="wrapper style5">
                    <div class="inner">
                        <div class="row">
                            <div class="col-12 col-sm-6 image fit">
                                <img src="images/25.jpg"/>
                            </div>
                            <div class="col-12 col-sm-6">
                                <h4>Partnering With Mirage VR</h4>
                                <p>Mirage VR is on a mission to make immersive, social VR experiences 
                                more accessible. We are partnering with entertainment destinations across 
                                the globe to create "Mirage Arenas" where consumers can experience 
                                premium free-roam VR. We provide the end-to-end adventure, 
                                you provide the space.</p>
                                <p>Our turnkey, lightweight system allows us to setup in as little as one day.</p>
                            
                                <h4>Interested?</h4>
                                <a href="#ownfuture"><button>Contact Us</button></a>
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                    </section>

                    <section id="ownfuture" className="wrapper style7 special no-border" style={{padding: "2em 2em 1em 2em"}}>
                        <div className="inner">
                            <header className="major less-heavy-text">
                                <h1>Join the <span className="mirage-teal">revolution</span></h1>
                            </header>
                        </div>
                    </section>

                    <section className="wrapper style5">
                        <div className="inner">
                            <h5>We'd love to hear from you</h5>
                            <br/>
                            <div id="message" className="inner fit vertical">
                                <FranchiseForm />
                            </div>
                        </div>
                    </section>
                </article>
            </div>
        )
    }
};

