import React, { Component } from 'react';

export default class Testimonials extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

  componentDidMount() {
    

        /*----------------------------- HOMEPAGE ------------------------------*/
        //Collage
        $( '#ri-grid' ).gridrotator(
            {
                rows : 2,
                columns: 5,
                preventClick: true,
                step : 4,
                maxStep : 5,
                interval : 2000,
                w1024 : { rows : 2, columns : 5 },
                w768 : {rows : 2,columns : 4 },
                w480 : {rows : 3,columns : 3 },
                w320 : {rows : 3,columns : 3 },
                w240 : {rows : 3,columns : 3 }
              } 
        );

        //testimonials
        if ($('.testamonials').length > 0) {
            $('.testamonials').slick({
               arrows: false,
               dots: false,
               dotsClass: 'teaser-slideshow-dots',
               infinite: true,
               lazyLoad : true,
               fade: false,
               autoplay: true,
               autoplaySpeed: 5000,
               speed: 1000,
               slidesToShow: 1,
               swipe: true,
               swipeToSlide: true
         });
        } 
  }
  componentWillUnmount() {

  }

  render() {
    return (

              <section id="collage" style={{padding:'0px'}}>
                      <div id="ri-grid" className="ri-grid ri-grid-size-2 ri-shadow" >
                          <ul>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/12.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/20.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/21.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/24.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/25.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/27.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/32.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/33.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/36.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/1.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/2.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/3.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/4.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/5.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/6.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/7.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/8.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/9.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/10.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/11.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/escaperoom/12.jpg")}/></a></li>

                          </ul>
                          <div className="overlay">
                          </div>
                          
                          <div className="overlay-no-fade">
                              <div className="testamonials">
                                  <div><p>As a seasoned escape room veteran, I was skeptical of VR. However, I was thoroughly impressed by the surreal and interesting puzzles in Decomposed. I will be back for more! <br/><br/> <span className="mirage-teal">- Cathy @ Toronto Comic Con</span></p></div>
                                  <div><p>It was amazing how we weren't just limited to a room, and we were trying to escape from an entire Mansion! The puzzles were very unique and fun! <br/><br/> <span className="mirage-teal">- James @ TAVES Electronics Show</span></p></div>
                                  <div><p>Such an exhilarating experience! The puzzles were different from normal escape rooms. Intuitive yet challenging! <br/><br/> <span className="mirage-teal">- Stephan @ Corporate Team Building</span></p></div>
                                  <div><p>This adds a whole new level of story and cinematic to escape rooms. Can't wait to see where this goes as the technology improves further! <br/><br/> <span className="mirage-teal">- Sharon @ Birthday Party</span></p></div>
                                  <div><p>It truly felt like a group adventure where we had to travel between different rooms to escape! The atmosphere added a whole new layer that normal escape rooms aren't able to achieve. <br/><br/> <span className="mirage-teal">- Lory @ Friends Night Out</span></p></div>
                              </div>
                          </div>

                      </div>
              </section>
    );
  }
}