import React from 'react';
import Helmet from 'react-helmet';
const ReactDOM = window.ReactDOM;

import BookModal from '../components/modals/BookModal';
import ExperienceChooser from '../components/ExperienceChooser';
import Shuffle from 'shufflejs';

const queryString = require('query-string');

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
    
}

export default class ExperiencesPage extends React.Component {
    constructor(props){
        super(props);

        const search = this.props.location.search;
        const params = queryString.parse(search);
        this.quantity = params['players'] || "1to40";
        this.category = params['category'] || "ShowAll";
        this.extras = "";

        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        //this.sizer = React.createRef();
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Experiences | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/experiences"/>
                </Helmet>
                <article id="main">
                    <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                        <div className="landing2-bg" style={imgDiv}>  
                          <div style={overlay}>
                              <img id="landingimage" className="lazy" src={require("../images/vrexperiences.jpg")} />
                          </div>                    
                        </div>
                        <div id="bannertext">
                            <div className="bannermidtext">
                            <header className="heavy-text">
                                <h2>Experiences</h2>
                            </header>
                            <br/>
                            <p>Choose your <span className="mirage-teal">adventure</span></p>
                            </div>
                        </div>
                    </section>
              
                    <section id="two" className="wrapper alt style2">
                        <section className="spotlight">
                            <div className="image"><img src={require("../images/hyperrealityexplanation.jpg")} alt="" /></div><div className="content">
                               <h2>Free-Roam Virtual Reality</h2>
                               <p></p>
                               <p>Group experiences that share a large, open space. Explore with your own legs, 
                               interact with your own hands, and coordinate as a team to stay alive.
                               </p>
                               <p><span className="mirage-teal"> LIMITED SLOTS AVAILABLE </span> - Please book at least 1 day in advance for guaranteed spots.
                               </p>
                            </div>
                        </section>

                        <section className="spotlight">
                            <div className="image"><img src={require("../images/virtualrealityexplanation.jpg")} alt="" /></div><div className="content">
                               <h2>Stationary Virtual Reality</h2>
                               <p></p>
                               <p>Rent your own personal booth, where you can play solo, or virtually with friends. Over 50 experiences available! Whether its action, adventure, puzzle, or dance, we have something for everyone!
                               </p>
                            </div>
                        </section>

                        <section className="spotlight hide">
                        <div className="image"><a href="/freeroamvrcombopackage"><img src={require("../images/freeroam-wide.jpg")} alt="" /></a></div><div className="content">
                           <h2>Free-Roam Combo <span className="mirage-teal">[1-5 players]</span></h2>
                           <br/>
                           <p>Play through both our Free-Roam VR experiences - Sherwood Forest, and Dragonfire. You will choose your hero from a warrior, magician, archer, or healer, each with unique powers. Work together to survive!
                           </p>
                           <p>
                           <a href="/freeroamvrcombopackage" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>See Package</a>
                           </p>
                        </div>
                        </section>

                        <section className="spotlight hide">
                        <div className="image"><a href="/defendanddefusepackage"><img src={require("../images/defend&defuse-wide.jpg")} alt="" /></a></div><div className="content">
                           <h2>Defend and Defuse <span className="mirage-teal">[6-10 players]</span></h2>
                           <p></p>
                           <p>If you're looking for an experience that puts your teamwork to the test, then look no further. Coordinate with your team as you shoot fireballs, slice monsters, and fight with your own hand and feet. Communicate with your team to carefully defuse a bomb before time runs out!
                           </p>
                           <p>
                           <a href="/defendanddefusepackage" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>See Package</a>
                           </p>
                        </div>
                        </section>

                    </section>

                    <section id="two" className="wrapper alt style3">
                        <section className="spotlight"></section>

                        <section className="spotlight hide">
                        <div className="image"><a href="/decomposedpackage"><img src={require("../images/decomposed-wide.jpg")} alt="" /></a></div><div className="content">
                           <h2>Decomposed <span className="mirage-teal">[2-5 players]</span></h2>
                           <p></p>
                           <p> You and your friends have been turned into ghosts, and trapped in the mansion of a demented composer. Solve mind-bending puzzles and impossible contraptions
                           to escape!
                            </p>
                           <p>
                           <a href="/decomposedpackage" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>See Package</a>
                           </p>
                        </div>
                        </section>
                        <section className="spotlight">
                        <div className="image"><a href="/stationaryvrpackage"><img src={require("../images/vrbooth-bg.jpg")} alt="" /></a></div><div className="content">
                           <h2>Stationary VR <span className="mirage-teal">[2-5 players]</span></h2>
                           <p></p>
                           <p> Explore over 50 worlds in the greatest adventure of your life. Whether you like action-packed battles, shooting zombies, or grooving to the rhythm, we have something for everyone.
                            </p>
                           <p>
                           <a href="/stationaryvrpackage" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>See Package</a>
                           </p>
                        </div>
                        </section>

                        <section className="spotlight">
                            <div className="image">
                                <a href="/bestofbothworldspackage"><img src={require("../images/magicdoor-wide.jpg")} alt="" /></a></div><div className="content">
                                <h2>Best of Both Worlds <span className="mirage-teal">[2-10 players]</span></h2>
                                <br/>
                                <p>Can't choose between Free-Roam or Stationary VR? Best of Both Worlds lets you experience the immersion of our free-roam VR arena, and over 50 virtual worlds in our VR stations.
                                </p>
                                <p>
                                <a href="/bestofbothworldspackage" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>See Package</a>
                                </p>
                            </div>
                        </section>

{/**
 * 
 *                         <section className="spotlight hide">
                        <div className="image"><a href="/partypackage"><img src={require("../images/vrpictionary-wide.jpg")} alt="" /></a></div><div className="content">
                           <h2>Party <span className="mirage-teal">[11-20 players]</span></h2>
                           <p></p>
                           <p>Save a forest from destruction, stop a bomb from exploding, and guess funny pictures drawn by your friends. Whether you're looking for an amazing birthday party or team building event, we promise a unique experience unlike any other.
                           </p>
                           <p>
                           <a href="/partypackage" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>See Package</a>
                           </p>
                        </div>
                        </section>
 * 
 */}


                        <section className="spotlight">
                            <div className="image"><a href="/deluxepartypackage"><img src={require("../images/landingportal-wide.jpg")} alt="" /></a></div><div className="content">
                               <h2>Deluxe Party <span className="mirage-teal">[11-40 players]</span></h2>
                               <p></p>
                               <p>The entire facility is yours when you book a deluxe party package with us. An endless variety of immersive experiences awaits, spanning everything from zombie shooting to dodgeball. 
                               </p>
                               <a name="timeshare"></a>
                               <p>
                               <a href="/deluxepartypackage" className="button small special" alt="" style={{margin: '0px 4px 0px 4px'}}>See Package</a>
                               </p>
                            </div>
                        </section>
                        <a id="booknow"></a>
                    </section>

                </article>

                <article className="wrapper style4 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                    <div className="inner">
                        <section>
                        <header className="major less-heavy-text">
                            <h1><span className="mirage-white">Choose Your Adventure</span>
                            </h1>
                        </header>
                        </section>
                    </div>
                </article>
                
                <ExperienceChooser category={this.category} groupSize={this.quantity} />

                <section className="faq-inline wrapper style7 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                            <div className="inner">
                                <header className="major less-heavy-text">
                                        <h1>Have more questions?</h1>
                                </header>
                                <a className="button small" href="/faq">See FAQ</a>
                            </div>
                </section>
            </div>
        )
    }
}


/*Removed Rows */

/* 
<tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-clock-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td style="
						                           vertical-align: middle;
						                           ">
						                           <div style="
						                              ">
						                              <h5 style="text-transform: capitalize;">Length</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">
						                                 30 minute reservation<br>
						                                 * 5 minute introduction &amp; virtual training <br>
						                                 * 20 minute game experience <br>
						                                 * 5 minute back-to-reality &amp; debrief
						                              </p>
						                           </div>
						                        </td>
                                             </tr>
*/

/* <tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-star-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td>
						                           <div>
						                              <h5 style="
						                                 text-transform: capitalize;
						                                 ">Difficulty</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">Available in casual or hardcore mode</p>
						                           </div>
						                        </td>
                                             </tr>
*/