import React from 'react';
import Form from './ContactForm';
import Helmet from 'react-helmet';

const headerStyle = {
    padding: '12em 0 10em'
}

const FeedbackV2Page= () => (
    <div>
    <Helmet>
        <style></style>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.miragevr.ca/feedback"/>
        <meta name="description" content="Your Feedback makes Mirage VR even better"/>
        <title>Customer Feedback | Best Virtual Reality Arcade in Ontario</title>
    </Helmet>
    <article id="main">
        <header style={headerStyle}>
            <div  class="heavy-text" >
            <h2>Customer Feedback</h2>
            </div>
        </header>
        <section class="wrapper style5">
			<div class="inner">
				<div id="message" class="inner fit vertical">
                    <Form 
                        tag = {'feedbackV2'}
                    />
                </div>
            </div>
        </section>
    </article>
    </div>
);
export default FeedbackV2Page;

/*

*/