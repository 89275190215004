import React from 'react';
import Helmet from 'react-helmet';

import BookModal from '../components/modals/BookModal';

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
    
}

export default class StationaryVRPackagePage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            appointmentType: "stationaryvr",
            mobile: ($(window).width() <= 736)? true : false
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.showBookModal = this.showBookModal.bind(this);
        this.showBookTimeshareModal = this.showBookTimeshareModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });              
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    showBookModal(){
        document.location.href = "/book?appointmentType=stationaryvr";
        //this.setState(()=> ({showModal: true}));
    }

    showBookTimeshareModal(){
      this.setState(()=> ({showModal: true}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Stationary Virtual Reality | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/virtualrealitypackage"/>
                </Helmet>
                <article id="main"  >

                  <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                      <div className="landing2-bg" style={imgDiv}>  
                        <div style={overlay}>
                            <img id="landingimage" className="lazy" src={require("../images/vrbooth-bg.jpg")} />
                        </div>                    
                      </div>
                      <div id="bannertext">
                          <div className="bannermidtext">
                          <header className="heavy-text">
                            <h2>Stationary Virtual Reality
                            </h2>
                          </header>
                          <br/>
                          <p>Explore over <span className="mirage-teal">50 worlds</span></p>
                          </div>
                      </div>
                  </section>
          
                        <article className="wrapper style3 special no-border" style={{padding:'0em 2em 1em 2em'}}>
                           <section>                    
                                <div className="inner">
                                  <section style={{margin:'0em 0em 2em 0em'}}>
                                      <div className="row events-occasions" >
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  2-5
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Players</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  50
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">minutes</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  8+
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Ages</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  $35
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Per Player</span>
                                              </p>
                                          </div>
                                      </div>
                              </section>
                                </div>
                            </section>
                        </article>
                      
                  <section id="two" className="wrapper alt style2">
                      <section className="spotlight">
                          <div className="image"><img src={require("../images/virtualrealityexplanation.jpg")} alt="" /></div><div className="content">
                             <h2>Ultimate Choice</h2>
                             <p></p>
                             <p>Explore over 50 worlds in the greatest adventure of your life. Whether you like action-packed battles, 
                             shooting zombies, or grooving to the rhythm, we have something for everyone. 
                             </p>
                             <a id="gamepage-book" className="button small special" onClick={this.showBookModal} alt="" >Book Stationary VR</a>
                          </div>
                      </section>
                      <section className="spotlight">
                          <div className="image"><img src={require("../images/beatsaber.jpg")} alt="" /></div><div className="content">
                             <h2>Beat Saber</h2>
                             <p></p>
                             <p> A unique rhythm game where you slice and dice to the beat of groovy music. If you like Fruit Ninja,
                                 you will simply LOVE this experience.
                             </p>
                          </div>
                      </section>
                      <section className="spotlight">
                          <div className="image"><img src={require("../images/arizonasunshine.jpg")} alt="" /></div><div className="content">
                             <h2>Arizona Sunshine</h2>
                             <p></p>
                             <p> You and your friends find yourself in the midst of a zombie apocalypse. Put your survival skills to the test as you
                             explore a post apocalyptic world. Choose your favourite weapon from the armory and put the undead back to rest.
                              </p>
                          </div>
                      </section>
                      <section className="spotlight">
                          <div className="image"><img src={require("../images/smashboxarena.jpg")} alt="" /></div><div className="content">
                             <h2>Smashbox Arena</h2>
                             <p></p>
                             <p> Go head-to-head with your friends in this action-packed shooter game. Dive into cover, dodge and volley enemy shots, 
                             then strike back with insane combos. Ready, set, Smash!
                              </p>
                          </div>
                      </section>
                      <section className="spotlight">
                          <div className="image"><img src={require("../images/vivegames/vivelaunch.jpg")} alt="" /></div><div className="content">
                             <h2>...And Much More</h2>
                             <p></p>
                             <p> No matter who you are or what you like, our collection of over 50 games will be sure to delight you.
                              </p>
                              <a name="timeshare"></a>
                          </div>
                      </section>
                        <section className="spotlight hide">
                            <div className="image"><img src={require("../images/vrtimeshare.jpg")} alt="" /></div><div className="content">
                               <h2>Timeshare</h2>
                               <p></p>
                               <p>
                               This package is available in Timeshare mode. Participants take turns entering Virtual Reality. While your friends are playing,
                               explore the virtual world through their eyes, and enjoy their funny movements in real life.
                                </p>
                            </div>

                        </section>
                  </section>
                      
                  <section className="wrapper style5">
                      <div className="inner">
                        <section>
                        <header className="major align-center ">
                        <h3 style={{color:'#2E3842'}}>Experience Features</h3>
                        </header>
                        <div className="row game-features">
                          <div className="col-sm-12 col-md-4 $(medium) align-center">
                            <img className="lazy" src={require("../images/vrheadset.png")}/>
                            <h3>Virtual<br/>Multiplayer</h3>
                            <p>Embark on a journey across different virtual worlds, with friends or family. Where to start? That is for you to decide.
                               <a className="hide" href="" style={{borderBottom: '1px solid'}}>Learn More</a></p>
                          </div>
                          <div className="col-sm-12 col-md-4 $(medium) align-center">
                            <img className="lazy" src={require("../images/pvp.png")}/>
                            <h3>Player Versus<br/> Player</h3>
                            <p>Nothing beats a bit of friendly competition in the safety of virtual reality.
                               Deflect, dodge, and defeat your friends using your new found powers.</p>
                          </div>
                          <div className="col-sm-12 col-md-4 $(medium) align-center">
                            <img className="lazy" src={require("../images/individualperson.png")}/>
                            <h3>Private<br/> Booths</h3>
                            <p>We provide you your own private universe of never-ending fun. Action, puzzle, shooting, or adventure. We have
                            it all! </p>
                          </div>
                        </div>
                        </section>
                      </div>
                  </section>

                  <article className="wrapper style3 special no-border" style={{padding:'2em 2em 1em 2em'}}>
                     <section>                    
                          <div className="inner">
                              <section style={{margin:'2em 0em 2em 0em'}}>
                                      <div className="row events-occasions" >
                                          <div className="col-sm-12 col-md-6 $(medium) align-center">
                                             <img src={require('../images/pricestamp.png')} style={{width:'50%'}}/>
                                              <h5 style={{
                                                  textTransform: 'capitalize',
                                                  fontSize: '1.2em',
                                                  }}>Price</h5>
                                              <p style={{
                                                  marginBottom: '0',
                                                  lineHeight: 'normal',
                                                  fontSize: '1.2em',
                                                  }}>$35 per player <br/><br/><br/>
                                              </p>
                                          </div>
                                          <div className="col-sm-12 col-md-6 $(medium) align-center">
                                             <img src={require('../images/moneybackguaranteestamp.png')} style={{width:'50%'}}/>
                                             <h5 style={{
                                                  textTransform: 'capitalize',
                                                  fontSize: '1.2em',
                                                  }}>Guarantee</h5>
                                              <p style={{
                                                  marginBottom: '0',
                                                  lineHeight: 'normal',
                                                  fontSize: '1.2em'
                                              }}>We are so sure you will love the experience that we offer a 100% refund if you are not completely satisfied.</p>
                                          </div>
                                      </div>
                              </section>
                          </div>
                      </section>
                  </article>

                <section id="one" className="wrapper style2 special">
                    <div class="inner">
                        <h2>Book Stationary Virtual Reality</h2>
                        <br/>
                        <a id="gamepage-book" className="button small special" onClick={this.showBookModal} alt="" >Book Now!</a>
                    </div>
                </section>
              </article>
              <BookModal 
              appointmentType={this.state.appointmentType}
              isGroupReservation = {false}
              showModal={this.state.showModal}
              exitModal={this.exitModal}
              />
          </div>
        )
    }
}


/*Removed Rows */

/* 
<tr style="display:none">
                                                <td style="vertical-align: middle;"><i class="icon fa-clock-o" style="
                                                   color: #ed4933;
                                                   font-size: 3em;
                                                   opacity: 0.8;
                                                   "></i></td>
                                                <td style="
                                                   vertical-align: middle;
                                                   ">
                                                   <div style="
                                                      ">
                                                      <h5 style="text-transform: capitalize;">Length</h5>
                                                      <p style="
                                                         margin-bottom: 0;
                                                         line-height: normal;
                                                         font-size: 0.9em;
                                                         ">
                                                         30 minute reservation<br>
                                                         * 5 minute introduction &amp; virtual training <br>
                                                         * 20 minute game experience <br>
                                                         * 5 minute back-to-reality &amp; debrief
                                                      </p>
                                                   </div>
                                                </td>
                                             </tr>
*/

/* <tr style="display:none">
                                                <td style="vertical-align: middle;"><i class="icon fa-star-o" style="
                                                   color: #ed4933;
                                                   font-size: 3em;
                                                   opacity: 0.8;
                                                   "></i></td>
                                                <td>
                                                   <div>
                                                      <h5 style="
                                                         text-transform: capitalize;
                                                         ">Difficulty</h5>
                                                      <p style="
                                                         margin-bottom: 0;
                                                         line-height: normal;
                                                         font-size: 0.9em;
                                                         ">Available in casual or hardcore mode</p>
                                                   </div>
                                                </td>
                                             </tr>
*/
