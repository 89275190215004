import React from 'react';
import Modal from 'react-modal';

//handles switch what the modal displays at each step, the form content is handled by ModalForm

export default class DiscountSentModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showModal: false,
        }
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.exitAfterDelay = this.exitAfterDelay.bind(this);
        //setTimeout(this.exitModal, 2000);
        //Modals
        /*this.exitModal = this.exitModal.bind(this);
        this.switchModalUserInfo5 = this.switchModalUserInfo5.bind(this);
        this.switchModalUserInfo10 = this.switchModalUserInfo10.bind(this);
        this.switchModalGroupReservation = this.switchModalGroupReservation.bind(this);
        this.switchModalThankYou = this.switchModalThankYou.bind(this);
        this.switchModalError = this.switchModalError.bind(this);*/
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    exitAfterDelay(){
        setTimeout(this.exitModal, 2000);
    }
    
    render(){
        return(
        <Modal
                isOpen={this.props.showModal}
                onRequestClose= {this.props.exitModal}
                ariaHideApp={false}
                closeTimeoutMS={200}
                className="discountsentmodal"
                overlayClassName="overlay"
                >
            <div>
                <article>
                <h3 class="mirage-teal">Check Your Email!</h3>
                </article>
            </div>

        </Modal>  
        )
    }
};