import React from 'react';
import Helmet from 'react-helmet';

import BookModal from '../components/modals/BookModal';

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
    
}

export default class BestOfBothWorldsPackagePage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            appointmentType: 8320402,
            quantity: 2,
            mobile: ($(window).width() <= 736)? true : false
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.showBookModal = this.showBookModal.bind(this);
        this.showBookDeterminePackage = this.showBookDeterminePackage.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                this.setState(()=> ({mobile: false}));
         }
        });              
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    showBookDeterminePackage(){
      var players = document.getElementById("quantity").value;
      if (players > 5){
        this.setState(()=> ({appointmentType: "med-hrvr"}));
      }
      else {
        this.setState(()=> ({appointmentType: "small-hrvr"}));
      }
      this.setState(()=> ({quantity: players}));      
      this.setState(()=> ({showModal: true}));
    }

    showBookModal(){
        var players = document.getElementById("quantity").value;
        this.setState(()=> ({quantity: players}));
        if (players > 5) {
          this.setState(()=> ({appointmentType: 8320402}));
        } else {
          this.setState(()=> ({appointmentType: 8548244}));
        }
        this.setState(()=> ({showModal: true}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Best Of Both Worlds Package | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/bestofbothworldspackage"/>
                </Helmet>
                <article id="main"  >

                {!this.state.mobile &&
                  <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                      <div className="landing2-bg" style={imgDiv}>  
                        <div style={overlay}>
                            <img id="landingimage" className="lazy" src={require("../images/magicdoor-wide.jpg")} />
                        </div>                    
                      </div>
                      <div id="bannertext">
                          <div className="bannermidtext">
                          <header className="heavy-text">
                            <h2>Best of Both Worlds
                            </h2>
                          </header>
                          <br/>
                          <p>Where <span className="mirage-teal">Immersion</span> Meets <span className="mirage-teal">Variety</span></p>
                          </div>
                      </div>
                  </section>
                }
                {this.state.mobile && 
                  <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: ninetyheight}}>
                      <div className="landing2-bg" style={imgDiv}>  
                        <div style={overlay}>
                            <img id="landingimage" className="lazy" src={require("../images/magicdoor-wide.jpg")} />
                        </div>                    
                      </div>
                      <div id="bannertext">
                          <div className="bannermidtext">
                          <header className="heavy-text">
                            <h2>Stationary Virtual Reality
                            </h2>
                          </header>
                          <br/>
                          <p>Explore over <span className="mirage-teal">50 dimensions</span></p>
                          </div>
                      </div>
                  </section>
                }
                
                <article className="wrapper style3 special no-border" style={{padding:'0em 2em 1em 2em'}}>
                   <section>                    
                        <div className="inner">
                            <section style={{margin:'0em 0em 2em 0em'}}>
                                    <div className="row events-occasions" >
                                        <div className="col-6 col-md-4 align-center">
                                            <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                6-10
                                                <br/>
                                                <br/>
                                                <span className="halfem">Players</span>
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-4 align-center">
                                            <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                120
                                                <br/>
                                                <br/>
                                                <span className="halfem">Minutes</span>
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-4 align-center">
                                            <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                8+
                                                <br/>
                                                <br/>
                                                <span className="halfem">Ages</span>
                                            </p>
                                        </div>
                                    </div>
                            </section>
                        </div>
                    </section>
                </article>
                    
                <section id="two" className="wrapper alt style2">
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/teambuilding-bg2.jpg")} alt="" /></div><div className="content">
                           <h2>Best Of Both Worlds</h2>
                           <p></p>
                           <p>Can't choose between Free-Roam or Stationary VR?
                              Best of Both Worlds lets you experience the immersion of our free-roam VR arena, and over 50 virtual worlds in our VR stations.
                              So bring your family, friends, enemies, and get ready to experience the ultimate adventure! Includes: fantasy, zombie shooting,
                              puzzle solving, and dancing.
                           </p>
                           <p><span className="mirage-teal"> LIMITED SLOTS AVAILABLE </span> - Please try to book at least 1 day in advance 
                           </p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/sherwood-hammer-boss-2.jpg")} alt="" /></div><div className="content">
                           <h2>Sherwood Forest</h2>
                           <p></p>
                           <p> The once healthy Sherwood Forest is being attacked by evil creatures. You are a team of heroes, each
                               granted unique powers by the gods. Your mission is twofold. First, defend the forest's magical obelisk with your life. Second,
                               beat your friends and family in a dance showdown!
                            </p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/ktane.jpg")} alt="" /></div><div className="content">
                           <h2>Keep Talking and Nobody Explodes</h2>
                           <p></p>
                           <p> You wake up and discover that you are locked in a room with a bomb! Carefully communicate with your teammates to figure out
                               how to defuse it, before you are blown to smithereens!
                           </p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/arizonasunshine.jpg")} alt="" /></div><div className="content">
                           <h2>Arizona Sunshine <a href="#timeshare">(Timeshare)</a></h2>
                           <p></p>
                           <p> You and your friends find yourself in the midst of a zombie apocalypse. Put your survival skills to the test as you
                           explore a post apocalyptic world. Choose your favourite weapon from the armory and put the undead back to rest.
                            </p>
                            <p>*This is a <span className="mirage-teal">Stationary VR</span> experience</p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/smashboxarena.jpg")} alt="" /></div><div className="content">
                           <h2>Smashbox Arena <a href="#timeshare">(Timeshare)</a></h2>
                           <p></p>
                           <p> Go head-to-head with your friends in this action-packed shooter game. Dive into cover, dodge and volley enemy shots, 
                           then strike back with insane combos. Ready, set, Smash!
                            </p>
                            <p>*This is a <span className="mirage-teal">Stationary VR</span> experience</p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/vivegames/vivelaunch.jpg")} alt="" /></div><div className="content">
                           <h2>...And Much More</h2>
                           <p></p>
                           <p> No matter who you are or what you like, our collection of over 50 unique experiences will be sure to delight you.
                            </p>
                            <a name="timeshare"></a>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/vrtimeshare.jpg")} alt="" /></div><div className="content">
                           <h2>Timeshare</h2>
                           <p></p>
                           <p>
                           The <span className="mirage-teal">Stationary VR </span> experiences in this package are timeshared. Participants take turns entering Virtual Reality. While your friends are playing,
                           explore the virtual world through their eyes, and enjoy their funny movements in real life.
                            </p>
                        </div>
                    </section>
                </section>
                    
                <section className="wrapper style5">
                    <div className="inner">
                      <section>
                      <header className="major align-center ">
                      <h3 style={{color:'#2E3842'}}>Experience Features</h3>
                      </header>
                      <div className="row game-features">
                        <div className="col-sm-12 col-md-4 $(medium) align-center">
                          <img className="lazy" src={require("../images/freeroam.png")}/>
                          <h3>Free-Roam<br/> VR</h3>
                          <p>Explore deep forests and sail in an endless ocean. Let your senses be the guide as you physically explore the edge of your imagination.</p>
                        </div>
                        <div className="col-sm-12 col-md-4 $(medium) align-center">
                          <img className="lazy" src={require("../images/collaborative.png")}/>
                          <h3>Collaborative<br/> Adventure</h3>
                          <p>Feel the adrenaline rushing through your body as you work together to discover the unexpected and face the impossible. Are you ready?</p>                        
                        </div>
                        <div className="col-sm-12 col-md-4 $(medium) align-center">
                          <img className="lazy" src={require("../images/pvp.png")}/>
                          <h3>Player Versus<br/> Player</h3>
                          <p>Nothing beats a bit of friendly competition in the safety of virtual reality.
                             Deflect, dodge, and defeat your friends using your new found powers.</p>
                        </div>
                      </div>
                      </section>
                    </div>
                </section>

                <article className="wrapper style3 special no-border" style={{padding:'2em 2em 1em 2em'}}>
                   <section>                    
                        <div className="inner">
                            <section style={{margin:'2em 0em 2em 0em'}}>
                                    <div className="row events-occasions" >
                                        <div className="col-sm-12 col-md-6 $(medium) align-center">
                                           <img src={require('../images/pricestamp.png')} style={{width:'50%'}}/>
                                            <h5 style={{
                                                textTransform: 'capitalize',
                                                fontSize: '1.2em',
                                                }}>Price</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '1.2em',
                                                }}>$40/player 
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 $(medium) align-center">
                                           <img src={require('../images/moneybackguaranteestamp.png')} style={{width:'50%'}}/>
                                           <h5 style={{
                                                textTransform: 'capitalize',
                                                fontSize: '1.2em',
                                                }}>Guarantee</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '1.2em'
                                            }}>We are so sure you will love the experience that we offer a 100% refund if you are not completely satisfied.</p>
                                        </div>
                                    </div>
                            </section>
                        </div>
                    </section>
                </article>


                <section id="one" className="wrapper style2 special">
                    <div class="inner">
                        <h2>Book Best Of Both Worlds Now!</h2>
                        <br/>
                        <p>*Please note you will be broken down into <span className="mirage-teal">2 teams</span>, and rotate
                            between our VR Arena and VR stations.
                        </p>
                        <p>
                           Participants: <input id="quantity" style={{color:'#000000', margin: '0px 10px 0px 0px'}} type="number" name="quantity" min="6" max="10" required /> 
                                         <input type="submit" value="Book Now" onClick={this.showBookDeterminePackage} className="book button special" style={{height:'auto',lineHeight:'inherit',verticalAlign:'middle',padding: '0.5em'}}/>
                        </p>
                    </div>
                </section>
            </article>
            <section className="faq-inline wrapper style7 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                    <div className="inner">
                        <header className="major less-heavy-text">
                                <h1>Have more questions?</h1>
                        </header>
                        <a className="button small" href="/faq">See FAQ</a>
                    </div>
            </section>
            <BookModal 
            quantity={this.state.quantity}
            appointmentType={this.state.appointmentType}
            isGroupReservation = {false}
            showModal={this.state.showModal}
            exitModal={this.exitModal}
            />
        </div>
        )
    }
}


/*Removed Rows */

/* 
<tr style="display:none">
                                                <td style="vertical-align: middle;"><i class="icon fa-clock-o" style="
                                                   color: #ed4933;
                                                   font-size: 3em;
                                                   opacity: 0.8;
                                                   "></i></td>
                                                <td style="
                                                   vertical-align: middle;
                                                   ">
                                                   <div style="
                                                      ">
                                                      <h5 style="text-transform: capitalize;">Length</h5>
                                                      <p style="
                                                         margin-bottom: 0;
                                                         line-height: normal;
                                                         font-size: 0.9em;
                                                         ">
                                                         30 minute reservation<br>
                                                         * 5 minute introduction &amp; virtual training <br>
                                                         * 20 minute game experience <br>
                                                         * 5 minute back-to-reality &amp; debrief
                                                      </p>
                                                   </div>
                                                </td>
                                             </tr>
*/

/* <tr style="display:none">
                                                <td style="vertical-align: middle;"><i class="icon fa-star-o" style="
                                                   color: #ed4933;
                                                   font-size: 3em;
                                                   opacity: 0.8;
                                                   "></i></td>
                                                <td>
                                                   <div>
                                                      <h5 style="
                                                         text-transform: capitalize;
                                                         ">Difficulty</h5>
                                                      <p style="
                                                         margin-bottom: 0;
                                                         line-height: normal;
                                                         font-size: 0.9em;
                                                         ">Available in casual or hardcore mode</p>
                                                   </div>
                                                </td>
                                             </tr>
*/
