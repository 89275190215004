import React from 'react';
import LazyLoad from 'react-lazyload';

export default class AsFeaturedOnLogos extends React.Component{
    constructor(props){
      super(props);
    }

    render(){
      return(

        <LazyLoad height={200} once offset={100}>
        <article id="top-banner" className="wrapper style7 special no-border" style={{padding:'2em 2em 1em 2em'}}>
            <section>                    
            <div className="inner">
                 <header className="major less-heavy-text">
                     <h1> Corporate Team Building Clients:
                     </h1>
                     <br></br>
                 </header>
                 <section>
                         <div className="row">
                             <div className="col-6 col-md-3 image fit align-center"><img className="lazy" src={require("../images/teamproof.png")} /></div>
                             <div className="col-6 col-md-3 image fit align-center"><img className="lazy" src={require("../images/teamproof2.png")}  /></div>
                             <div className="col-6 col-md-3 image fit align-center"><img className="lazy" src={require("../images/teamproof3.png")} /> </div> 
                             <div className="col-6 col-md-3 image fit align-center"><img className="lazy" src={require("../images/teamproof4.png")} /></div>                          
                         </div>
                 </section>
                 </div>
             </section>
         </article>
     </LazyLoad>
      )
    }
}

