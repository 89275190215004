import React from 'react';
import Helmet from 'react-helmet';

import BookModal from '../components/modals/BookModal';

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
    
}

export default class FreeRoamVRPackagePage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            appointmentType: "freeroamcombo",
            mobile: ($(window).width() <= 736)? true : false
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.showBookModal = this.showBookModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });              
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    showBookModal(){
        this.setState(()=> ({showModal: true}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Free-Roam VR Package | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/hyperrealitycombopackage"/>
                </Helmet>
                <article id="main"  >
                    {!this.state.mobile &&
                        <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                            <div className="landing2-bg" style={imgDiv}>  
                              <div style={overlay}>
                                  <img id="landingimage" className="lazy" src={require("../images/freeroam-wide.jpg")} />
                              </div>                    
                            </div>
                            <div id="bannertext">
                                <div className="bannermidtext">
                                <header className="heavy-text">
                                  <h2>Free-Roam Virtual Reality
                                  </h2>
                                </header>
                                <br/>
                                <p>Full-Body, Physically Interactive <span className="mirage-teal">Group Experiences</span></p>
                                </div>
                            </div>
                        </section>
                    }

                    {this.state.mobile &&
                        <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: ninetyheight}}>
                            <div className="landing2-bg" style={imgDiv}>  
                              <div style={overlay}>
                                  <img id="landingimage" className="lazy" src={require("../images/freeroam-wide.jpg")} />
                              </div>                    
                            </div>
                            <div id="bannertext">
                                <div className="bannermidtext">
                                <header className="heavy-text">
                                  <h2>Free-Roam Virtual Reality
                                  </h2>
                                </header>
                                <br/>
                                <p>Full-Body, Physically Interactive Group <span className="mirage-teal">Experiences</span></p>
                                </div>
                            </div>
                        </section>
                    }
                  
                        <article className="wrapper style3 special no-border" style={{padding:'0em 2em 1em 2em'}}>
                           <section>                    
                                <div className="inner">
                                  <section style={{margin:'0em 0em 2em 0em'}}>
                                      <div className="row events-occasions" >
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  2-5
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Players</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  50
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">minutes</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  8+
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Ages</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  $35
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Per Player</span>
                                              </p>
                                          </div>
                                      </div>
                              </section>
                                </div>
                            </section>
                        </article>

                        <div>
                            <section id="two" className="wrapper alt style2">
                                <section className="spotlight">
                                    <div className="image"><img src={require("../images/teambuilding-bg2.jpg")} alt="" /></div><div className="content">
                                       <h2>The Ultimate Group Experience</h2>
                                       <p></p>
                                       <p>Immerse yourself in a world where you can dance, battle, and explore with your friends or family.
                                          A fun night out filled with wonder, laughter, and a lot of funny-looking Instagram posts.
                                       </p>
                                       <a id="gamepage-book" className="button small special" onClick={this.showBookModal} alt="" >Book Free-Roam Combo</a>
                                    </div>
                                </section>
                                <section className="spotlight">
                                    <div className="image"><img src={require("../images/sherwood-hammer-boss-2.jpg")} alt="" /></div><div className="content">
                                       <h2>Sherwood Forest</h2>
                                       <p></p>
                                       <p> The once healthy Sherwood Forest is being attacked by evil creatures. You are a team of heroes, each
                                           granted unique powers from the gods. Your mission is twofold. First, defend the forest's magical obelisk with your life. Second,
                                           beat your friends and family in a dance showdown!
                                        </p>
                                    </div>
                                </section>
                                <section className="spotlight">
                                    <div className="image"><img src={require("../images/dragonfire-ship.jpg")} alt="" /></div><div className="content">
                                       <h2>Dragonfire</h2>
                                       <p></p>
                                       <p> You wake up on a boat sailing towards a mysterious island. Your captain tells of bountiful
                                           treasures on the island, to make you all filthy rich. Arghhhh! Rumors say it's guarded by a fearsome dragon.
                                           But no need to fear matey... we all know dragons don't exist.
                                        </p>
                                    </div>
                                </section>
                            </section>
                        </div>

                    <section className="wrapper style5">
                        <div className="inner">
                          <section>
                          <header className="major align-center ">
                          <h3 style={{color:'#2E3842'}}>Experience Features</h3>
                          </header>
                          <div className="row game-features">
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/freeroam.png")}/>
                              <h3>Free-Roam<br/> VR</h3>
                              <p>Explore deep forests and sail in an endless ocean. Let your senses be the guide as you physically explore the edge of your imagination.</p>
                            </div>
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/collaborative.png")}/>
                              <h3>Collaborative<br/> Adventure</h3>
                              <p>Feel the adrenaline rushing through your body as you work together to discover the unexpected and face the impossible. Are you ready?</p>
                            </div>
                            <div className="col-12 col-md-4 align-center">
                              <img className="lazy" src={require("../images/topscore.png")}/>
                              <h3>Compete For<br/>High Score</h3>
                              <p>Based on your performance in virtual reality, we give you both an individual and team score. Duke it out with friends to reach the top!</p>
                            </div>
                          </div>
                          </section>
                        </div>
                    </section>

                <article className="wrapper style3 special no-border" style={{padding:'2em 2em 1em 2em'}}>
                   <section>                    
                        <div className="inner">
                            <section style={{margin:'2em 0em 2em 0em'}}>
                                    <div className="row events-occasions" >
                                        <div className="col-sm-12 col-md-6 $(medium) align-center">
                                           <img src={require('../images/pricestamp.png')} style={{width:'50%'}}/>
                                            <br/><br/>
                                            <h5 style={{
                                                textTransform: 'capitalize',
                                                fontSize: '1.2em',
                                                }}>Price</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '1.2em',
                                                }}>$35 per player <br/><br/><br/>
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 $(medium) align-center">
                                           <img src={require('../images/moneybackguaranteestamp.png')} style={{width:'50%'}}/>
                                           <br/><br/>
                                           <h5 style={{
                                                textTransform: 'capitalize',
                                                fontSize: '1.2em',
                                                }}>Guarantee</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '1.2em'
                                            }}>We are so sure you will totally love the experience that we offer a 100% refund if you are not completely satisfied.</p>
                                        </div>
                                    </div>
                            </section>
                        </div>
                    </section>
                </article>


                <section id="one" className="wrapper style2 special">
                    <div class="inner">
                        <h2>Book Free-Roam Combo</h2>
                        <br/>
                        <a id="gamepage-book" className="button small special" onClick={this.showBookModal} alt="" >Book Now!</a>
                    </div>
                </section>
            </article>
            <BookModal 
            appointmentType="freeroamcombo"
            isGroupReservation={false}
            showModal={this.state.showModal}
            exitModal={this.exitModal}
            />
        </div>
        )
    }
}


/*Removed Rows */

/* 
<tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-clock-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td style="
						                           vertical-align: middle;
						                           ">
						                           <div style="
						                              ">
						                              <h5 style="text-transform: capitalize;">Length</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">
						                                 30 minute reservation<br>
						                                 * 5 minute introduction &amp; virtual training <br>
						                                 * 20 minute game experience <br>
						                                 * 5 minute back-to-reality &amp; debrief
						                              </p>
						                           </div>
						                        </td>
                                             </tr>
*/

/* <tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-star-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td>
						                           <div>
						                              <h5 style="
						                                 text-transform: capitalize;
						                                 ">Difficulty</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">Available in casual or hardcore mode</p>
						                           </div>
						                        </td>
                                             </tr>
*/
