import React from 'react';
import Helmet from 'react-helmet';

const GiftPage = () => (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Gift Cards | Mirage VR</title>
            <link rel="canonical" href="https://www.miragevr.ca/gift"/>
        </Helmet>
        <article id="main" style={{backgroundColor: 'black'}}>
            <iframe id='acuity-iframe' src="https://app.acuityscheduling.com/catalog.php?owner=18317231" width="100%" height={$(window).height()} frameBorder="0"></iframe>
            <script src="https://d3gxy7nm8y4yjr.cloudfront.net/js/embed.js" type="text/javascript"></script>
        </article>
    </div>
);

export default GiftPage;