import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet';
import butter from '../server/butter-client'

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
    
}

export default class BlogPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            meta: {},
            data: []
        }
    }

    async componentDidMount () {
        const { match } = this.props
        let page = match.params.page || 1

        const resp = await butter.post.list({ page: page, page_size: 30 })
        this.setState(resp.data)

        butter.post.list({page: 1, page_size: 30}).then(function(response) {
            console.log(response)
        })
    }

    render () {
        const { next_page, previous_page } = this.state.meta
        return (
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Blog | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/experiences"/>
                </Helmet>
                <article id="main">
                    <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                        <div className="landing2-bg" style={imgDiv}>  
                          <div style={overlay}>
                              <img id="landingimage" className="lazy" src={require("../images/blogcover2.jpg")} />
                          </div>                    
                        </div>
                        <div id="bannertext">
                            <div className="bannermidtext">
                            <header className="heavy-text">
                                <h2 className="mirage-teal">Blog</h2>
                            </header>
                            <br/>
                                <p>The Newest Trends in Entertainment</p>
                            </div>
                        </div>
                    </section>
                    <section id="two" className="wrapper alt style2">
                      <div>
                        {this.state.data.map((post, key) => {
                          var post_url = "/" + post.slug
                          return (
                            <section className="spotlight">
                                <div className="image">
                                    <a href={post_url}><img src={post.featured_image} alt="" /></a>
                                </div>
                                <div key={key} className="content">
                                    <h2><a href={post_url}>{post.title}</a></h2>
                                    <p></p>
                                    <p>
                                    {post.summary}
                                    </p>
                                </div>
                            </section>
                          )
                        })}

                        <br />

                        <div>
                          {previous_page && (
                            <Link to={`/blog/${previous_page}`}>
                              <a>Prev</a>
                            </Link>
                          )}

                          {next_page && (
                            <Link to={`/blog/${next_page}`}>
                              <a>Next</a>
                            </Link>
                          )}
                        </div>
                      </div>
                    </section>
                </article>
            </div>
        )
    }
}