import React from 'react';
import Helmet from 'react-helmet';

import BookModal from '../components/modals/BookModal';

export default class VirtualRealityPage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            mobile: ($(window).width() <= 736)? true : false
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });              
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Virtual Reality | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/virtualreality"/>
                </Helmet>
                <article id="main"  >
                    <header className="virtualreality-bg">
                        <div className="heavy-text major">
                            <h1>Virtual <span style={{color:'blue'}}>Reality</span></h1>
                        </div>
                    </header>
                    <section className="wrapper style5" style={{padding: '3em 0 0 0 ! important'}}>
                        <div className="" style={{
                            marginLeft: '3em',
                            marginRight: '3em'
                        }}>
                            <section>
                                <div className="row">
                                    <div className="col-12 col-sm-3 col-lg-3">
                                        <span className="image fit"><img src={require("../images/virtualreality.jpg")} alt=""/></span>
                                    </div>
                                    <div className="col-12 col-sm-9 col-lg-5">
                                        <h3>Description</h3>
                                        <p> Greetings, Adventurer... what do you search for? Is it intense, action-packed battles, or careful puzzle solving? Partying with your friends, or travelling solo? Ayyy, I can only offer suggestions... but the choice is ultimately yours. 
                                        </p>
                                        <h5 style={{display:'none', textTransform: 'capitalize'}}>Tickets</h5>
                                        <p>Starting from <b>$20</b>/person<br/><br/> <span>*Available November 15th, 2018. Discounted Early Bird Tickets are now on sale! Note: The experiences listed here are not free-roam or full body.</span></p>
                                        <a id="gamepage-book" className="button special" onClick={this.openModal} alt="" >Book Now</a>
                                    </div>

                                    <div className="col-12 col-sm-12 col-lg-4">
                                        <h3>Detail</h3>
                                        <table style={{textTransform:'capitalize'}}>
                                            <tbody style={{ verticalAlign: 'middle'}}>
                                                <tr>
                                                    <td style={{verticalAlign: 'middle'}}><i className="icon fa-users" style={{
                                                    color: '#ed4933',
                                                    fontSize: '3em',
                                                    opacity: '0.8'
                                                    }}></i></td>
                                                    <td>
                                                        <div>
                                                            <h5 style={{
                                                                textTransform: 'capitalize'
                                                                }}>Team size</h5>
                                                            <p style={{
                                                                marginBottom: '0',
                                                                lineHeight: 'normal',
                                                                fontSize: '0.9em',
                                                                }}>Standard Mode: 2-5 players <br/>Tournament Mode: 6-10 players <br/>Party Mode: 11-20 players </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{verticalAlign: 'middle'}}>
                                                        <img src={require('../images/money-back.png')} style={{width:"53px"}}/>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <h5 style={{
                                                                textTransform: 'capitalize'
                                                                }}>Satisfaction Guarantee</h5>
                                                            <p style={{
                                                                marginBottom: '0',
                                                                lineHeight: 'normal',
                                                                fontSize: '0.9em'
                                                            }}>We are so sure that you will totally love the experience that we offer a 100% refund if otherwise</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>`
                    </div>
                </section>
            </article>
            <article>
                <span className="image fit"><img src={require("../images/vivegames/vivelaunch.jpg")} alt=""/></span>
            </article>
            <BookModal 
            showModal={this.state.showModal}
            exitModal={this.exitModal}
            />
        </div>
        )
    }
}


/*Removed Rows */

/* 
<tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-clock-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td style="
						                           vertical-align: middle;
						                           ">
						                           <div style="
						                              ">
						                              <h5 style="text-transform: capitalize;">Length</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">
						                                 30 minute reservation<br>
						                                 * 5 minute introduction &amp; virtual training <br>
						                                 * 20 minute game experience <br>
						                                 * 5 minute back-to-reality &amp; debrief
						                              </p>
						                           </div>
						                        </td>
                                             </tr>
*/

/* <tr style="display:none">
						                        <td style="vertical-align: middle;"><i class="icon fa-star-o" style="
						                           color: #ed4933;
						                           font-size: 3em;
						                           opacity: 0.8;
						                           "></i></td>
						                        <td>
						                           <div>
						                              <h5 style="
						                                 text-transform: capitalize;
						                                 ">Difficulty</h5>
						                              <p style="
						                                 margin-bottom: 0;
						                                 line-height: normal;
						                                 font-size: 0.9em;
						                                 ">Available in casual or hardcore mode</p>
						                           </div>
						                        </td>
                                             </tr>
*/
