import React from 'react';
import BookModal from './modals/BookModal';
import LocationChooser from './modals/LocationChooser';

export default class Header extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            mobile: ($(window).width() <= 763)? true : false,
            appointmentType: ""
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.goToExperiences = this.goToExperiences.bind(this);
        this.bookModalRef = React.createRef();  
    }

    componentDidMount(){

        if ($(window).width() <= 1024) {
            //show mobile version of header
            $('#header').addClass("is-mobile");
        } else {
            $('#header').removeClass("is-mobile");
        }
        
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 763){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
            }

            if ($(window).width() <= 1024) {
                //show mobile version of header
                $('#header').addClass("is-mobile");
            } else {
                $('#header').removeClass("is-mobile");
            }
        });  

        //console.log('header mount');
        //SKEL vars
        var $window = $(window),
        $body = $('body'),
        $wrapper = $('#page-wrapper'),
        $banner = $('#banner'),
        $header = $('#header');

        $.fn.navList = function() {
            var	$this = $(this),
            $a = $this.find('a'),
            b = [];
        
            $a.each(function() {
        
                var	$this = $(this),
                    indent = Math.max(0, $this.parents('li').length - 1),
                    href = $this.attr('href'),
                    target = $this.attr('target'),
                    onclick = '';
        
                if (href != null && href.indexOf('menu') > 0) {
                    return true;
                }
        
                if (href != null && href.indexOf('book') > 0) {
                    onclick="booknow('NavbarSide')";
                }			
        
                b.push(
                    '<a ' +
                        'class="link depth-' + indent + '"' +
                        ( (typeof target !== 'undefined' && target != '') ? ' target="' + target + '"' : '') +
                        ( (typeof href !== 'undefined' && href != '') ? ' href="' + href + '"' : '') +
                        ( (typeof onclick !== 'undefined' && onclick != '') ? ' onclick="' + onclick + '"' : '') +
                    '>' +
                        '<span class="indent-' + indent + '"></span>' +
                        $this.text() +
                    '</a>'
                );
        
            });
        
            return b.join('');
        
        };
        
        $.fn.panel = function(userConfig) {

            // No elements?
                if (this.length == 0)
                    return $this;
    
            // Multiple elements?
                if (this.length > 1) {
    
                    for (var i=0; i < this.length; i++)
                        $(this[i]).panel(userConfig);
    
                    return $this;
    
                }
    
            // Vars.
                var	$this = $(this),
                    $body = $('body'),
                    $window = $(window),
                    id = $this.attr('id'),
                    config;
    
            // Config.
                config = $.extend({
    
                    // Delay.
                        delay: 0,
    
                    // Hide panel on link click.
                        hideOnClick: false,
    
                    // Hide panel on escape keypress.
                        hideOnEscape: false,
    
                    // Hide panel on swipe.
                        hideOnSwipe: false,
    
                    // Reset scroll position on hide.
                        resetScroll: false,
    
                    // Reset forms on hide.
                        resetForms: false,
    
                    // Side of viewport the panel will appear.
                        side: null,
    
                    // Target element for "class".
                        target: $this,
    
                    // Class to toggle.
                        visibleClass: 'visible'
    
                }, userConfig);
    
                // Expand "target" if it's not a jQuery object already.
                    if (typeof config.target != 'jQuery')
                        config.target = $(config.target);
    
            // Panel.
    
                // Methods.
                    $this._hide = function(event) {
    
                        // Already hidden? Bail.
                            if (!config.target.hasClass(config.visibleClass))
                                return;
    
                        // If an event was provided, cancel it.
                            if (event) {
    
                                event.preventDefault();
                                event.stopPropagation();
    
                            }
    
                        // Hide.
                            config.target.removeClass(config.visibleClass);
    
                        // Post-hide stuff.
                            window.setTimeout(function() {
    
                                // Reset scroll position.
                                    if (config.resetScroll)
                                        $this.scrollTop(0);
    
                                // Reset forms.
                                    if (config.resetForms)
                                        $this.find('form').each(function() {
                                            this.reset();
                                        });
    
                            }, config.delay);
    
                    };
    
                // Vendor fixes.
                    $this
                        .css('-ms-overflow-style', '-ms-autohiding-scrollbar')
                        .css('-webkit-overflow-scrolling', 'touch');
    
                // Hide on click.
                    if (config.hideOnClick) {
    
                        $this.find('a')
                            .css('-webkit-tap-highlight-color', 'rgba(0,0,0,0)');
    
                        $this
                            .on('click', 'a', function(event) {
    
                                var $a = $(this),
                                    href = $a.attr('href'),
                                    target = $a.attr('target');
    
                                if (!href || href == '#' || href == '' || href == '#' + id)
                                    return;
    
                                // Cancel original event.
                                    event.preventDefault();
                                    event.stopPropagation();
    
                                // Hide panel.
                                    $this._hide();
    
                                // Redirect to href.
                                    window.setTimeout(function() {
    
                                        if (target == '_blank')
                                            window.open(href);
                                        else
                                            window.location.href = href;
    
                                    }, config.delay + 10);
    
                            });
    
                    }
    
                // Event: Touch stuff.
                    $this.on('touchstart', function(event) {
    
                        $this.touchPosX = event.originalEvent.touches[0].pageX;
                        $this.touchPosY = event.originalEvent.touches[0].pageY;
    
                    })
    
                    $this.on('touchmove', function(event) {
    
                        if ($this.touchPosX === null
                        ||	$this.touchPosY === null)
                            return;
    
                        var	diffX = $this.touchPosX - event.originalEvent.touches[0].pageX,
                            diffY = $this.touchPosY - event.originalEvent.touches[0].pageY,
                            th = $this.outerHeight(),
                            ts = ($this.get(0).scrollHeight - $this.scrollTop());
    
                        // Hide on swipe?
                            if (config.hideOnSwipe) {
    
                                var result = false,
                                    boundary = 20,
                                    delta = 50;
    
                                switch (config.side) {
    
                                    case 'left':
                                        result = (diffY < boundary && diffY > (-1 * boundary)) && (diffX > delta);
                                        break;
    
                                    case 'right':
                                        result = (diffY < boundary && diffY > (-1 * boundary)) && (diffX < (-1 * delta));
                                        break;
    
                                    case 'top':
                                        result = (diffX < boundary && diffX > (-1 * boundary)) && (diffY > delta);
                                        break;
    
                                    case 'bottom':
                                        result = (diffX < boundary && diffX > (-1 * boundary)) && (diffY < (-1 * delta));
                                        break;
    
                                    default:
                                        break;
    
                                }
    
                                if (result) {
    
                                    $this.touchPosX = null;
                                    $this.touchPosY = null;
                                    $this._hide();
    
                                    return false;
    
                                }
    
                            }
    
                        // Prevent vertical scrolling past the top or bottom.
                            if (($this.scrollTop() < 0 && diffY < 0)
                            || (ts > (th - 2) && ts < (th + 2) && diffY > 0)) {
    
                                event.preventDefault();
                                event.stopPropagation();
    
                            }
    
                    });
    
                // Event: Prevent certain events inside the panel from bubbling.
                    $this.on('click touchend touchstart touchmove', function(event) {
                        event.stopPropagation();
                    });
    
                // Event: Hide panel if a child anchor tag pointing to its ID is clicked.
                    $this.on('click', 'a[href="#' + id + '"]', function(event) {
    
                        event.preventDefault();
                        event.stopPropagation();
    
                        config.target.removeClass(config.visibleClass);
    
                    });
    
            // Body.
    
                // Event: Hide panel on body click/tap.
                    $body.on('click touchend', function(event) {
                        $this._hide(event);
                    });
    
                // Event: Toggle.
                    $body.on('click', 'a[href="#' + id + '"]', function(event) {
    
                        event.preventDefault();
                        event.stopPropagation();
    
                        config.target.toggleClass(config.visibleClass);
    
                    });
    
            // Window.
    
                // Event: Hide on ESC.
                    if (config.hideOnEscape)
                        $window.on('keydown', function(event) {
    
                            if (event.keyCode == 27)
                                $this._hide(event);
    
                        });
    
            return $this;
    
        };

        /*----------------------------- PROMO -------------------------------*/
        
        /*
        if ( window.location.pathname.indexOf('experiences') > -1 ){
            $('.promo').addClass('hide');
        } 

        if ( window.location.pathname.indexOf('book') > -1 ){
            $('.promo').addClass('hide');
        } 
        */

        if ($('.promo').is(':visible')) {
            $('#header').css('top', $('.promo').height());
        }

        /*----------------------------- HEADER ------------------------------*/
        //Dropdown
        $('#nav > ul').dropotron({
             alignment: 'left',
             hideDelay: 350,
             mode: 'slide'
        });
        
        // Menu.
		$(
            '<div id="navPanel">' +
                '<nav>' +
                    $('#nav').navList() +
                '</nav>' +
            '</div>'
        )
        .appendTo($body)
        .panel({
            delay: 500,
            hideOnClick: true,
            hideOnSwipe: true,
            resetScroll: true,
            resetForms: true,
            side: 'right',
            target: $body,
            visibleClass: 'is-menu-visible'
        });


		//Sidemenu toggle
        $('.menuToggle').on('click', function(e) {
            e.preventDefault();
            console.log("OPEN MENUE");
            $body.addClass('is-menu-visible');
            return false;
        });
        

                

        // --MAKE HEADER OPAQUE AS YOU SCROLL THROUGH THE PAGE-----
        function checkHeaderOpacity()
        {
            var header = $('#header');
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var headerTop = $(header).offset().top;
            var headerBottom = headerTop + $(header).height();

            if (headerBottom > $(header).height()) {
                header.addClass('black-background');
            } else {
                header.removeClass('black-background');
            }
        }

        $(window).scroll(function() {    
            checkHeaderOpacity();  
        });
        


    }

    openModal(){
        //console.log('hello');
        this.setState(()=> ({showModal: true}));
        return false;
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    goToExperiences(){
        var url = window.location.href;
        if (url.indexOf('freeroamvrcombopackage')>0) {
            this.bookModalRef.current.state.appointmentType = "freeroamcombo";
            this.setState(()=> ({showModal: true}));
        } else if (url.indexOf('decomposedpackage')>0) {
            this.bookModalRef.current.state.appointmentType = "decomposed";
            this.setState(()=> ({showModal: true}));
        } else if (url.indexOf('stationaryvrpackage')>0) {
            this.bookModalRef.current.state.appointmentType = "stationaryvr";
            document.location.href = "/book?appointmentType=stationaryvr";
            this.setState(()=> ({showModal: false}));
        } else if (url.indexOf('bestofbothworldspackage')>0) {
            this.bookModalRef.current.state.appointmentType = "bestofbothworlds";
            document.location.href = "/book?appointmentType=bestofbothworlds";
            this.setState(()=> ({showModal: false}));
        } else if (url.indexOf('defendanddefusepackage')>0) {
            this.bookModalRef.current.state.appointmentType = "defendanddefuse",    
            this.setState(()=> ({showModal: true}));
        } else if (url.indexOf('partypackage')>0) {
            this.bookModalRef.current.state.appointmentType = "party";
            this.setState(()=> ({showModal: true}));
        } else if (url.indexOf('deluxepartypackage')>0) {
            this.bookModalRef.current.state.appointmentType = "deluxeparty";
            this.setState(()=> ({showModal: true}));
        } else if (url.indexOf('escape')>0) {
            this.bookModalRef.current.state.appointmentType = "decomposed";
            this.setState(()=> ({showModal: true}));
        } else if (url.indexOf('birthday')>0) {
            location.href="#experienceChooser";
        } else if (url.indexOf('teambuilding')>0) {
            location.href="#bookteambuilding";
        } else {
            location.href='/experiences#booknow';
        }
    }
    render(){
        var isCorporate =
            this.props.location.pathname=="/partner" ||
            this.props.location.pathname=="/license" ||
            this.props.location.pathname=="/franchise";
        return (
            <div>
                <div class="promo wrapper">
                    <div class="inner align-center">
                        {
                            /*
                            * use  <br class="mobile-break"></br> to insert mobile breaks
                            */
                        }
                    <p style={{margin:'0em'}}>
                        <a href="/faq#covid19">We are committed to providing a safe and clean environment for our team members and our guests. <span class="underline" >Learn More</span></a>
                    </p>
                    </div>
                </div>
            <header id="header" className="is-mobile">

            <h1><a href="/">MIRAGE <span className="mirage-teal">VR</span></a></h1>
              <nav id="nav">
              <ul>
                {true &&
                    <li><a class="changecolor" href="/gift">Gift Card</a></li> 
                }   
                {/*
                <li>
                  <a href="/experiences"><span className="icon fa-angle-down"></span> Experiences</a>
                  <ul>
                    <li><a href="/sherwood">Sherwood Forest</a></li>
                    <li><a href="/dragonfire">Dragonfire</a></li>
                    <li><a href="/virtualreality">Virtual Reality</a></li>
                  </ul>
                </li> */
                }

                {!isCorporate &&
                    <li><a href="/experiences">Experiences</a></li>
                }

                {!isCorporate &&
                    <li><a href="/birthday">Birthdays</a></li>
                }
                {!isCorporate &&
                    <li><a href="/teambuilding">Team Building</a></li>
                }
                {false && !isCorporate &&
                    <li><a href="/escape">Escape</a></li>
                }

                <li><a href="/faq">FAQ</a></li>

                <li>
                  <a href=""><span className="icon fa-angle-down"></span> Locations</a>
                  <ul>
                    <li><a href="/Kitchener">Kitchener</a></li>
                    <li><a href="/Mississauga">Mississauga</a></li>
                    <li><a href="/Burlington">Burlington (Coming Soon)</a></li>
                  </ul>
                </li>

                <li>
                    <button onClick={this.goToExperiences}
                            className="button special" style={{
                            letterSpacing: '.225em',
                            textTransform: 'uppercase',
                            fontSize: '0.7em',
                            backgroundColor: '#ed4933',
                            padding: '0 1.5em',
                            lineHeight: '2.5em',
                            verticalAlign:'base-line',
                            margin: '0',
                            height: '2.5em',
                            marginRight: '1.5em'
                        }}><span style={{
                            margin: '0',
                            padding: '0',
                        }}>Book Now</span>
                    </button>
                </li>

                <li className="special">
                  <a href="#menu" className="menuToggle"><span>Menu</span></a>
                </li>
              </ul>
            </nav>
            </header>
            <BookModal 
                ref={this.bookModalRef}
                appointmentType={this.state.appointmentType} 
                showModal={this.state.showModal}
                exitModal={this.exitModal}
            />  

         </div>   
        )
    }
};

/* book mobile to /book

                {!this.state.mobile &&
                    <a id="navbar-book" onClick={this.openModal} className="book-button"><span style={spanButtonStyle}>Book Now</span></a>
                }

                {this.state.mobile &&
                <li>
                    <a id="book-button" href="/book" className="book-button"><span>Book Now</span></a>
                </li>
                }
*/

/*
<BookModal 
                    showModal={this.state.showModal}
                    exitModal={this.exitModal}
                    switchModalUserInfo={this.switchModalUserInfo}
                    switchModalGroupReservation={this.switchModalGroupReservation}
                />
                <UserInfoModal
                    showModal={this.state.showUserInfoModal}
                    exitModal={this.exitModal}
                />
                <GroupReservationModal
                    showModal={this.state.showGroupReservationModal}
                    exitModal={this.exitModal}
                />
                <GroupReservationModal
                    showModal={this.state.showThankYouModal}
                    exitModal={this.exitModal}
                    switchModalThankYou={this.switchModalThankYou}
                />                
           
*/

/*
                    
                    <UserInfoModal
                       showModal={this.state.showUserInfoModal}
                        exitModal={this.exitModal}
                    />
                    <GroupReservationModal
                        showModal={this.state.showGroupReservationModal}
                        exitModal={this.exitModal}
                    />
                    <GroupReservationModal
                        showModal={this.state.showThankYouModal}
                        exitModal={this.exitModal}
                        switchModalThankYou={this.switchModalThankYou}
                    /> 
*/

