import "babel-polyfill";
//install -> import -> use
import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from '../routers/AppRouter';
//import 'normalize.css/normalize.css';
import '../css/bundle.css';
import '../css/modal.css';
import '../css/grid.css'
import Footer from '../components/Footer'

ReactDOM.render(<AppRouter />,document.getElementById('app'));


module.hot.accept();
