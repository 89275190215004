import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Testimonials from '../components/Testimonials';
import EmailSubscribeForm from './EmailSubscribeForm';

//this isn't scripted on index instead it is installed as a dependancy
import LeadMagnetModal from '../components/modals/LeadMagnetModal';
import Cookies from 'universal-cookie';
import LazyLoad from 'react-lazyload';
import BookModal from '../components/modals/BookModal';
import AsFeaturedOnLogos from '../components/AsFeaturedOnLogos';
import Collapsible from 'react-collapsible';

const height = String($(window).height()*(1)) + "px";
const ninetyheight= String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    paddingTop: '3em',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.5)'
    
}

const img = {
   width: imgWidth,
   height: imgHeight,
   opacity: '0.65'
}

export default class BurlingtonPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showVideo: false,
            showLeadMagnet: false,
            showBookModal: false,
            mobile: ($(window).width() <= 763)? true : false,
            portrait: ($(window).width() <= 500)? true : false,
            videourl: null,
            playing: true,
            leadMagnetPoppedUp: false,

        }
        this.openVideoModal = this.openVideoModal.bind(this);
        this.exitVideoModal = this.exitVideoModal.bind(this);
        this.openLeadMagnetModal = this.openLeadMagnetModal.bind(this);
        this.exitLeadMagnetModal = this.exitLeadMagnetModal.bind(this);
        this.checkLeadMagnetPopup = this.checkLeadMagnetPopup.bind(this);
        this.checkLeadMagnetPopupLongWait = this.checkLeadMagnetPopupLongWait.bind(this);
        this.playVideoMobile = this.playVideoMobile.bind(this);
        this.playVideoDesktop = this.playVideoDesktop.bind(this);
        this.openBookModal = this.openBookModal.bind(this);
        this.exitBookModal = this.exitBookModal.bind(this);
        this.trackSessionDuration = this.trackSessionDuration.bind(this);
        this.trackSessionDurationAfterDelay = this.trackSessionDurationAfterDelay.bind(this);
        this.videoplayer = React.createRef();
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 763){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
            if($(window).width() <= 500){
                this.setState(()=> ({portrait: true}));
            }
            else {
                 this.setState(()=> ({portrait: false}));
         }
        });
        //$("div.fullscreen-bg").css('width', String($(window).width())+'px');
    }

    openVideoModal(){
        mixpanel.track("Video play");
        this.setState(()=> ({showVideo: true}));

    }
    
    exitVideoModal(){
        mixpanel.track('Video close');
        this.setState(()=> ({showVideo: false}));
    }

    playVideoMobile(){
        posthog.capture('Play Video');
          var videowrapper = document.getElementById("mobileVideoHider");
          var playbutton = document.getElementById("watchTrailerMobile");
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          window._vis_opt_queue = window._vis_opt_queue || [];
          window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
          //this.checkLeadMagnetPopup()
          //var videoiframe = video.getElementsByTagName('iframe')[0];
          //this.setState({videourl: 'https://www.youtube.com/watch?v=eHkBBV7ccsc',playing: 'true'})
          //screenfull.request(findDOMNode(video));
          /*  if (videoiframe.requestFullscreen) {
                videoiframe.requestFullscreen();
            } else if (videoiframe.webkitRequestFullscreen) {
                videoiframe.webkitRequestFullscreen();
            } else if (videoiframe.mozRequestFullScreen) {
                videoiframe.mozRequestFullScreen();
            } else if (videoiframe.msRequestFullscreen) {
                videoiframe.msRequestFullscreen();
            }*/
    }

    playVideoDesktop(){
        posthog.capture('Play Video');
          var videowrapper = document.getElementById("desktopVideoHider");
          var playbutton = document.getElementById("watchTrailerDesktop");
          var bg = document.getElementsByClassName("fullscreen-bg")[0];
          videowrapper.style.display = "block";
          playbutton.style.display = "none";
          this.videoplayer.current.play();
          window._vis_opt_queue = window._vis_opt_queue || [];
          window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
          //this.checkLeadMagnetPopup()
          /*if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            }*/
    }


    openLeadMagnetModal(){
        if (this.state.leadMagnetPoppedUp == false && ((this.videoplayer.current != null) && (this.videoplayer.current.paused || this.videoplayer.current.ended))) {
            let cookies = new Cookies();
            cookies.set('visitedbefore', true, { path: '/' });
            this.setState(()=> ({leadMagnetPoppedUp: true}));
            this.setState(()=> ({showLeadMagnet: true}));
        }
    }

    exitLeadMagnetModal(){
        this.setState(()=> ({showLeadMagnet: false}));
    }

    checkLeadMagnetPopup(){
        let cookies = new Cookies();
        if(this.state.leadMagnetPoppedUp == false && typeof cookies.get('visitedbefore') == 'undefined'){
            setTimeout(this.openLeadMagnetModal, 5000);
            setTimeout(this.checkLeadMagnetPopup, 5000);
        }
    }

    checkLeadMagnetPopupLongWait(){
        /*
        let cookies = new Cookies();
        if(typeof cookies.get('visitedbefore') == 'undefined'){
            setTimeout(this.openLeadMagnetModal, 3000);
        }*/
    }

    trackSessionDurationAfterDelay(){
        setTimeout(this.trackSessionDuration, 60000);
    }

    trackSessionDuration(){
        window._vis_opt_queue = window._vis_opt_queue || [];
        window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(201);});
    }

    openBookModal(){
        this.setState(()=> ({showBookModal: true}));
    }

    exitBookModal(){
        this.setState(()=> ({showBookModal: false}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <link rel="canonical" href="https://www.miragevr.ca/burlington"/>
                    <meta name='description' content="Burlington, ON residents can now experience free-roaming VR, the most fun activity around! Learn more about free-roaming VR & plan your next event today!"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Experience Virtual Reality In Burlington, ON | Mirage VR"/>
                    <meta property="og:description" content="Canada's first full-body VR destination. Gear up to enter a new dimension where you battle, dance, and explore with friends, family, or team. Book now to start your next adventure!"/>
                    <meta property="og:url" content="https://www.miragevr.ca/burlington"/>
                    <meta property="og:site_name" content="Mirage VR"/>
                    <meta property="og:image" content="https://miragevr.ca/images/hyperrealityexplanation.jpg"/>
                    <meta property="og:image:width" content="1500"/>
                    <meta property="og:image:height" content="1050"/>
                    <meta property="fb:app_id" content="118069268859718"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:description" content="Canada's first full-body VR destination. Gear up to enter a new dimension where you battle, dance, and explore with friends, family, or team. Book now to start your next adventure!"/>
                    <meta name="twitter:title" content="Experience Virtual Reality In Burlington, ON | Mirage VR"/>
                    <meta name="twitter:site" content="@entermirage"/>
                    <meta name="twitter:image" content="https://miragevr.ca/images/hyperrealityexplanation.jpg"/>
                    <meta name="twitter:creator" content="@entermirage"/>
                    <title>Experience Virtual Reality In Burlington, ON | Mirage VR</title>
                </Helmet>
                <LeadMagnetModal
                showModal={this.state.showLeadMagnet}
                exitModal={this.exitLeadMagnetModal}
                />

                
                {this.checkLeadMagnetPopupLongWait()}
                {this.trackSessionDurationAfterDelay()}

                {!this.state.mobile &&
                <div className="fullscreen-bg">
                    <video id="background-video" className="background-video" src={require("../videos/10sectrailer5.mp4")} type="video/mp4" muted loop autoplay=""/>
                    <div className="fullscreen-overlay video-overlay-diagonal"></div>
                    <div className="fullscreen-overlay frame-overlay"></div>
                </div>
                }
                {!this.state.mobile &&
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                    <div className="inner">
                    <div className="likeh1"><span className="mirage-teal">Coming Soon</span>
                    </div>
                        <p>
                            Check out our Mississauga store @ QEW and Winston Churchill
                        </p>
                    </div>
                    <ul className="actions">
                            <li><div>
                                    <div id="desktopVideoHider">
                                     <video ref={this.videoplayer} id="desktopTrailerVideo" controls type="video/mp4" src={require("../videos/trailer2.mp4")} preload="metadata"/>
                                    </div>
                                    <img id="watchTrailerDesktop" src={require("../images/playbutton.png")} onClick={this.playVideoDesktop} className="small"/>
                            </div></li>
                    </ul>
                </section>
                }
                {this.state.mobile &&
                <div className="landing2-bg" style={imgDiv}>  
                    <div style={overlay}>
                        <img id="landingimage" className="lazy" src={require("../images/dragonbreath.jpg")} />
                    </div>                    
                    <div className="fullscreen-overlay video-overlay-diagonal"></div>
                    <div className="fullscreen-overlay frame-overlay"></div>
                </div>
                }
                {this.state.mobile &&
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: ninetyheight}}>
                    <div id="bannertext">
                        <div className="bannertoptext">
                        <div className="likeh1">Experience The <span className="mirage-teal">Future Of Entertainment</span>
                        </div>
                        </div>
                        <div id="playvidbutton">
                                <div id="mobileVideoHider">
                                    <video ref={this.videoplayer} id="mobileTrailerVideo" controls type="video/mp4" src={require("../videos/trailer2.mp4")} preload="metadata"/>
                                </div>     
                                <img id="watchTrailerMobile" 
                                    src={require("../images/playbutton.png")} 
                                    onClick={this.playVideoMobile} 
                                className="small" />                  
                        </div>
                        <div className="bannerbottomtext">
                        <p id="landingdescription">
                            The Best Group Experience On The Planet, Powered by <span className="mirage-teal">Virtual Reality</span>
                        </p>
                        </div>
                    </div>
                </section>
                }

                <AsFeaturedOnLogos />
                
                <LazyLoad height={600} once offset={100}>
                    <section id="two" className="wrapper alt style2">
                        <section className="spotlight">
                            <div className="image"><img src={require("../images/whatisvirtualreality.jpg")} alt="" /></div><div className="content">
                               <h1>Experience Free-Roaming VR in Burlington, ON</h1>
                               <p></p>
                                    <p>We combine state-of-the-art virtual reality technology
                                    with customized content to deliver a mind shattering adventure. Gear up and enter a new dimension where you battle, 
                                    dance and explore using your own hands and feet. A completely intuitive experience that is suitable for all ages and all 
                                    occasions. Gather your team and experience the best group activity on Earth, period. 
                                    </p>
                                <p>
                                <a href="/experiences" id="gamepage-book" className="button small special" alt="" >See Experiences</a>
                                </p>
                            </div>
                        </section>
                    </section>
                </LazyLoad>

                <LazyLoad height={600} once offset={400}>
                    <section className="wrapper style3 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                        <div className="inner">
                        <header className="major less-heavy-text">
                                <h2>Virtual Reality In Burlington, ON
                                </h2>
                        </header>
                        </div>
                    </section>
                    <section className="wrapper style5">
                        <div className="inner">
                          <section>
                          <header className="major align-center ">
                          <div className="likeh3" style={{color:'#2E3842'}}>Events and Occasions</div>
                          </header>
                                <div>
                                  <div className="row events-occasions">
                                    <div className="col-12 col-md-4 align-center">
                                      <a href="/experiences"><img className="lazy" src={require("../images/groupfun.jpg")}/></a>
                                      <div className="likeh4">Group Fun</div>
                                      <p>Looking for a new and unique activity? Team up with friends and embark on an adventure full of
                                         wonder, laughs, and funny instagram posts.</p>
                                    </div>
                                    <div className="col-12 col-md-4 align-center">
                                      <a href="/birthday"><img className="lazy" src={require("../images/birthdayparties.jpg")}/></a>
                                      <div className="likeh4">Birthday Parties</div>
                                      <p>Throw the coolest birthday party in town, where participants live their wildest dreams.
                                         An event full of laughter, excitement and fun that is suitable for both Kids and Adults.
                                      </p>
                                    </div>
                                    <div className="col-12 col-md-4 align-center">
                                      <a href="/teambuilding"><img className="lazy" src={require("../images/teamwork.jpg")}/></a>
                                      <div className="likeh4">Team Building</div>
                                      <p>Team up for the most immersive team building activity on the planet. Work together to solve a variety of challenges, 
                                         and forge bonds that last far into the future.</p>
                                    </div>
                                  </div>
                                  <div className="row events-occasions">
                                    <div className="col-12 col-md-4 align-center">
                                      <img className="lazy" src={require("../images/bacheloretteparty.jpg")}/>
                                      <div className="likeh4">Bachelor/Bachelorette Parties</div>
                                      <p>Before tying the knot, why not go out for an unforgettable night of fun? Watch your guests dance, battle,
                                         and laugh as they explore a new dimension. </p>
                                    </div>
                                    <div className="col-12 col-md-4 align-center">
                                      <img className="lazy" src={require("../images/fieldtrip.jpg")}/>
                                      <div className="likeh4">Field Trips</div>
                                      <p>Are you looking for a student activity that is both mentally engaging AND physically stimulating?
                                         Then you've come to the right place :).
                                      </p>
                                    </div>
                                    <div className="col-12 col-md-4 align-center">
                                      <img className="lazy" src={require("../images/mobilevr.jpg")}/>
                                      <div className="likeh4">Mobile VR</div>
                                      <p>Don't want to commute all the way to our facility? We will bring our system to you!</p>
                                    </div>
                                  </div>
                                <div className="col-12 col-md-12 align-center">
                                    <a href="/experiences" id="gamepage-book" className="button small special" alt=""> See Experiences</a>
                                </div>
                                </div>
                        
                          </section>
                        </div>
                    </section>
                </LazyLoad>
                <LazyLoad height={600} once offset={400}>
                    <section className="wrapper style2">
                        <div className="inner">
                          <section>
                          <header className="major align-center ">
                          <div className="likeh3">Our Amazing Experiences</div>
                          </header>
                            <div>
                              <div className="row events-occasions">
                                <div className="col-12 col-md-4 align-center">
                                    <a href="/freeroamvrcombopackage"><img className="lazy" src={require("../images/freeroam-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Free-Roam Combo
                                    </div>
                                    <div className="likeh4"><span className="mirage-teal">2-5 players</span> </div>
                                    <p> Our most immersive package, where your body is the controller! Explore with your own legs, interact with your own hands, and coordinate as a team to stay alive.
                                    </p>

                                </div>
                                <div className="col-12 col-md-4 align-center">
                                    <a href="/stationaryvrpackage"><img className="lazy" src={require("../images/vrbooth-bg.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Stationary VR
                                    </div>
                                    <div className="likeh4"><span className="mirage-teal">2-10 players</span></div>
                                    <p>
                                        Explore over 50 worlds in your private VR station. Whether you like shooting zombies, solving puzzles, or grooving to the rhythm,
                                        we have something for everyone!
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 align-center">  
                                    <a><img className="lazy" src={require("../images/magicdoor-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Best of Both Worlds
                                    </div>
                                    <div className="likeh4"><span className="mirage-teal">2-10 players</span></div>
                                    <p>
                                        Completely immerse yourselves in free-roaming virtual reality
                                        and explore over 50 worlds in private VR stations. It really is the best of both worlds.
                                    </p>
                                    </div>
                               </div>
                               <div className="row events-occasions">
                                <div className="col-12 col-md-4 align-center">
                                    <a href="/defendanddefusepackage"><img className="lazy" src={require("../images/defend&defuse-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Defend And Defuse
                                    </div>
                                    <div className="likeh4"><span className="mirage-teal">6-10 players</span></div>                                    
                                    <p>
                                        Get ready for the ultimate test of teamwork. Defend a magical forest from the forces of evil,
                                        and work together to defuse a bomb before it blows you to smithereens!
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 align-center">
                                    <a href="/partypackage"><img className="lazy" src={require("../images/vrpictionary-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Party Package
                                    </div>  
                                    <div className="likeh4"><span className="mirage-teal">11-20 players</span></div>
                                    <p>Our virtual reality experiences, tailored for parties. Explore deep forests, defuse bombs, and 
                                       play a game of pictionary in virtual reality.
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 align-center">  
                                    <a href="/deluxepartypackage"><img className="lazy" src={require("../images/landingportal-wide.jpg")}/></a><br/><br/>
                                    <div className="likeh4">Deluxe Party Package
                                    </div>  
                                    <div className="likeh4"><span className="mirage-teal">11-40 players</span></div>
                                    <p>The ultimate party package, with access to all of our experiences. Our free-roaming VR arena,
                                       party stations, and private booths are fully yours to enjoy.
                                    </p>
                                </div>
                               </div>
                                <div className="col-12 col-md-12 align-center">
                                    <a href="/experiences" id="gamepage-book" className="button small special" alt=""> See Experiences</a>
                                </div>
                            </div>
                        </section>
                        </div>
                    </section>
                </LazyLoad>
                <LazyLoad height={600} once offset={400}>       
                    <Collapsible trigger={
                        <section id="one" className="collapsible wrapper style3 special">
                            <h3>Augmented Reality Team Building in Burlington, ON</h3>
                            <i className="downarrow white"/>
                        </section>}>           

                        <section id="two" className="wrapper alt style3">
                            <section className="spotlight">
                                <div className="image"><img src={require("../images/teamwork.jpg")}/></div><div className="content">
                                    <p>
                                    A good team building event is not only fun, but develops a strong bond between team members, 
                                    lasting far into the future. Our virtual reality experiences place team members
                                    into the shoes of powerful heroes, where they must work together to save the world. There is no acting
                                    or role-playing here - participants will be mesmerized as their wildest imaginations come to life around 
                                    them. 
                                    </p>
                                </div>
                            </section>
                            <section className="spotlight">
                                <div className="image"><img src={require("../images/teambuilding-bg2.jpg")}/></div><div className="content">
                                    <p>
                                    Through virtual reality teamwork, coordination, and strategization, participants 
                                    experience an adventure that is both engaging and challenging. With every challenge that team members 
                                    overcome, a stronger connection & chemistry is forged between them. Mirage VR specializes in this
                                    type of team building experience.
                                    </p>
                                </div>
                            </section>
                        </section>

                        <section className="wrapper style3 special no-border" style={{padding: '2em 2em 2em 2em'}}>
                            <div className="inner">
                                <a className="button small special" href="/faq">Team Building</a>
                            </div>
                        </section>
                    </Collapsible>
                </LazyLoad>
                <LazyLoad height={600} once offset={400}>
                    <Collapsible trigger={
                        <section id="one" className="collapsible wrapper style2 special">
                        <h3>Virtual Reality Corporate Events in Burlington, ON</h3>
                            <i className="downarrow white"/>
                        </section>}>    

                        <section id="two" className="wrapper alt style2">
                            <section className="spotlight">
                                <div className="image"><img src={require("../images/unique.jpg")}/></div><div className="content">
                                    <p>
                                    Planning a corporate party? We'd love to host you! Every event has its own unique requirements, and we're here
                                    to accommodate you! You can come to our facility, or we can come to you. Our state-of-the-art technology and experiences 
                                    will enhance your corporate brand to your guests. And of course, we provide world class virtual reality to entertain your guests,
                                    no matter who they are.
                                    </p>
                                </div>
                            </section>
                            <section className="spotlight">
                                <div className="image"><img src={require("../images/teambuildingvr.jpg")}/></div><div className="content">
                                    <p>
                                    Our friendly and knowledgeable staff will be on site to guide you through every 
                                    step of the experience. We offer a wide variety of experiences, from fantasy role-playing to zombie-shooting madness. 
                                    We have done virtual reality corporate events with companies like Google & Shopify. Will yours be next?
                                    </p>
                                </div>
                            </section>
                        </section>

                        <section className="wrapper style2 special no-border" style={{padding: '2em 2em 2em 2em'}}>
                            <div className="inner">
                                <a className="button small special" href="/faq">Corporate Events</a>
                            </div>
                        </section>
                    </Collapsible>
                </LazyLoad>

                <LazyLoad height={600} once offset={400}>
                    <Collapsible trigger={
                        <section id="one" className="collapsible wrapper style3 special">
                        <h3>Free-Roam VR Birthday Parties in Burlington, ON</h3>
                            <i className="downarrow white"/>
                        </section>}>     

                        <section id="two" className="wrapper alt style3">
                            <section className="spotlight">
                                <div className="image"><img src={require("../images/birthdayparties.jpg")}/></div><div className="content">
                                <div className="likeh4">The Coolest Birthday Party</div>
                                <br/>
                                <p>
                                If you want to have the coolest birthday party on Earth, then look no further. Experience an epic adventure as you and your close ones step into a world beyond all of your wildest dreams. This is a unique and innovative experience where you and your friends can dance, battle, and hug each other. Our virtual reality birthday parties are always filled with wonder, laughter, and a whole lot of funny Instagram posts. So whether it's your 10th or your 50th birthday, our experiences are an absolute blast! We also offer a complimentary party room, free of charge.
                                </p>
                                </div>
                            </section>
                            <section className="spotlight">
                                <div className="image"><img src={require("../images/kidsbirthdayparty.jpg")}/></div><div className="content">
                                <h4>Birthday Parties For Kids</h4>
                                <br/>
                                <p>Our experiences are made to be suitable for all ages, and our formats tailored for birthday parties. Kids 
                                adore our experiences because they are intuitive and require no learning.</p>
                                </div>
                            </section>
                            <section className="spotlight">
                                <div className="image"><img src={require("../images/adultbirthdayparty.jpg")}/></div><div className="content">
                                <h4>Birthday Parties For Adults</h4>
                                <br/>
                                <p>
                                Throw a virtual reality birthday party and escape the boredom of real life. Immerse yourself 
                                and your loved ones into a world filled with the best type of fun and wonder - the feeling that anything is possible. 
                                We specialize in creating these moments for you, that last far into the future.
                                </p>
                                </div>
                            </section>
                        </section>

                        <section className="wrapper style3 special no-border" style={{padding: '2em 2em 2em 2em'}}>
                            <div className="inner">
                                <a className="button small special" href="/faq">Birthdays</a>
                            </div>
                        </section>
                    </Collapsible>
                </LazyLoad>

                <LazyLoad height={600} once offset={400}>
                    <section id="one" className="wrapper style2 special">
                        <div class="inner">
                            <header class="major">
                                <h2>Schedule Your VR Event In Burlington Today!</h2>
                            </header>
                            <br></br>
                            <p>
                                <a id="gamepage-book" className="button special" href="/experiences?#booknow" alt="" >Book Now</a>
                            </p>
                            {(this.state.mobile) &&
                                <iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.469217582997!2d-80.50122776111864!3d43.45480717770333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882bf48afa6aaaab%3A0xf9ca17480df38305!2sMirage+VR!5e0!3m2!1sen!2sca!4v1505546250254"} width='100%' height='450' frameBorder='0'></iframe>
                            }
                            {!(this.state.mobile) &&
                                <iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.469217582997!2d-80.50122776111864!3d43.45480717770333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882bf48afa6aaaab%3A0xf9ca17480df38305!2sMirage+VR!5e0!3m2!1sen!2sca!4v1505546250254"} width='600' height='450' frameBorder='0'></iframe>
                            }
                        </div>
                    </section>

                    <section className="faq-inline wrapper style3 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                        <div className="inner">
                            <header className="major less-heavy-text">
                                <h2>Visit Our Other Locations</h2> 
                            </header>
                            <br></br>
                            <a href="/kitchener"><h3 id="gamepage-book" className="button" alt="">Kitchener, ON</h3></a>
                            <a href="/mississauga"><h3 id="gamepage-book" className="button" alt="">Mississauga, ON</h3></a>
                        </div>
                    </section>
                </LazyLoad>

                <LazyLoad height={200} once offset={600}>
                    <Testimonials />                
                </LazyLoad>

                <LazyLoad height={600} once offset={400}>
                    <section className="pricing wrapper style2">
                        <div className="inner">
                            <div id="message" className="inner fit vertical">
                                <div className="likeh2">Join the Insider Club</div>
                                <br/>
                                <p>
                                    Receive exclusive promotions and offers, such as days when we offer free VR.
                                </p>
                                <EmailSubscribeForm
                                />
                            </div>
                        </div>
                    </section>
                </LazyLoad>

                <BookModal 
                showModal={this.state.showBookModal}
                exitModal={this.exitBookModal}
                />
            </div>
        )
    }
};

// const img = {
//     backgroundImage: 'url("https://res.cloudinary.com/teepublic/image/private/s--E-fxVegu--/t_Preview/b_rgb:ffffff,c_lpad,f_jpg,h_630,q_90,w_1200/v1515227040/production/designs/2258222_3.jpg")', /*Just for clarrification case*/
//     textAlign: 'center !important',
//     margin: '0 auto !important'
// }
// const imgDiv = {
//     backgroundImage: 'url("https://s33.postimg.cc/b1rse32sv/dragonfire.jpg")',
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'auto',
//     display: 'inline !important',
//     //margin: 'auto !important',
//     position: 'fixed !important',
// }