import React from 'react';
import Modal from 'react-modal';


export default class NumberChooser extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showLocationChoices: true,
            showComingSoonModal: false,
            modalClass: 'modal'
        };
    }


    switchModalComingSoon(){
        this.setState(()=> ({showComingSoonModal: true}));
        this.setState(()=> ({showLocationChoices: false}));
    }

    render(){
        return(

            <Modal
                isOpen={this.props.showModal}
                onRequestClose= {this.props.exitModal}
                ariaHideApp={false}
                closeTimeoutMS={200}
                className={this.state.modalClass}
                overlayClassName="overlay"
                >

                {this.state.showLocationChoices &&
                    <div id="book" className="iziModal-wrapper" style={{padding: '0 !important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>Choose Your Location</h2>
                            <div className="iziModal-content" style={{padding: '1em 0'}}>
                                <a style={{padding: '0 1.5em'}} className="button special" href="tel:12268981261">Kitchener</a>
                                <div className='divider'/>
                                <a style={{padding: '0 1.5em'}} className="button special" href="tel:12893261964">Mississauga</a>
                            </div>
                        </div>
                    </div>
                }

                {this.state.showComingSoonModal &&
                    <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>Coming Soon!</h2>
                            <br/>
                            <p>Subscribe to be notified when pre-sale tickets are available!</p>
                        </div>
                    </div>
                }
            </Modal>
        );
    }
}