import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import butter from '../server/butter-client'

const height = String($(window).height()*(1)) + "px";
const ninetyheight = String($(window).height()*(0.9)) + "px";
const imgHeight = String($(window).height()*(1)) + "px";
const imgWidth = String($(window).width()*(2.5)) +"px"

const imgDiv = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: height,
    zIndex: '-100',
    backgroundColor: 'black'

}
const overlay = {
    background: 'rgba(0, 0, 0, 0.25)'
    
}

export default class BlogPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: {}
        }
    }

    async componentDidMount () {
      const { match } = this.props
      const resp = await butter.post.retrieve(match.params.post)
      this.setState(resp.data)
      console.log(this.state.data);
    }

    render () {
    const post = this.state.data

      return (
        <div>
          <Helmet>
            <title>{post.seo_title}</title>
            <meta name='description' content={post.meta_description} />
            <meta name='og:image' content={post.featured_image} />
          </Helmet>
          <article id="main">
                <section id="banner" className="heavy-text" position={{position:'relative'}} style={{height: height}}>
                    <div className="landing2-bg" style={imgDiv}>  
                      <div style={overlay}>
                          <img id="landingimage" className="lazy" src={post.featured_image} />
                      </div>                    
                    </div>
                    <div id="bannertext">
                        <div className="bannermidtext">
                        <header className="heavy-text">
                            <h1>{post.title}</h1>
                        </header>
                        </div>
                    </div>
                </section>
                <section class="wrapper style5">
                  <div class="inner">
                    <div dangerouslySetInnerHTML={{ __html: post.body }} />
                  </div>
                </section>
          </article>
        </div>
      )
    }
}