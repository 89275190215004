import React from 'react';
import Modal from 'react-modal';


const ThankYouModal = (props) => (
        <Modal
            isOpen={props.showModal}
            onRequestClose= {props.exitModal}
            ariaHideApp={false}
            closeTimeoutMS={200}
            className="modal"
            >
            <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                <div className="inner fit vertical align-center">
                   <h2>Thank you!</h2>
                   <p>Your message has been recieved and we will get back to you shortly</p>
                </div>
            </div>
        </Modal>


);

export default ThankYouModal;