import React from 'react';
import Modal from 'react-modal';


export default class LocationChooser extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            location: '',
            locationURL: '',
            showLocationChoices: true && (!props.showCOVID19),
            showComingSoonModal: false,
            showCOVID19: props.showCOVID19,
            showMap: props.showMap,
            mapWidth: props.mobile ? String($(window).width) : '600',
            modalClass: 'modal'
        };

        this.setLocation = this.setLocation.bind(this);
    }

    setLocation (location) {
        console.log(location);
        this.state.location = location;
        //console.log("chose " + this.state.location);
        if (location == "kitchener") {
            this.state.locationURL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.469217582997!2d-80.50122776111864!3d43.45480717770333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882bf48afa6aaaab%3A0xf9ca17480df38305!2sMirage+VR!5e0!3m2!1sen!2sca!4v1505546250254";
            this.setState(()=> ({showLocationChoices: false}));
            this.state.modalClass = 'map_modal';
        } else if (location == "mississauga") {
            this.state.locationURL = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11574.044001682283!2d-79.6487822!3d43.5125357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x96e425c91f36140f!2sMirage+VR!5e0!3m2!1sen!2sca!4v1553843491396!5m2!1sen!2sca";
            this.setState(()=> ({showLocationChoices: false}));
            this.state.modalClass = 'map_modal';
        } else if (location == "burlington") {
            this.switchModalComingSoon();
        }
    }

    switchModalComingSoon(){
        this.setState(()=> ({showMap: false}));
        this.setState(()=> ({showComingSoonModal: true}));
        this.setState(()=> ({showLocationChoices: false}));
    }

    render(){
        return(

            <Modal
                isOpen={this.props.showModal}
                onRequestClose= {this.props.exitModal}
                ariaHideApp={false}
                closeTimeoutMS={200}
                className={this.state.modalClass}
                overlayClassName="overlay"
                >

                {this.state.showLocationChoices &&
                    <div id="book" className="iziModal-wrapper" style={{padding: '0 !important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>Choose Your Location</h2>
                            <div className="iziModal-content" style={{padding: '1em 0'}}>
                                <a style={{padding: '0 1.5em'}} className="button special" onClick={()=> this.setLocation("kitchener")}>Kitchener</a>
                                <div className='divider'/>
                                <a style={{padding: '0 1.5em'}} className="button special" onClick={()=> this.setLocation("mississauga")}>Mississauga</a>
                                <div className='divider'/>
                                <a id="reserve" style={{padding: '0 1.5em'}} className="button special" onClick={()=> this.setLocation("burlington")}>Burlington</a>
                            </div>
                        </div>
                    </div>
                }

                {(this.state.showMap && !this.state.showLocationChoices) && 
                    <iframe src={this.state.locationURL} width={this.state.mapWidth} height='450' frameBorder='0'></iframe>
                }

                {this.state.showComingSoonModal &&
                    <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>Coming Soon!</h2>
                            <br/>
                            <p>Subscribe to be notified when pre-sale tickets are available!</p>
                        </div>
                    </div>
                }

                {this.state.showCOVID19 &&
                    <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>About COVID-19</h2>
                            <br/>
                            <p>To ensure the health and safety of both our staff and customers, we are putting in place the following measures:</p>
                            <p>1. All VR headsets will be cleaned and disinfected after <b class="underline">each</b> use.<br></br>
                            2. All customers will be asked to use hand sanitizer before and after a session.<br></br>
                            3. We will be spreading out appointments to minimize back-to-back bookings.<br></br>
                            4. Anyone displaying flu symptoms will be asked to leave immediately and reschedule.<br></br>
                            4. We <b class="underline">strongly recommend</b> you stay home and visit Mirage VR at a later time!</p>
                            <br></br>
                            <a style={{padding: '0 1.5em'}} className="button special" onClick={()=> this.props.exitModal()}>Close</a>
                        </div>
                    </div>
                }
            </Modal>
        );
    }
}