import React from 'react';
import Modal from 'react-modal';
import ContactForm from '../../pages/ContactForm';
import Cookies from 'universal-cookie';

const queryString = require('query-string');

var magicButtonClickCount = 0;

//handles switch what the modal displays at each step, the form content is handled by ModalForm
class BookModal extends React.Component {
    constructor(props){

        super(props);

        const cookies = new Cookies();

        this.state = {
            location: "",
            //Modals
            appointmentType: props.appointmentType,
            showLocationChoices: false,
            showPlayerChoices: true,
            showGroupReservationModal: false,
            showThankYouModal: false,
            showErrorModal: false,
            showComingSoonModal: false,
            setPreferredLocation: false,
            showTemporaryClosed: false,
            numPlayers: 1
        }

        /*
        if (cookies.get('location')) {
            this.state.location = cookies.get('location');
            this.state.showLocationChoices = false;
        } 
*/
        //Modals
        this.exitModal = this.exitModal.bind(this);
        this.switchModalGroupReservation = this.switchModalGroupReservation.bind(this);
        this.switchModalThankYou = this.switchModalThankYou.bind(this);
        this.switchModalError = this.switchModalError.bind(this);
        this.switchModalKitchener = this.switchModalKitchener.bind(this);
        this.switchModalMississauga = this.switchModalMississauga.bind(this);
        this.switchModalBurlington = this.switchModalBurlington.bind(this);
        this.toggleLocationPreference = this.toggleLocationPreference.bind(this);
        this.magicButtonClick = this.magicButtonClick.bind(this);
        this.state.appointmentType = this.props.appointmentType;
        this.setUserCount = this.setUserCount.bind(this);
    }

    magicButtonClick() {
        magicButtonClickCount ++;
        if (magicButtonClickCount > 5) {
            if (this.props.isGroupReservation == false){
                document.location.href = this.getbookurl(5);
            } else {
                this.switchModalGroupReservation();
            }
        }
    }

    exitModal(){
        this.props.exitModal()
        setTimeout(() => {
            this.setState(()=> ({showGroupReservationModal: false}));
            this.setState(()=> ({showThankYouModal: false}));
            this.setState(()=> ({showPlayerChoices: true}));
            this.setState(()=> ({showLocationChoices: false}));
            this.setState(()=> ({showComingSoonModal: false}));
            this.setState(()=> ({showTemporaryClosed: false}));
            this.setState(()=> ({showErrorModal: false}));
        }, 1000);
    }

    switchModalKitchener(){

        this.state.location = "kitchener";

        if (this.state.setPreferredLocation) {
            const cookies = new Cookies();
            cookies.set('location', this.state.location, { path: '/' });
        }
        
        document.location.href = this.getbookurl(this.state.numPlayers);
    }

    switchModalMississauga(){
        console.log(this.props.isGroupReservation);

        this.state.location = "mississauga";

        if (this.state.setPreferredLocation) {
            const cookies = new Cookies();
            cookies.set('location', this.state.location, { path: '/' });
        }

        document.location.href = this.getbookurl(this.state.numPlayers);
    }

    switchModalBurlington(){
        this.state.location = "burlington";

        this.switchModalComingSoon();
    }

    getbookurl(numppl) {
        //var pacakgeName = this.state.location + "-" + this.props.appointmentType + "-weekday";
        return "/book" + '?appointmentType=' + this.state.appointmentType + '&location=' + this.state.location + '&quantity=' + numppl;
    }


    switchModalComingSoon(){
        this.setState(()=> ({showComingSoonModal: true}));
        this.setState(()=> ({showLocationChoices: false}));
    }

    switchModalTempClosure() {
        this.setState(()=> ({showTemporaryClosed: true}));
        this.setState(()=> ({showLocationChoices: false}));
    }

    switchModalGroupReservation(){
        this.setState(()=> ({showComingSoonModal: false}));
        this.setState(()=> ({showGroupReservationModal: true}));
        this.setState(()=> ({showLocationChoices: false}));
    }

    switchModalThankYou(){
        this.setState(()=> ({showThankYouModal: true}));
        this.setState(()=> ({showGroupReservationModal: false}));
    }
    
    switchModalError(){
        this.setState(()=> ({showErrorModal: true}));
        this.setState(()=> ({showGroupReservationModal: false}));
    }

    toggleLocationPreference() {
        this.setState(()=> ({setPreferredLocation: ! this.state.setPreferredLocation}));
    }

    setUserCount(event){
        var userCount = event.target.attributes.getNamedItem('data-group').value;
        this.setState(()=> ({numPlayers: userCount}));
        this.setState(()=> ({showPlayerChoices: false}));
        this.setState(()=> ({showLocationChoices: true}));

        this.checkPackageIds(userCount);
    }

    checkPackageIds(userCount){
        if (this.state.appointmentType == "bestofbothworlds"){
            if (userCount > 5)
                this.setState(()=> ({appointmentType: "bestofbothworlds-med"}));
            else
                this.setState(()=> ({appointmentType: "bestofbothworlds-small"}));
        }
    }

    
    render(){

        let tag;

        if (this.props.category == "Birthday") {
            tag = "birthday";
        } else if (this.props.category == "TeamBuilding") {
            tag = "teambuilding";
        } else {
            tag = "partypackage";
        }

        return(
        <div>
            <Modal
                isOpen={this.props.showModal}
                onRequestClose= {this.exitModal}
                ariaHideApp={false}
                closeTimeoutMS={200}
                className="modal"
                overlayClassName="overlay"
                >
                {this.state.showLocationChoices &&
                <div id="book" className="iziModal-wrapper" style={{padding: '0 !important'}}>
                    <div className="inner fit vertical align-center">
                        <h2>Choose Your Location</h2>
                        <div className="iziModal-content" style={{padding: '1em 0'}}>
                            <a style={{padding: '0 1.5em'}} className="button special" onClick={this.switchModalKitchener}>Kitchener</a>
                            <div className='divider'/>
                            <a style={{padding: '0 1.5em'}} className="button special" onClick={this.switchModalMississauga}>Mississauga</a>
                            <div className='divider hide'/>
                            <a id="reserve" style={{padding: '0 1.5em'}} className="button special hide" onClick={this.switchModalBurlington}>Burlington</a>
                            <div className='hide' onClick={this.toggleLocationPreference}>
                                <input type="checkbox" name="prefer-location" checked={this.state.setPreferredLocation}/>                            
                                <label >Remember as my preferred location</label>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.showPlayerChoices &&
                <div id="book" className="iziModal-wrapper" style={{padding: '0 !important'}}>
                        {(this.state.appointmentType == "bestofbothworlds") &&
                            <div className="inner fit vertical align-center">
                                <h2>Group Size</h2>
                                <div>* Groups of 2 and 3 people may share session with another group to make a team of 5.</div>                                
                                <div className="iziModal-content" style={{padding: '1em 0'}}>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="2">2</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="3">3</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="4">4</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="5">5</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="6">6</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="7">7</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="8">8</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="9">9</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="10">10</a>
                                    <div className='divider'/>
                                </div>
                            </div>
                        }
                        {(this.state.appointmentType == "freeroamcombo") &&
                            <div className="inner fit vertical align-center">
                                <h2>Group Size</h2>
                                <div>* Groups of 2 and 3 people may share session with another group to make a team of 5.</div>                                
                                <div className="iziModal-content" style={{padding: '1em 0'}}>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="2">2</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="3">3</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="4">4</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="5">5</a>
                                    <div className='divider'/>
                                </div>
                            </div>
                        }
                        {(this.state.appointmentType == "decomposed") &&
                            <div className="inner fit vertical align-center">
                                <h2>Group Size</h2>
                                <div>* It's okay to use an <b>estimate</b>, can be adjusted later based on availability</div>                                
                                <div className="iziModal-content" style={{padding: '1em 0'}}>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="2">2</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="3">3</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="4">4</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="5">5</a>
                                    <div className='divider'/>
                                </div>
                            </div>
                        }
                        {(this.state.appointmentType == "stationaryvr") &&
                            <div className="inner fit vertical align-center">
                                <h2>Group Size</h2>
                                <div>* It's okay to use an <b>estimate</b> number of players, can be adjusted later based on availability</div>                                
                                <div className="iziModal-content" style={{padding: '1em 0'}}>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="1">1</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="2">2</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="3">3</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="4">4</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="5">5</a>
                                    <div className='divider'/>
                                </div>
                            </div>
                        }
                        {(this.state.appointmentType == "defendanddefuse") &&
                            <div className="inner fit vertical align-center">
                                <h2>Group Size</h2>
                                <div>* It's okay to use an <b>estimate</b>, can be adjusted later based on availability</div>                                
                                <div className="iziModal-content" style={{padding: '1em 0'}}>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="6">6</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="7">7</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="8">8</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="9">9</a>
                                    <div className='divider'/>
                                    <a style={{padding: '0 1.5em'}} className="button special" onClick={this.setUserCount} data-group="10">10</a>
                                    <div className='divider'/>
                                </div>
                            </div>
                        }
                        {(this.state.appointmentType == "party" || this.state.appointmentType == "deluxeparty") &&
                            <div id="reservation" className="iziModal-wrapper">
                                <div id="message" className="inner fit vertical">
                                    <h2>Group Reservation</h2><br/>
                                    <ContactForm
                                        tag= {tag}
                                        switchModalThankYou = {this.switchModalThankYou}
                                        switchModalError = {this.switchModalError}
                                        exitModal = {this.props.exitModal}
                                    />
                                </div>
                            </div>
                        }
                </div>
                }

                {this.state.showThankYouModal &&
                    <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>Thank you!</h2>
                            <p>Your message has been recieved and we will get back to you shortly</p>
                        </div>
                    </div>
                }
                {this.state.showComingSoonModal &&
                    <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>Coming Soon!</h2>
                            <br/>
                            <p onClick={this.magicButtonClick}>Subscribe to be notified when pre-sale tickets are available!</p>
                        </div>
                    </div>
                }
                {this.state.showTemporaryClosed &&
                    <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>Store Temporarily Closed due to COVID-19</h2>
                            <br/>
                            <p onClick={this.magicButtonClick}>Thank you for your interest, we are remaining closed for just a bit longer!</p>
                        </div>
                    </div>
                }
                {this.state.showErrorModal &&
                    <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                        <div className="inner fit vertical align-center">
                            <h2>Ooops!</h2>
                            <p>Looks like something went wrong on our end, please send us an email or try again later.</p>
                        </div>
                    </div>
                }
            </Modal>
            
        </div>
        )
    }
};

export default BookModal;