//Importing Libraries
import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import { Redirect } from 'react-router';

//Importing Pages
import HomePage from '../pages/HomePage';
import SherwoodForestPage from '../pages/SherwoodForestPage';
import DragonFirePage from '../pages/DragonFirePage';
import BirthdayPage from '../pages/BirthdayPage';
import TeamBuildingPage from '../pages/TeamBuildingPage';
import EscapePage from '../pages/EscapePage';
import FAQPage from '../pages/FAQPage';
import AboutUsPage from '../pages/AboutUsPage'
import ContactPage from '../pages/ContactPage';
import FranchisePage from '../pages/FranchisePage';
import GiftPage from '../pages/GiftPage';
import BookPage from '../pages/BookPage';
import NotFoundPage from '../pages/NotFoundPage';
import VirtualRealityPage from '../pages/VirtualRealityPage';
import TermsConditionsPage from '../pages/TermsConditionsPage';
import ExperiencesPage from '../pages/ExperiencesPage';
import EventsPage from '../pages/EventsPage';
import KitchenerPage from '../pages/KitchenerPage';
import BurlingtonPage from '../pages/BurlingtonPage';
import MississaugaPage from '../pages/MississaugaPage';
import FreeRoamVRComboPackagePage from '../pages/FreeRoamVRComboPackagePage';
import DecomposedPackagePage from '../pages/DecomposedPackagePage';
import StationaryVRPackagePage from '../pages/StationaryVRPackagePage';
import BestOfBothWorldsPackagePage from '../pages/BestOfBothWorldsPackagePage';
import BestOfBothWorldsPackageMedPage from '../pages/BestOfBothWorldsPackageMedPage';
import DefendAndDefusePackagePage from '../pages/DefendAndDefusePackagePage';
import PartyPackagePage from '../pages/PartyPackagePage';
import DeluxePartyPackagePage from '../pages/DeluxePartyPackagePage';
import BlogPage from '../pages/BlogPage';
import BlogPostPage from '../pages/BlogPostPage';
import FeedbackPage from '../pages/FeedbackPage';
import FeedbackV2Page from '../pages/FeedbackV2Page';


//Importing Components
import Header from '../components/Header';
import Footer from '../components/Footer';
import BottomNav from '../components/BottomNav';


export default class AppRouter extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            mobile: ($(window).width() <= 763)? true : false
        }
    }
    componentDidMount(){
        /*----------------------------------- GENERAL --------------------------*/

        $.prioritize = function($elements, condition) {

            var key = '__prioritize';
    
            // Expand $elements if it's not already a jQuery object.
                if (typeof $elements != 'jQuery')
                    $elements = $($elements);
    
            // Step through elements.
                $elements.each(function() {
    
                    var	$e = $(this), $p,
                        $parent = $e.parent();
    
                    // No parent? Bail.
                        if ($parent.length == 0)
                            return;
    
                    // Not moved? Move it.
                        if (!$e.data(key)) {
    
                            // Condition is false? Bail.
                                if (!condition)
                                    return;
    
                            // Get placeholder (which will serve as our point of reference for when this element needs to move back).
                                $p = $e.prev();
    
                                // Couldn't find anything? Means this element's already at the top, so bail.
                                    if ($p.length == 0)
                                        return;
    
                            // Move element to top of parent.
                                $e.prependTo($parent);
    
                            // Mark element as moved.
                                $e.data(key, $p);
    
                        }
    
                    // Moved already?
                        else {
    
                            // Condition is true? Bail.
                                if (condition)
                                    return;
    
                            $p = $e.data(key);
    
                            // Move element back to its original location (using our placeholder).
                                $e.insertAfter($p);
    
                            // Unmark element as moved.
                                $e.removeData(key);
    
                        }
    
                });
    
        };

        $.fn.placeholder = function() {

            // Browser natively supports placeholders? Bail.
                if (typeof (document.createElement('input')).placeholder != 'undefined')
                    return $(this);
    
            // No elements?
                if (this.length == 0)
                    return $this;
    
            // Multiple elements?
                if (this.length > 1) {
    
                    for (var i=0; i < this.length; i++)
                        $(this[i]).placeholder();
    
                    return $this;
    
                }
    
            // Vars.
                var $this = $(this);
    
            // Text, TextArea.
                $this.find('input[type=text],textarea')
                    .each(function() {
    
                        var i = $(this);
    
                        if (i.val() == ''
                        ||  i.val() == i.attr('placeholder'))
                            i
                                .addClass('polyfill-placeholder')
                                .val(i.attr('placeholder'));
    
                    })
                    .on('blur', function() {
    
                        var i = $(this);
    
                        if (i.attr('name').match(/-polyfill-field$/))
                            return;
    
                        if (i.val() == '')
                            i
                                .addClass('polyfill-placeholder')
                                .val(i.attr('placeholder'));
    
                    })
                    .on('focus', function() {
    
                        var i = $(this);
    
                        if (i.attr('name').match(/-polyfill-field$/))
                            return;
    
                        if (i.val() == i.attr('placeholder'))
                            i
                                .removeClass('polyfill-placeholder')
                                .val('');
    
                    });
    
            // Password.
                $this.find('input[type=password]')
                    .each(function() {
    
                        var i = $(this);
                        var x = $(
                                    $('<div>')
                                        .append(i.clone())
                                        .remove()
                                        .html()
                                        .replace(/type="password"/i, 'type="text"')
                                        .replace(/type=password/i, 'type=text')
                        );
    
                        if (i.attr('id') != '')
                            x.attr('id', i.attr('id') + '-polyfill-field');
    
                        if (i.attr('name') != '')
                            x.attr('name', i.attr('name') + '-polyfill-field');
    
                        x.addClass('polyfill-placeholder')
                            .val(x.attr('placeholder')).insertAfter(i);
    
                        if (i.val() == '')
                            i.hide();
                        else
                            x.hide();
    
                        i
                            .on('blur', function(event) {
    
                                event.preventDefault();
    
                                var x = i.parent().find('input[name=' + i.attr('name') + '-polyfill-field]');
    
                                if (i.val() == '') {
    
                                    i.hide();
                                    x.show();
    
                                }
    
                            });
    
                        x
                            .on('focus', function(event) {
    
                                event.preventDefault();
    
                                var i = x.parent().find('input[name=' + x.attr('name').replace('-polyfill-field', '') + ']');
    
                                x.hide();
    
                                i
                                    .show()
                                    .focus();
    
                            })
                            .on('keypress', function(event) {
    
                                event.preventDefault();
                                x.val('');
    
                            });
    
                    });
    
            // Events.
                $this
                    .on('submit', function() {
    
                        $this.find('input[type=text],input[type=password],textarea')
                            .each(function(event) {
    
                                var i = $(this);
    
                                if (i.attr('name').match(/-polyfill-field$/))
                                    i.attr('name', '');
    
                                if (i.val() == i.attr('placeholder')) {
    
                                    i.removeClass('polyfill-placeholder');
                                    i.val('');
    
                                }
    
                            });
    
                    })
                    .on('reset', function(event) {
    
                        event.preventDefault();
    
                        $this.find('select')
                            .val($('option:first').val());
    
                        $this.find('input,textarea')
                            .each(function() {
    
                                var i = $(this),
                                    x;
    
                                i.removeClass('polyfill-placeholder');
    
                                switch (this.type) {
    
                                    case 'submit':
                                    case 'reset':
                                        break;
    
                                    case 'password':
                                        i.val(i.attr('defaultValue'));
    
                                        x = i.parent().find('input[name=' + i.attr('name') + '-polyfill-field]');
    
                                        if (i.val() == '') {
                                            i.hide();
                                            x.show();
                                        }
                                        else {
                                            i.show();
                                            x.hide();
                                        }
    
                                        break;
    
                                    case 'checkbox':
                                    case 'radio':
                                        i.attr('checked', i.attr('defaultValue'));
                                        break;
    
                                    case 'text':
                                    case 'textarea':
                                        i.val(i.attr('defaultValue'));
    
                                        if (i.val() == '') {
                                            i.addClass('polyfill-placeholder');
                                            i.val(i.attr('placeholder'));
                                        }
    
                                        break;
    
                                    default:
                                        i.val(i.attr('defaultValue'));
                                        break;
    
                                }
                            });
    
                    });
    
            return $this;
    
        };

        skel.breakpoints({
			xlarge:	'(max-width: 1680px)',
			large:	'(max-width: 1280px)',
			medium:	'(max-width: 980px)',
			small:	'(max-width: 736px)',
			xsmall:	'(max-width: 480px)'
        });

        $('.lazy').Lazy(); 

        //Skel vars
        var $window = $(window),
            $body = $('body'),
            $wrapper = $('#page-wrapper'),
            $banner = $('#banner'),
            $header = $('#header');
        
        

        // Disable animations/transitions until the page has loaded.
        //$body.addClass('is-loading');
        $(document).ready(function() {
            window.setTimeout(function() {
            $body.removeClass('is-loading');
            }, 100);
        });
  
          // Mobile?
        if (skel.vars.mobile) {
            $body.addClass('is-mobile');
            $header.addClass('is-mobile');
        }
        else
            skel.on('-small !small', function() {
                $body.removeClass('is-mobile');
                $header.removeClass('is-mobile');
            }).on('+small', function() {
                $body.addClass('is-mobile');
                $header.addClass('is-mobile');
            });
        
 		// Fix: Placeholder polyfill.
        $('form').placeholder();

		// Prioritize "important" elements on medium.
		skel.on('+medium -medium', function() {
			$.prioritize(
				'.important\\28 medium\\29',
				skel.breakpoint('medium').active
			);
        });

        // Scrolly.
		$('.scrolly').scrolly({
			speed: 1500,
			offset: $header.outerHeight()
        });

        window.stringHash = function(str) {
            var hash = 0;
            if (str.length == 0) {
                return hash;
            }
            for (var i = 0; i < str.length; i++) {
                var char = str.charCodeAt(i);
                hash = ((hash<<5)-hash)+char;
                hash = hash & hash; // Convert to 32bit integer
            }
            return hash;
        }

		// Header.
        if (skel.vars.IEVersion < 9)
            $header.removeClass('alt');

            if ($banner.length > 0
            &&	$header.hasClass('alt')) {

            $window.on('resize', function() { $window.trigger('scroll'); });

            $banner.scrollex({
                bottom:		$header.outerHeight() + 1,
                terminate:	function() { $header.removeClass('alt'); },
                enter:		function() { $header.addClass('alt'); },
                leave:		function() { $header.removeClass('alt'); }
            });
        }
    }

    render(){
        return(
            <div id="page-wrapper">
                {/* This is in a router so have access to path*/}
                <BrowserRouter>
                    <Switch>
                        <Route component={Header} />
                    </Switch>
                </BrowserRouter>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" component={HomePage} exact={true}/>
                        <Route path="/landing2" component={HomePage} exact={true}/>
                        <Route path="/landing3" component={HomePage} exact={true}/>
                        <Route path="/experiences" component={ExperiencesPage} exact={true} />
                        <Route path="/sherwood" component={SherwoodForestPage} exact={true}/>
                        <Route path="/dragonfire" component={DragonFirePage} exact={true}/>
                        <Route path="/virtualreality" component={VirtualRealityPage} exact={true}/>
                        <Route path="/birthday" component={BirthdayPage} exact={true}/>
                        <Route path="/teambuilding" component={TeamBuildingPage} exact={true}/>
                        <Route path="/escape" component={EscapePage} exact={true}/>
                        <Route path="/events" component={EventsPage} exact={true}/>
                        <Route path="/faq" component={FAQPage} exact={true}/>
                        <Route path="/about-us" component={AboutUsPage} exact={true}/>
                        <Route path="/contact" component={ContactPage} exact={true}/>
                        <Route path="/feedback" component={FeedbackPage} exact={true}/>
                        <Route path="/feedback2" component={FeedbackV2Page} exact={true}/>
                        <Route path="/franchise" component={FranchisePage} exact={true}/>
                        <Route path="/license" component={FranchisePage} exact={true}/>
                        <Route path="/partner" component={FranchisePage} exact={true}/>
                        <Route path="/gift" component={GiftPage} exact={true}/>
                        <Route path="/book" component={BookPage} exact={true}/>
                        <Route path="/terms" component={TermsConditionsPage} exact={true}/>
                        <Route path="/kitchener" component={KitchenerPage} exact={true}/>
                        <Route path="/mississauga" component={MississaugaPage} exact={true}/>
                        <Route path="/burlington" component={BurlingtonPage} exact={true}/>
                        <Route path="/freeroamvrcombopackage" component={FreeRoamVRComboPackagePage} exact={true}/>
                        <Route path="/decomposedpackage" component={DecomposedPackagePage} exact={true} />
                        <Route path="/stationaryvrpackage" component={StationaryVRPackagePage} exact={true}/>
                        <Route path="/bestofbothworldspackage" component={BestOfBothWorldsPackagePage} exact={true}/>
                        <Route path="/bestofbothworldspackagemed" component={BestOfBothWorldsPackageMedPage} exact={true}/>
                        <Route path="/defendanddefusepackage" component={DefendAndDefusePackagePage} exact={true}/>
                        <Route path="/partypackage" component={PartyPackagePage} exact={true}/>
                        <Route path="/deluxepartypackage" component={DeluxePartyPackagePage} exact={true}/>
                        <Route path='/blog/:page' exact component={BlogPage} />
                        <Route path='/blog' exact component={BlogPage} />
                        <Route path='/:post' component={BlogPostPage} />
                        <Route component={HomePage}/>
                    </Switch>
                </BrowserRouter>
                <Footer />        
                {(this.state.mobile) && <BottomNav />   }
            </div>
        )
    }  
};

