import React from 'react';

import BookModal from '../components/modals/BookModal';
import Shuffle from 'shufflejs';

export default class ExperienceChooser extends React.Component {
    constructor(props){
        super(props);

        this.state ={
            showModal: false,
            mobile: ($(window).width() <= 736)? true : false,
            shuffleInstance: null,
            filterList: [],
            groupSize: "1to40",
            category: "ShowAll",
            hideCategory: "false",
            filterStyle: !($(window).width() <= 736) ? 
                {
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', 
                    padding: '2em 2em 1em 2em'
                }:{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', 
                    padding: '1em 0em'
                }
        };


        this.filterOptions = this.filterOptions.bind(this);
        this.filterGroupSize = this.filterGroupSize.bind(this);
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.showBookModal = this.showBookModal.bind(this);
        this.bookModalRef = React.createRef();  
        //this.sizer = React.createRef();
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });
        //var Shuffle = window.Shuffle;
        var element = document.querySelector('.my-shuffle-container');
        var sizer = element.querySelector('.shuffle-sizer');

        this.state.shuffleInstance = new Shuffle(element, {
          filterMode: Shuffle.FilterMode.ALL,
          itemSelector: '.js-item'
        });

        this.state.groupSize = this.props.groupSize ? this.props.groupSize : "1to40";
        this.state.category = this.props.category? this.props.category : "ShowAll";
        this.state.hideCategory = this.props.hideCategory? this.props.hideCategory : "false";
        this.state.filterList.push(this.state.groupSize);
        this.state.filterList.push(this.state.category);

        $('#' + this.state.groupSize).addClass('active');
        $('#' + this.state.category).addClass('active');

        this.state.shuffleInstance.filter(this.state.filterList);

        var url = window.location.href;
        if (url.indexOf('experiences')>0) {

            //make filter options sticky
            var h = document.getElementById("filterContainer");
            //var readout = document.getElementById("readout");
            var stuck = false;
            var stickPoint = getDistance();
            var breakpoint = 0;
            
            function getDistance() {
            var topDist = h.offsetTop;
            return topDist;
            }
            
            var oldShuffleContainerPaddingTop = $('#experiencePackages').css('padding-top');

            window.onscroll = function(e) {

                var mainHeaderHeight = $('header').height();
                var stickyContainerHeight = $(h).height();

            var distance = getDistance() - window.pageYOffset - mainHeaderHeight;
            var offset = window.pageYOffset;
            //console.log('stickpoint: ' + stickPoint + '  distance: ' + distance + ' offset:' + offset + '   ' + stuck);
            if ( (distance <= 0) && !stuck) {
                h.style.position = 'fixed';
                h.style.top = mainHeaderHeight + 'px';
                h.style.zIndex = '1';
                stuck = true;
                breakpoint = offset;
                $("#experiencePackages").css('padding-top', mainHeaderHeight + stickyContainerHeight + parseInt(oldShuffleContainerPaddingTop) + "px");
            } else if (stuck && (offset <= breakpoint)){
                h.style.position = 'static';
                stuck = false;
                $("#experiencePackages").css('padding-top', oldShuffleContainerPaddingTop);
            }
            }
        } 

        if (this.state.hideCategory == "true"){
            $("#filterContainer").addClass("hide");
        }
        
    }

    filterGroupSize(event) {
        if (this.state.shuffleInstance) {
            //model
            var size = event.target.attributes.getNamedItem('data-group').value;

            //view
            $('#groupSizeFilter a').each(function() {
                $(this).removeClass('active');
            })
            $(event.target).addClass('active');
            
            //update

            this.state.filterList.splice(this.state.filterList.indexOf(this.state.groupSize), 1);
            this.state.groupSize = size;
            this.state.filterList.push(this.state.groupSize);
            this.state.shuffleInstance.filter(this.state.filterList);
            //console.log(this.state.filterList);
        }
    }

    filterOptions(event) {
        if (this.state.shuffleInstance) {
            var filter = event.target.attributes.getNamedItem('data-group').value;
            this.state.category = filter;
            // remove showall once any other filters are selected
            if (filter != "ShowAll" && this.state.filterList.includes("ShowAll")) {
                this.state.filterList.splice(this.state.filterList.indexOf("ShowAll"), 1);
                $('[data-group=ShowAll]').removeClass('active');
            }
            if (filter != "HyperReality" && this.state.filterList.includes("HyperReality")) {
                this.state.filterList.splice(this.state.filterList.indexOf("HyperReality"), 1);
                $('[data-group=HyperReality]').removeClass('active');
            }
            if (filter != "VirtualReality" && this.state.filterList.includes("VirtualReality")) {
                this.state.filterList.splice(this.state.filterList.indexOf("VirtualReality"), 1);
                $('[data-group=VirtualReality]').removeClass('active');
            }
            if (filter != "Birthday" && this.state.filterList.includes("Birthday")) {
                this.state.filterList.splice(this.state.filterList.indexOf("Birthday"), 1);
                $('[data-group=Birthday]').removeClass('active');
            }
            if (filter != "TeamBuilding" && this.state.filterList.includes("TeamBuilding")) {
                this.state.filterList.splice(this.state.filterList.indexOf("TeamBuilding"), 1);
                $('[data-group=TeamBuilding]').removeClass('active');
            }
            if (filter != "EscapeRoom" && this.state.filterList.includes("EscapeRoom")) {
                this.state.filterList.splice(this.state.filterList.indexOf("EscapeRoom"), 1);
                $('[data-group=EscapeRoom]').removeClass('active');
            }
            if (!this.state.filterList.includes(filter)) {
                this.state.filterList.push(filter); 
                $(event.target).addClass('active');
            } else {
                this.state.filterList.splice(this.state.filterList.indexOf(filter), 1);
                $(event.target).removeClass('active');
            }

            if (this.state.filterList.length <= 1) {
                this.state.filterList.push("ShowAll");
                $('[data-group=ShowAll]').addClass('active');
            }

            this.state.shuffleInstance.filter(this.state.filterList);
            //console.log(this.state.filterList);

        }
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    showBookModal(event){
        var aptType = event.target.attributes.getNamedItem('appointment-type').value;
        this.bookModalRef.current.state.appointmentType = aptType;
        //console.log("current category is: " + this.state.category);
        if (aptType == "stationaryvr" || aptType == "bestofbothworlds") {
            document.location.href = "/book?appointmentType="+aptType;
        } else {
            this.setState(()=> ({showModal: true}));
        }
    }

    render(){
        return(
            <div>
                <a id="experienceChooser"></a>
                <section id="filterContainer" className="wrapper style5 special no-border" style={this.state.filterStyle}>
                    <div className="container inner">
                            {/*
                            <div class="row">
                            <div id="groupSizeFilter" class="filters-group">
                            <ul class="btn-group filter-options">
                                <li><label class="filter-label">Group Size</label></li>
                                <li><a id="1to5" class="btn" onClick={this.filterGroupSize} data-group="1to5">1 - 5</a></li>
                                <li><a id="6to10"class="btn" onClick={this.filterGroupSize} data-group="6to10">6 - 10</a></li>
                                <li><a id="11to20"class="btn" onClick={this.filterGroupSize} data-group="11to20">11 - 20</a></li>
                                <li><a id="Over20"class="btn" onClick={this.filterGroupSize} data-group="Over20">21 +</a></li>
                                </ul>
                            </div>
                            </div>
                            */}
                            <div class="row">
                            <div id="categoryFilter" class="filters-group">
                                <ul class="btn-group filter-options">
                                <li><label class="filter-label">Category</label></li>
                                <li><a id="ShowAll" class="btn" onClick={this.filterOptions} data-group="ShowAll">Show All</a></li>
                                <li><a id="HyperReality" class="btn" onClick={this.filterOptions} data-group="HyperReality">Free-Roam VR</a></li>
                                <li><a id="VirtualReality" class="btn" onClick={this.filterOptions} data-group="VirtualReality">Stationary VR</a></li>
                                {
                                    /*
                                    *                                 }<li><a id="EscapeRoom" class="btn" onClick={this.filterOptions} data-group="EscapeRoom">Escape Room</a></li>

                                    */
                                }
                                <li><a id="Birthday" class="btn" onClick={this.filterOptions} data-group="Birthday">Birthday</a></li>
                                <li><a id="TeamBuilding" class="btn" onClick={this.filterOptions} data-group="TeamBuilding">Team Building</a></li>
                                </ul>
                            </div>
                        </div>
                   </div>
                </section>  
                
                <section id="experiencePackages" className="wrapper style5" >
                    <div className="container inner" style={{paddingBottom: '2em'}}>
                        <div class="row my-shuffle-container">
                            <figure class="col-12 col-md-6 js-item  shuffle-item shuffle-item--visible" data-groups='["ShowAll-HIDE", "HyperReality-HIDE", "1to5", "1to40", "Sherwood", "Dragonfire"]' data-date-created="2016-06-09" data-title="Crossroads">
                                <div class="aspect aspect--16x9">
                                    <div class="aspect__inner">
                                        <a href="/freeroamvrcombopackage"><img class="shuffle-img" src="../images/freeroam-wide.jpg" alt="A multi-level highway stack interchange in Puxi, Shanghai" /></a>
                                        <div class="package-details row no-gutters">
                                            <div class="col-6">
                                            <div class="row">
                                                <div class="col-12 package-name">Free-Roam Combo</div>
                                                <div class="col-12 package-duration">1-5 Players, 50 minutes</div>
                                            </div>
                                            </div>
                                            <div class="col-3">
                                                <a href="/freeroamvrcombopackage" className="button">Learn<br></br>More</a>
                                            </div>
                                            <div class="col-3">
                                                <a className="button" onClick={this.showBookModal} appointment-type="freeroamcombo">Book<br></br>Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <figure class="col-12 col-md-6 js-item  shuffle-item shuffle-item--visible" data-groups='["ShowAll-HIDE", "VirtualReality-HIDE", "EscapeRoom-HIDE", "1to5", "1to40"]' data-date-created="2016-06-09" data-title="Crossroads">
                                <div class="aspect aspect--16x9">
                                    <div class="aspect__inner">
                                        <a href="/decomposedpackage"><img class="shuffle-img" src={"../images/decomposed-wide.jpg"} alt="A multi-level highway stack interchange in Puxi, Shanghai" /></a>
                                        <div class="package-details row no-gutters">
                                            <div class="col-6">
                                            <div class="row">
                                                <div class="col-12 package-name">Decomposed</div>
                                                <div class="col-12 package-duration">2-5 Players, 60 minutes</div>
                                            </div>
                                            </div>
                                            <div class="col-3">
                                                <a href="/decomposedpackage" className="button">Learn<br></br>More</a>
                                            </div>
                                            <div class="col-3">
                                                <a className="button" onClick={this.showBookModal} appointment-type="decomposed">Book<br></br>Now</a>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </figure>
                            <figure class="col-12 col-md-6 js-item  shuffle-item shuffle-item--visible" data-groups='["ShowAll", "VirtualReality", "1to5", "1to40", "Beatsaber", "Arizona"]' data-date-created="2016-06-09" data-title="Crossroads">
                                <div class="aspect aspect--16x9">
                                    <div class="aspect__inner">
                                        <a href="/stationaryvrpackage"><img class="shuffle-img" src={"../images/vrbooth-bg.jpg"} alt="A multi-level highway stack interchange in Puxi, Shanghai" /></a>
                                        <div class="package-details row no-gutters">
                                            <div class="col-6">
                                            <div class="row">
                                                <div class="col-12 package-name">Stationary VR</div>
                                                <div class="col-12 package-duration">$35, 2-5 Players, 60 mins</div>
                                            </div>
                                            </div>
                                            <div class="col-3">
                                                <a href="/stationaryvrpackage" className="button">Learn<br></br>More</a>
                                            </div>
                                            <div class="col-3">
                                                <a className="button" onClick={this.showBookModal} appointment-type="stationaryvr">Book<br></br>Now</a>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </figure>
                            <figure class="col-12 col-md-6 js-item  shuffle-item shuffle-item--visible" data-groups='["ShowAll", "HyperReality", "VirtualReality", "Birthday", "TeamBuilding", "1to5", "1to40", "Sherwood", "Dragonfire", "Beatsaber", "Arizona"]' data-date-created="2016-06-09" data-title="Crossroads">
                                <div class="aspect aspect--16x9">
                                    <div class="aspect__inner">
                                        <a href="/bestofbothworldspackage"><img class="shuffle-img" src={"../images/magicdoor-wide.jpg"} alt="A multi-level highway stack interchange in Puxi, Shanghai" /></a>
                                        <div class="package-details row no-gutters">
                                            <div class="col-6">
                                            <div class="row">
                                                <div class="col-12 package-name">Best of Both Worlds</div>
                                                <div class="col-12 package-duration">$45, 2-10 Players, 70 mins</div>
                                            </div>
                                            </div>
                                            <div class="col-3">
                                                <a href="/bestofbothworldspackage" className="button">Learn<br></br>More</a>
                                            </div>
                                            <div class="col-3">
                                                <a className="button" onClick={this.showBookModal} appointment-type="bestofbothworlds">Book<br></br>Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <figure class="col-12 col-md-6 js-item  shuffle-item shuffle-item--visible" data-groups='["ShowAll-HIDE", "HyperReality-HIDE", "Birthday-HIDE", "TeamBuilding-HIDE", "6to10", "1to40", "Sherwood", "Bombdefusal"]' data-date-created="2016-06-09" data-title="Crossroads">
                                <div class="aspect aspect--16x9">
                                    <div class="aspect__inner">
                                        <a href="/defendanddefusepackage"><img class="shuffle-img" src={"../images/defend&defuse-wide.jpg"} alt="A multi-level highway stack interchange in Puxi, Shanghai" /></a>
                                        <div class="package-details row no-gutters">
                                            <div class="col-6">
                                            <div class="row">
                                                <div class="col-12 package-name">Defend and Defuse</div>
                                                <div class="col-12 package-duration">6-10 Players, 60 minutes</div>
                                            </div>
                                            </div>
                                            <div class="col-3">
                                                <a href="/defendanddefusepackage" className="button">Learn<br></br>More</a>
                                            </div>
                                            <div class="col-3">
                                                <a className="button" onClick={this.showBookModal} appointment-type="defendanddefuse">Book<br></br>Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <figure class="col-12 col-md-6 js-item  shuffle-item shuffle-item--visible" data-groups='["ShowAll-HIDE", "HyperReality-HIDE", "Birthday-HIDE", "TeamBuilding-HIDE", "11to20", "Over20", "1to40", "Sherwood", "Bombdefusal", "Pictionary"]' data-date-created="2016-06-09" data-title="Crossroads">
                                <div class="aspect aspect--16x9">
                                    <div class="aspect__inner">
                                        <a href="/partypackage"><img class="shuffle-img" src={"../images/vrpictionary-wide.jpg"}alt="A multi-level highway stack interchange in Puxi, Shanghai" /></a>
                                        <div class="package-details row no-gutters">
                                            <div class="col-6">
                                            <div class="row">
                                                <div class="col-12 package-name">Party Package</div>
                                                <div class="col-12 package-duration">11-20 Players, 90+ minutes</div>
                                            </div>
                                            </div>
                                            <div class="col-3">
                                                <a href="/partypackage" className="button">Learn<br></br>More</a>
                                            </div>
                                            <div class="col-3">
                                                <a className="button" onClick={this.showBookModal} appointment-type="party">Book<br></br>Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <figure class="col-12 col-md-6 js-item  shuffle-item shuffle-item--visible" data-groups='["ShowAll", "HyperReality", "Birthday", "TeamBuilding", "11to20", "Over20", "1to40", "Sherwood", "Bombdefusal", "Pictionary", "Beatsaber", "Arizona"]' data-date-created="2016-06-09" data-title="Crossroads">
                                <div class="aspect aspect--16x9">
                                    <div class="aspect__inner">
                                        <a href="/deluxepartypackage"><img class="shuffle-img" src={"../images/landingportal-wide.jpg"} alt="A multi-level highway stack interchange in Puxi, Shanghai" /></a>
                                        <div class="package-details row no-gutters">
                                            <div class="col-6">
                                            <div class="row">
                                                <div class="col-12 package-name">Deluxe Party Package</div>
                                                <div class="col-12 package-duration">11-40 Players, 120+ minutes</div>
                                            </div>
                                            </div>
                                            <div class="col-3">
                                                <a href="/deluxepartypackage" className="button">Learn<br></br>More</a>
                                            </div>
                                            <div class="col-3">
                                                <a className="button" onClick={this.showBookModal} appointment-type="deluxeparty">Book<br></br>Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <figure class="col-12 col-md-6 js-item  shuffle-item shuffle-item--visible" data-groups='["ShowAll", "VirtualReality", "EscapeRoom", "1to5", "1to40"]' data-date-created="2016-06-09" data-title="Crossroads">
                                <div class="aspect aspect--16x9">
                                    <div class="aspect__inner">
                                        <img class="shuffle-img" src={"../images/lostpyramidcomingsoon-wide.jpg"} alt="A multi-level highway stack interchange in Puxi, Shanghai" />
                                        <div class="package-details row no-gutters">
                                            <div class="col-6">
                                            <div class="row">
                                                <div class="col-12 package-name">Lost Pyramid</div>
                                                <div class="col-12 package-duration">2-5 Players, 60 minutes</div>
                                            </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </figure>
                    </div>
                    </div>
                </section>
                <BookModal 
                    ref={this.bookModalRef}
                    category={this.state.category}
                    showModal={this.state.showModal}
                    exitModal={this.exitModal}
                />
            </div>
        )
    }
}
