import React, { Component } from 'react';

export default class Testimonials extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

  componentDidMount() {
    

        /*----------------------------- HOMEPAGE ------------------------------*/
        //Collage
        $( '#ri-grid' ).gridrotator(
            {
                rows : 2,
                columns: 5,
                preventClick: true,
                step : 4,
                maxStep : 5,
                interval : 2000,
                w1024 : { rows : 2, columns : 5 },
                w768 : {rows : 2,columns : 4 },
                w480 : {rows : 3,columns : 3 },
                w320 : {rows : 3,columns : 3 },
                w240 : {rows : 3,columns : 3 }
              } 
        );

        //testimonials
        if ($('.testamonials').length > 0) {
            $('.testamonials').slick({
               arrows: false,
               dots: false,
               dotsClass: 'teaser-slideshow-dots',
               infinite: true,
               lazyLoad : true,
               fade: false,
               autoplay: true,
               autoplaySpeed: 5000,
               speed: 1000,
               slidesToShow: 1,
               swipe: true,
               swipeToSlide: true
         });
        } 
  }
  componentWillUnmount() {

  }

  render() {
    return (

              <section id="collage" style={{padding:'0px'}}>
                      <div id="ri-grid" className="ri-grid ri-grid-size-2 ri-shadow" >
                          <ul>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/38.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/39.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/1.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/2.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/14.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/15.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/16.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/17.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/18.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/8.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/9.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/10.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/11.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/12.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/13.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/20.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/21.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/22.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/23.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/24.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/3.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/4.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/5.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/6.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/7.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/29.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/30.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/31.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/25.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/26.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/27.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/28.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/19.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/32.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/33.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/34.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/35.jpg")}/></a></li>
                              <li><a href="#"><img className="lazy" src={require("../images/medium/36.jpg")}/></a></li> 
                          </ul>
                          <div className="overlay">
                          </div>
                          
                          <div className="overlay-no-fade">
                              <div className="testamonials">
                                  <div><p>Definitely exceeded my expectation. This was the most fun I have had in a long time, completely forgot that I was actually in the convention center. I played the warrior and I get to use an ACTUAL sword to fight monsters, defintely want to try the magician if I get to play this again! <br/><br/> <span className="mirage-teal">- Jonathan Davis @ Toronto Comic Con</span></p></div>
                                  <div><p>This is by far the most immersive VR experience I have had. I have tried other VR games and arcades before, this is on a different level. I could move around freely and use my entire body to explore. My favourite part was kicking monsters and watching them fly away! Had never seen this in any other VR experiences. <br/><br/> <span className="mirage-teal">- Gabriel Lam @ Hong Kong E-Sports Festival</span></p></div>
                                  <div><p>I have never had such a great time. The VR experience was extraordinary with an adrenaline rush. We were transported to another world of pirates and fireballs and swords. What better way to enjoy a weekend! <br/><br/> <span className="mirage-teal">- Patricia Rego @ TAVES Electronics Show</span></p></div>
                                  <div><p>Thank you MirageVR for adding a new dimension to what I thought was dull gaming. I was waiting for such an experience for years and you have made me part of it last night! It was a most memorable experience that brought me back to my childhood days where I was completely absorbed into gaming. It makes me feel like a child again. <br/><br/> <span className="mirage-teal">- Cristina Arsene @ Startup Open House</span></p></div>
                                  <div><p>I never game - but would 10/10 be down to play Mirage VR anytime. Gets you out of your seat and throws you into a whole new world. Love the multi-player dimension and how realistic everything appears in front of you. Definitely see lots of potential to where Mirage VR can go! <br/><br/> <span className="mirage-teal">- Anna Zuo @ TEDxWaterloo</span></p></div>
                                  <div><p>It was a blast! I have tried the HTC Vive before but while I played it I always felt so isolated. I wanted to share the experience with others. This is the perfect way to solve that problem. Very impressed! <br/><br/> <span className="mirage-teal">- Brad Moon @ Mirage VR Prelaunch</span></p></div>
                                  <div><p>The smoothest, most realistic VR experience I've had. There was no motion sickness and no latency in my movements. I hope to soon see your technology around, as I'd love to try out your games again! <br/><br/> <span className="mirage-teal">- Daniel Franz @ Communitech Showcase</span></p></div>
                              </div>
                          </div>

                      </div>
              </section>
    );
  }
}