import React from 'react';
import Helmet from 'react-helmet';
import WatchTrailerModal from '../components/modals/WatchTrailerModal';

export default class AboutUsPage extends React.Component {
	constructor(props){
        super(props);
        this.state = {
			showVideo: false,
			mobile: ($(window).width() <= 736)? true : false
        }
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
    }

    componentDidMount(){ 
		$(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });             
    }

    openModal(){
        this.setState(()=> ({showVideo: true}));
    }
    
    exitModal(){
        this.setState(()=> ({showVideo: false}));
    }
	
	render(){
		return(
			<div>
				<Helmet>
					<style></style>
					<meta charSet="utf-8" />
					<link rel="canonical" href="https://www.miragevr.ca/about-us"/>
                    <meta name="description" content="If you would like to enjoy the best virtual reality experience with friends, family, or colleagues, Mirage VR has everything needed to create an unforgettable and exciting experience that everyone can enjoy. Learn more about us here."/>
					<title>About Mirage VR | Top Virtual Reality Business in Ontario</title>
				</Helmet>
				<WatchTrailerModal
				showModal={this.state.showVideo}
				exitModal={this.exitModal}
				/>
				<article id="main">
					<header>
						<div className="heavy-text" >
							<h2>Enter the <span className="mirage-teal">Matrix</span><br/> Enter <span className="mirage-teal">Mirage</span></h2>
							<p>Canada's First Full-body, Free-movement, Multiplayer VR Experience Centre</p>
							<button id="fb-video-trigger" onClick={this.openModal} className="small">Watch Trailer</button>
						</div>
					</header>
					<section className="wrapper style5">
						<div className="inner">
							<h4>Our Mission</h4>
							<p>Mirage VR is on a mission to make immersive, social VR experiences more accessible. We believe it is the beginning of a new entertainment era. With VR as the medium, people can experience things and travel to worlds that they could have only dreamed of before. At its core, Mirage VR is about bringing people together and creating unforgettable memories that extend beyond virtual dimensions.</p>
							<hr/> 
							<h4>Experience Centre</h4>
							<p>We are Canada’s first to offer full-body, free-roam, multiplayer VR experiences. At Mirage VR, instead of playing a game, you are LIVING it! Players come in groups and enter fantasy worlds where they use unique magical powers and teamwork to overcome obstacles.</p>

							<h4>WHERE</h4>
							<p>Located at 283 Duke St West, Mirage VR’s flagship experience centre sits in the heart of Waterloo Region’s tech sector in downtown Kitchener, minutes away from the central rail station connecting 3 cities, amongst thousands of high-tech companies, and neighboring Google’s biggest R&D office in Canada.</p>
										

							<h4>WHY</h4>
							<p>Virtual Reality is not a new concept anymore, yet most haven’t had a truly enjoyable or memorable VR experience. Despite recent promotional efforts by hardware manufacturers to popularize VR headsets, most devices still cost hundreds to purchase, and potentially thousands to get the right computer for. Even worse, enjoying VR with friends is difficult if not impossible due to hardware incompatibility and lack of content. While recent development of VR arcades does a great job at introducing the general public to VR, it is rare to find truly immersive multiplayer experiences that places social interaction at its core.</p>
							<p>Compared to existing VR arcades, Mirage VR differentiates itself by its:</p>

							<br/>

							<div className="row">
								<div className="col-12 col-md-4">
									<h5>Full-body experience</h5>
									<p>Move just as you would in real life: dance, kick, high-five - all without ever clicking a button. Your physical movements are mapped one-to-one to your in-game avatar.</p>
								</div>
								<div className="col-12 col-md-4">
									<h5>Free roam exploration</h5>
									<p>No need to worry about wires or cables, and no more uncomfortable teleportation. Players wear special equipment allowing physical exploration of virtual environments.</p>
								</div>
								<div className="col-12 col-md-4">
									<h5>Customized content</h5>
									<p>All experiences are designed to exploit the unique full-body and free-roam capabilities, maximizing immersion through environmental and real player interactions.</p>
								</div>
							</div>

							<hr/>
							<p>For more information, please email us at info@miragevr.ca.</p>
						</div>
					</section>
				</article>
			</div>
		)
	}
};
