import React from 'react';
import Helmet from 'react-helmet';

import BookModal from '../components/modals/BookModal';


export default class DragonFirePage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            mobile: ($(window).width() <= 736)? true : false
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });              
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    render(){
        return(
            <div>
            <Helmet>
                <style></style>
                <meta charSet="utf-8" />
                <title>Dragon Fire | Mirage VR</title>
                <link rel="canonical" href="https://www.miragevr.ca/dragonfire"/>
            </Helmet>
            <article id="main"  >
                <header className="dragonfire-bg">
                    <div className="heavy-text major">
                        <h1>Dragon <span style={{color:'red'}}>fire</span></h1>
                        <p>Journey of the Black Pearl</p>
                    </div>
                </header>

                <section className="wrapper style5" style={{padding: '3em 0 0 0 ! important'}}>
                    <div className="" style={{
                        marginLeft: '3em',
                        marginRight: '3em'
                    }}>
                        <section>
                            <div className="row">
                                <div className="col-12 col-sm-3 col-lg-3">
                                    <span className="image fit"><img src={require("../images/newdragonfire_thumbnail.jpg")} alt=""/></span>
                                </div>
                                <div className="col-12 col-sm-9 col-lg-5">
                                    <h3>Description</h3>
                                    <p>Ahoy Matey! We be lookin’ for a crew to set sail into the open waters. There be rumours of bountiful treasures guarded by a fearsome dragon. Dragons don’t really exist though, do they? Argh, I hear there’s a strange mist out there that I ain’t ever seen anyone come back out of. Sounds like a blast to me, you up for an adventure?
                                    </p>
                                    <h5 style={{display:'none', textTransform: 'capitalize'}}>Tickets</h5>
                                    <p><b>$20</b>/person<br/><br/> <span style={{color:'#ED4933'}}>*Exclusive Content. Only available as a part of the Flagship Experience Package.</span></p>
                                    <a id="gamepage-book" className="button special" onClick={this.openModal} alt="" >Book Now</a>
                                </div>

                                <div className="col-12 col-sm-12 col-lg-4">
                                    <h3>Detail</h3>
                                    <table style={{textTransform:'capitalize'}}>
                                        <tbody style={{ verticalAlign: 'middle'}}>
                                            <tr>
                                                <td style={{verticalAlign: 'middle'}}><i className="icon fa-users" style={{
                                                color: '#ed4933',
                                                fontSize: '3em',
                                                opacity: '0.8'
                                                }}></i></td>
                                                <td>
                                                    <div>
                                                        <h5 style={{
                                                            textTransform: 'capitalize'
                                                            }}>Team size</h5>
                                                        <p style={{
                                                            marginBottom: '0',
                                                            lineHeight: 'normal',
                                                            fontSize: '0.9em',
                                                            }}>Standard Mode: 2-5 players <br/>Tournament Mode: 6-10 players</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign: 'middle'}}>
                                                    <img src={require('../images/money-back.png')} style={{width:"53px"}}/>
                                                </td>
                                                <td>
                                                    <div>
                                                        <h5 style={{
                                                            textTransform: 'capitalize'
                                                            }}>Satisfaction Guarantee</h5>
                                                        <p style={{
                                                            marginBottom: '0',
                                                            lineHeight: 'normal',
                                                            fontSize: '0.9em'
                                                        }}>We are so sure that you will totally love the experience that we offer a 100% refund if otherwise</p>
                                                    </div>
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </div>
            </section>

        </article>
        <BookModal 
            showModal={this.state.showModal}
            exitModal={this.exitModal}
        />
        </div>
        )
    }                                       
};


/* IMAGE for satisfaction garauntee 
    <img src="../images/money-back.png" style={{width:'53px'}}/>
*/

/*Unused table rows*/
/*
            <tr style={{display:'none'}}>
                                        <td style={{verticalAlign: 'middle'}}><i className="icon fa-clock-o" style={{
                                            color: '#ed4933',
                                            fontSize: '3em',
                                            opacity: '0.8'
                                            }}></i></td>
                                        <td style={{verticalAlign: 'middle'}}>
                                            <div style="">
                                               <h5 style={{textTransform: 'capitalize'}}>Length</h5>
                                               <p style={{
                                                  marginBottom: '0',
                                                  lineHeight: 'normal',
                                                  fontSize: '0.9em'
                                               }}>
                                                  25 minute reservation<br/>
                                                  * 5 minute introduction &amp; virtual training <br/>
                                                  * 15 minute game experience <br/>
                                                  * 5 minute back-to-reality &amp; debrief
                                               </p>
                                            </div>
                                         </td>
                                    </tr>
*/

/*
                                    <tr style={{display:'none'}}>
                                        <td style={{verticalAlign: 'middle'}}><i className="icon fa-star-o" style={{
                                        color: '#ed4933',
                                        fontSize: '3em',
                                        opacity: '0.8'
                                        }}></i></td>
                                        <td>
                                        <div>
                                            <h5 style={{
                                                textTransform: 'capitalize'
                                                }}>Difficulty</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '0.9em'
                                            }}>Available in casual or hardcore mode</p>
                                        </div>
                                        </td>
                                    </tr>
*/