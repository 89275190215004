import React from 'react';
import LazyLoad from 'react-lazyload';

export default class AsFeaturedOnLogos extends React.Component{
    constructor(props){
      super(props);
    }

    render(){
      return(

        <LazyLoad height={200} once offset={100}>
        <article id="top-banner" className="wrapper style7 special no-border" style={{padding:'2em 2em 1em 2em'}}>
            <section>                    
            <div className="inner">
                 <header className="major less-heavy-text">
                     <h1> As Featured On:
                     </h1>
                     <br></br>
                 </header>
                 <section>
                         <div className="row">
                             <div className="col-4 col-md-2 image fit align-center"><img className="lazy" src={require("../images/socialproof.png")} /></div>
                             <div className="col-4 col-md-2 image fit align-center"><img className="lazy" src={require("../images/socialproof2.png")}  /></div>
                             <div className="col-4 col-md-2 image fit align-center"><img className="lazy" src={require("../images/socialproof3.png")} /> </div> 
                             <div className="col-4 col-md-2 image fit align-center"><img className="lazy" src={require("../images/socialproof4.png")} /></div>
                             <div className="col-4 col-md-2 image fit align-center"><img className="lazy" src={require("../images/socialproof5.png")}  /></div>
                             <div className="col-4 col-md-2 image fit align-center"><img className="lazy" src={require("../images/socialproof6.png")} /> </div>                           
                         </div>
                 </section>
                 </div>
             </section>
         </article>
     </LazyLoad>
      )
    }
}

