import React from 'react';
import FAQInfo from './FAQRevealnfo';
import Helmet from 'react-helmet';

var QA = { 
    "Q1"  :  "What is Free-Roam Virtual Reality?", 
    "A1"   :  "Free-Roam Virtual Reality is a result of virtually simulated environments combined with physical elements. It allows you to move just as you would in real life: fight with your arms, kick with your legs, and touch with your hands. It is immersion at the highest level.",
    "Q2"  :  "How do I book an appointment?", 
    "A2"   :  <span>Appointments for groups up to 10 players can be made directly on our website. To get started, choose of one of our amazing <a style={{color:'#0000FF'}} href="/contact">experiences</a>!<br />
        For groups with more than 10 players, contact us <a style={{color:'#0000FF'}} href="/contact">here</a>.</span>,
    "Q3"  :  "What is the maximum group size?", 
    "A3"   :  <span>We recommend a maximum group size of 20 players for standard booking. We will divide the group into smaller teams which will rotate between different experience stations. We are also able to host larger groups, please message us on our <a  style={{color:'#0000FF'}} href="/contact">contact form</a> and we will try our best to accommodate you.</span>,
    "Q4"  :  "Who is this experience for?", 
    "A4"   :  <span>Our experience is for anyone looking to have a good time. Our customers range from families to students to business professionals. We also host <a  style={{color:'#0000FF'}} href="/birthday">birthday parties</a> and <a  style={{color:'#0000FF'}} href="/teambuilding">team building</a> events.</span>,
    "Q5"  :  "What is the Free-Roam Combo Package?", 
    "A5"   :  <span>Our most popular option is the Mirage VR Free-Roam Combo Package which includes both <a  style={{color:'#0000FF'}} href="/sherwood">Sherwood Forest</a> and <a  style={{color:'#0000FF'}} href="/dragonfire">Dragonfire</a>. <a style={{color:'#0000FF'}} href="/freeroamvrcombopackage"> Learn more here.</a></span>,
    "Q6"  :  "When is Mirage VR open (Operation Hours)?", 
    "A6"   : <div>
    Mon: Closed
    <br/>
    Tue: Closed
    <br/>
    Wed: 3-10 PM
    <br/>
    Thur: 3-10 PM
    <br/>
    Friday:	3-10 PM
    <br/>
    Saturday: 11 AM - 10 PM
    <br/>
    Sunday:	12-7 PM
    </div>,

    "Q7"  :  "How long are the experiences?", 
    "A7"   :  <span>We have experiences ranging from 50 minutes to 2+ hours. See our <a  style={{color:'#0000FF'}} href="/experiences#booknow">packages</a> for details.</span>,
    "Q8"  :  "How much does it cost?", 
    "A8"   :  <span>Please see our <a  style={{color:'#0000FF'}} href="/experiences#booknow">packages</a> for information on pricing and duration.</span>,
    "Q9"  :  "Are there any age requirements to play?", 
    "A9"   :  "To ensure quality experience, we require players to be age 8+ or at least 48 “ (1.2 m) tall.",
    "Q10"  :  "What should I wear?", 
    "A10"   :  "Wear clothing that is suitable for walking around and movement.",
    "Q11"  :  "Can I wear glasses?", 
    "A11"   :  <span>Yes! We've recently upgraded our headsets, and most glasses will fit inside them. </span>,
    "Q12"  :  <span><a class="anchor" id="refund"></a>What is your refund/cancellation policy?</span>,
    "A12"   :  <div>- All deposits are non-refundable. 
    <br></br>- If we receive more than 24 hour notice of any cancellations, we will refund 80% of the total amount. 
   <br></br>- With less than 24 hours notice, we can keep the amount paid as store credits and <b>will not be able to issue a refund.</b> 
    <br></br>- Store credits cannot be used on Saturdays. Appointments made with store credits are final and cannot be rescheduled. 
</div>,
    "Q13"  :  "Do you accept corporate event bookings?",  
    "A13"   :  <span>Definitely! Please message us on our <a style={{color:'#0000FF'}} href="/contact">contact form</a>.</span>,
    "Q14"  :  "I’m interested in franchising or other business matters.", 
    "A14"   :  <span>Please message us on our <a style={{color:'#0000FF'}} href="/contact">contact form</a>.</span>,
    "Q15": "Do you have a party room?",
    "A15": "Yes, we provide a party room for birthday parties! It is available for 45 minutes after the VR activities - at no extra cost!",
    "Q16": "Is food included in the price?",
    "A16": "No, we leave the cooking to the professionals! Feel free to bring your own food or have them delivered to our facilities.",
    "Q17": "What's inside the party room?",
    "A17": "We will provide party tables, chairs, and a mini-fridge. Please bring anything else you may need for the celebration!",
    "Q18": "What if some people don't show up on the day of the event?",
    "A18": "Oh no! We can transfer the difference as store credit to be used at a later date. Store credits cannot be used on Saturdays. Appointments made with store credits are final and cannot be rescheduled again. Refer to the refund policy for details.",
    "Q19": "Do I need to sign a waiver?",
    "A19": <span>Yes, you can see it <a style={{color:'#0000FF'}} href="/terms">here</a>. Players under 18 years of age require an adult to sign on their behalf. We will have both digital and physical copies in store.</span>,
    "Q20": "I have more than 10 people in my group, how does it work?",
    "A20": <span>We will put the large group into smaller teams of up to 5 people. 
        The smaller groups will rotate between the different VR activities we offer such as Sherwood Forest, VR Bomb Defusal and VR Pictionary. 
        All the activities are group oriented and we also keep score of each team so there is some competition as well! 
        For more information, tell us more about your event <a style={{color:'#0000FF'}} href="/contact">here</a> and we will get back to you within 24 hours!</span>,
    "Q21": "I have more than 40 people in my group, how does it work?",
    "A21": <span>Sounds like we have a party! For groups of this size, we recommend booking out the entire facility for an exclusive event. 
        tell us more about your event <a style={{color:'#0000FF'}} href="/contact">here</a> and we will get back to you within 24 hours!</span>,
    "Q22": "Do you have mobile or offsite options?",
    "A22": <span>Yes, we can bring the fun to you! Each offsite event is slightly different, tell us more about yours <a style={{color:'#0000FF'}} href="/contact">here</a> and we will get back to you within 24 hours!</span>,
    "Q23": "Do you provide a space for food and refreshments?",
    "A23": "Yes, we provide a party room for food and drinks! It is available for 45 minutes after the VR activities - at no extra cost! If you need it outside the 45 minutes, let us know and we will try our best to accommodate.",
    "Q24": "What do I need to do to make a reservation?",
    "A24": "For groups of over 10 people, we require a 20% non-refundable deposit to secure the desired date/time. The remainder will be due 2 days before the event. For groups under 10 people, full payment is required to secure the time slot.",
    "Q25": "How does a birthday party work?",
    "A25": <span>We will create small teams - each with up to 5 players (if you have 15 guests, that's 3 teams).
    The smaller teams will rotate between the different VR games we offer such as Sherwood Forest, VR Bomb Defusal and VR Pictionary. 
    All the activities are group oriented - some are more action packed such as Sherwood Forest, while others are more lighthearted and creative such as VR Pictionary! 
    At the end of the VR activities, you will have a party room for 45 minutes at no additional cost!</span>,
    "Q26": "What's your cleaning and sanitization process?",
    "A26": <div>
        <p>In addition to sanitizing all contact surfaces at the end of the day, we also follow a 3-step sanitization process during <b>EACH</b> session to ensure safety:</p>
        <img class="col-12 image fit" src={require("../images/cleaning_process.jpg")} alt="" />
    </div>,
    "Q27": "How many people can I bring?",
    "A27": "To ensure distancing, we accept a maximum of 10 players per group, and at most 1 observer for every 2 players. In other words, there will be a maximum of 15 customers in our 3000+ square foot facility at a given time.",
    "Q28": "What makes it safe to play at Mirage VR?",
    "A28": 
    <div> 
        <span>We have implemented the following measures to ensure a safe environment for our customers:</span>
        <br></br><br></br>
        <div className="row">
            <div className="col-12 col-md-4">
                <h5>1. Extra Cleaning</h5>
                <p>We clean with disinfectants qualified for use against COVID-19 by the <a href="https://www.epa.gov/pesticide-registration/list-n-disinfectants-use-against-sars-cov-2-covid-19" target="_blank">U.S. Environmental Protection Agency</a>.</p>
            </div>
            <div className="col-12 col-md-4">
                <h5>2. Reduced Capacity</h5>
                <p>For your safety, we are allowing a maximum of 15 customers in our 3000 square foot facility.</p>
            </div>
            <div className="col-12 col-md-4">
                <h5>3. Exclusive Playing Area</h5>
                <p>Only play with your own group in a given area. No crossing paths with strangers.</p>
            </div>
        </div>
    </div>

  }
export default class ExperiencesPage extends React.Component {

    componentDidMount(){ 
        
        // hotjar tracking
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1024291,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }

    render(){
        return(
            <div>
            <Helmet>
                <style></style>
                <meta charSet="utf-8" />
                <link rel="canonical" href="https://www.miragevr.ca/faq"/>
                <meta name="description" content="Have any questions about how our virtual reality experiences work, or want more information about our packages at Mirage VR? Review our FAQ here or contact us at any time for additional information."/>
                <title>Frequently Asked Questions About Mirage VR Experiences</title>
            </Helmet>
            <article id="main">
                <header>
                    <div className="heavy-text" >
                        <h2>Frequently Asked Questions</h2>
                    </div>
                </header>
                <section className="wrapper style5" >

                    <div className="inner">
                        <a class="anchor" id="General"></a>
                        <h3>General Questions</h3>
                        <table id="faq">
                            <tbody>
                            <FAQInfo question={QA.Q1} answer={QA.A1}/>
                            <FAQInfo question={QA.Q2}answer={QA.A2}/>
                            <FAQInfo question={QA.Q3}answer={QA.A3}/>
                            <FAQInfo question={QA.Q4}answer={QA.A4}/>
                            <FAQInfo question={QA.Q9}answer={QA.A9}/>
                            <FAQInfo question={QA.Q11} answer={QA.A11}/>
                            <FAQInfo question={QA.Q12}answer={QA.A12}/>
                            </tbody>
                        </table>
                    </div>

                    <div className="inner">
                        <a class="anchor" id="Birthday"></a>
                        <h3>Birthday Party Questions</h3>
                        <table id="faq">
                            <tbody>
                            <FAQInfo question={QA.Q25} answer={QA.A25}/>
                            <FAQInfo question={QA.Q15} answer={QA.A15}/>
                            <FAQInfo question={QA.Q16}answer={QA.A16}/>
                            <FAQInfo question={QA.Q17}answer={QA.A17}/>
                            <FAQInfo question={QA.Q18}answer={QA.A18}/>
                            </tbody>
                        </table>
                    </div>

                    <div className="inner">
                        <a class="anchor" id="Teambuilding"></a>
                        <h3>Team Building Questions</h3>
                        <table id="faq">
                            <tbody>
                            <FAQInfo question={QA.Q20} answer={QA.A20}/>
                            <FAQInfo question={QA.Q21}answer={QA.A21}/>
                            <FAQInfo question={QA.Q22}answer={QA.A22}/>
                            <FAQInfo question={QA.Q23}answer={QA.A23}/>
                            <FAQInfo question={QA.Q24}answer={QA.A24}/>
                            </tbody>
                        </table>
                    </div>

                    <div className="inner">
                        <a class="anchor" id="covid19"></a>
                        <h3>Hygiene Questions</h3>
                        <table id="faq">
                            <tbody>
                            <FAQInfo question={QA.Q26} answer={QA.A26}/>
                            <FAQInfo question={QA.Q28} answer={QA.A28}/>
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="inner">
                        <h3>Other Questions</h3>
                        <table id="faq">
                            <tbody>
                            <FAQInfo  question={QA.Q7}  answer={QA.A7}/>
                            <FAQInfo  question={QA.Q8}  answer={QA.A8}/>
                            <FAQInfo question={QA.Q10} answer={QA.A10}/>
                            <FAQInfo question={QA.Q19} answer={QA.A19}/>
                            <FAQInfo question={QA.Q13} answer={QA.A13}/>
                            <FAQInfo question={QA.Q14} answer={QA.A14}/>
                            </tbody>
                        </table>
                    </div>
                </section>
             </article>
             </div>
        );
    }
};
