import React from 'react';
import Modal from 'react-modal';

//Used to display error on pages where there is a form

const ErrorModal = (props) => (
        <Modal
            isOpen={props.showModal}
            onRequestClose= {props.exitModal}
            ariaHideApp={false}
            closeTimeoutMS={200}
            className="modal"
            >
            <div id="userinfo" className="iziModal-wrapper" style={{padding: '0 ! important'}}>
                <div className="inner fit vertical align-center">
                   <h2>Ooops!</h2>
                   <p>Looks like something went wrong on our end, please send us an email or try again later.</p>
                </div>
            </div>
        </Modal>


);

export default ErrorModal;