import React from 'react';
import Helmet from 'react-helmet';

const TermsConditionsPage = () => (
	<div>
	    <Helmet>
	        <meta charSet="utf-8" />
	        <title>Terms and Conditions | Mirage VR</title>
	        <link rel="canonical" href="https://www.miragevr.ca/terms"/>
	    </Helmet>
	    <article id="main" style={{backgroundImage: 'url("https://wallup.net/wp-content/uploads/2016/01/104067-Horsehead_Nebula-space-nebula.jpg")'}}>
		        <section className="wrapper" >
		            <div className="inner">
					    <object data="Waiver-and-Release.pdf" type="application/pdf" style={{width:'100%', height:'800px'}}>
					          <p>It appears you don't have a PDF plugin for this browser. No biggie... you can <a href="/Waiver-and-Release.pdf" style={{color:'blue'}}>click here to download the PDF file.</a></p>
					        <embed src="Waiver-and-Release.pdf" type="application/pdf" />
					    </object>
				    </div>
		    	</section>
	    </article>
	</div>
);

export default TermsConditionsPage;

