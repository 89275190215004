import React from 'react'
import ThankYouModal from '../components/modals/ThankYouModal';
import ErrorModal from '../components/modals/ErrorModal';

export default class FranchiseForm extends React.Component {
    constructor(props){
        super(props);
        //inputs
        this.state = {
            name: "",
            organization: "",
            email: "",
            message: "",
            phone: "",
            city: "",
            country: "",
            facility: "",
            showThankYouModal: false,
            showErrorModal: false
 
        };

        //Binding Functions
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleFacilityChange = this.handleFacilityChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);

    }

    componentDidMount(){

    }

    openModal(){
        if(this.state.name&&this.state.email&&this.state.phone&&this.state.country&&this.state.city){
            this.setState({showThankYouModal: true});
        }
    }

    exitModal(){
        this.setState({showThankYouModal: false})
        this.setState({showErrorModal: false})
        this.setState({name: ''});
        this.setState({organization: ''});
        this.setState({email: ''});
        this.setState({message: ''});
        this.setState({phone: ''});
        this.setState({city: ''});
        this.setState({country: ''});
    }

    handleNameChange(e){
        this.setState({name: e.target.value});
    }

    handleOrganizationChange(e){
        this.setState({organization: e.target.value});
    }

    handleEmailChange(e){
        this.setState({email: e.target.value});
    }

    handlePhoneChange(e){
        this.setState({phone: e.target.value});
    }

    handleCityChange(e){
        this.setState({city: e.target.value});
    }

    handleCountryChange(e){
        this.setState({country: e.target.value})
    }

    handleMessageChange(e){
        this.setState({message: e.target.value});
    }

    handleFacilityChange(e){
        this.setState({facility: e.target.value});
    }

    gtag_report_conversion(url) {
      var callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = url;
        }
      };
      gtag('event', 'conversion', {
          'send_to': 'AW-832069074/jO3PCOKhyIoBENK74YwD',
          'event_callback': callback
      });
      return false;
    }

    handleSubmit(e){
        e.preventDefault();
        var name = this.state.name.trim();
		var email = this.state.email.trim();
        var message = this.state.message.trim();
        var phone = this.state.phone.trim();
        var city = this.state.city.trim();
        var country = this.state.country.trim();
        var organization = this.state.organization.trim();
        var facility = this.state.facility.trim();
        var search = document.location.search;

        this.gtag_report_conversion();
        
        $.ajax({
			url: '/inquire',
			type: 'POST',
			data: {
				name: name,
				email: email,
				message:message,
                organization: organization,
                phone: phone,
                city: city,
                country: country,
                facility: facility,
                urlParams: search
			},
			success: function(data) {
                this.setState({data: data});
				this.setState({showThankYouModal: true});
			}.bind(this),
			error: function (err)
            { console.log(err.responseText);
                this.setState({showErrorModal: true});
            }.bind(this)
        
        });

        // Hash to avoid PII.
        var userID = window.stringHash(email);
        ga('set', 'dimension1', userID);
        ga('set', 'userId', userID);
        mixpanel.alias(email);
        mixpanel.track('Submit inquiry');
    }


    
    //method=POST
    render(){
        return(
            <div>
                <form id="messageForm" onSubmit={this.handleSubmit} method="post" action="#">
                    <div className="row uniform">
                        <label className="col-12 col-md-6">
                            <input type="text" name="name" id="name" value={this.state.name} onChange={this.handleNameChange} placeholder="Name" required/>
                        </label>
                        <label className="col-12 col-md-6">
                            <input type="text" name="organization" id="organization" value={this.state.organization} onChange={this.handleOrganizationChange} placeholder="Organization(Optional)"/>
                        </label>
                    </div>
                    <div className="row uniform">
                        <label className="col-12 col-md-6">
                            <input type="email" name="email" id="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email" required/>
                        </label>
                        <label className="col-12 col-md-6">
                            <select value={this.state.facility} onChange={this.handleFacilityChange} id="facility" required>
                                <option value="">Facility</option>
                                <option value="new">New Facility</option>
                                <option value="themepark">Theme Park</option>
                                <option value="theater">Movie Theater</option>
                                <option value="fec">FEC</option>
                                <option value="escaperoom">Escape Room</option>
                                <option value="arcade">Arcade</option>
                                <option value="other">Other</option>
                            </select>
                        </label>
                    </div>
                    <div className="row uniform">
                        <label className="col-12 col-md-4">
                            <input type="text" name="phone" id="phone" value={this.state.phone} onChange={this.handlePhoneChange} placeholder="Phone Number" required/>
                        </label>
                        <label className="col-12 col-md-4">
                            <input type="text" name="city" id="city" value={this.state.city} onChange={this.handleCityChange} placeholder="City" required/>
                        </label>
                        <label className="col-12 col-md-4">
                            <input type="text" name="country" id="country" value={this.state.country} onChange={this.handleCountryChange} placeholder="Country" required/>
                        </label>
                    </div>

                    <div className="row uniform">
                        <label className="col-12">
                            <textarea name="message" id="message" value={this.state.message} onChange={this.handleMessageChange} placeholder="Leave us a message and a Mirage VR representative will get back to you shortly" rows="3"></textarea>
                        </label>
                        <p id="formError" className="hide" style={{color:"red"}}>invalid </p>
                    </div>
                    <div className="row uniform">
                        <label className="col-12 col-md-4">
                            <ul className="actions">
                                <li ><input id="sendMessage" type="submit" value="Send Message" className="special"/></li>
                            </ul>
                        </label>
                    </div>
            </form>
            <ThankYouModal
            showModal={this.state.showThankYouModal}
            exitModal={this.exitModal}
            />       
            <ErrorModal
            showModal={this.state.showErrorModal}
            exitModal={this.exitModal}
            />      
          </div>
        );
    }
}