import React from 'react';
import Form from './ContactForm';
import Helmet from 'react-helmet';

const ContactPage= () => (
    <div>
    <Helmet>
        <style></style>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.miragevr.ca/contact"/>
        <meta name="description" content="If you have any questions or concerns about Mirage VR or would like more details about how to book with us, contact us at any time here and we'll get back to you as soon as possible."/>
        <title>Contact Mirage VR | Best Virtual Reality Arcade in Ontario</title>
    </Helmet>
    <article id="main">
        <header>
            <div  class="heavy-text" >
            <h2>Contact Us</h2>
            </div>
        </header>
        <section class="wrapper style5">
			<div class="inner">
				<div id="message" class="inner fit vertical">
                    {/* 
                    <p>For <b>faster</b> response, start a<b><span class="mirage-teal"><a href="javascript:void(Tawk_API.toggle())"> Live Chat</a></span></b>!</p>
                    */}
                    <Form 
                        tag = {'general'}
                        organizationText={'Organization(Optional)'}
                        messageText={'Leave us a message and we will get back to you very soon!'}
                    />
                </div>
            </div>
        </section>
    </article>
    </div>
);
export default ContactPage;

/*

*/