import React from 'react';
import Helmet from 'react-helmet';

import Form from './ContactForm';

export default class PartyPackagePage extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            showModal: false,
            mobile: ($(window).width() <= 736)? true : false
        };
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
    }

    componentDidMount(){ 
        $(window).resize(() => {
            //console.log('window was resized');
            if($(window).width() <= 736){
                this.setState(()=> ({mobile: true}));
            }
            else {
                 this.setState(()=> ({mobile: false}));
         }
        });              

        // hotjar tracking
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1024291,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    render(){
        return(
            <div>
                <Helmet>
                    <style></style>
                    <meta charSet="utf-8" />
                    <title>Party Package | Mirage VR</title>
                    <link rel="canonical" href="https://www.miragevr.ca/partypackage"/>
                </Helmet>
                <article id="main"  >

                <header className="partypackage-bg">
                    <div className="heavy-text" >
                        <h2>Party</h2>
                        <p>A Unique Event <span className="mirage-teal">For Any Occasion</span></p>
                    </div>
                </header>
                
                        <article className="wrapper style3 special no-border" style={{padding:'0em 2em 1em 2em'}}>
                           <section>                    
                                <div className="inner">
                                  <section style={{margin:'0em 0em 2em 0em'}}>
                                      <div className="row events-occasions" >
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  11-20
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Players</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  90+
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">minutes</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  8+
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Ages</span>
                                              </p>
                                          </div>
                                          <div className="col-6 col-md-3 align-center">
                                              <p className="threeem" style={{margin:'1em 0em 0em 0em'}}>
                                                  $28
                                                  <br/>
                                                  <br/>
                                                  <span className="halfem">Per Player</span>
                                              </p>
                                          </div>
                                      </div>
                              </section>
                                </div>
                            </section>
                        </article>
                    
                <section id="two" className="wrapper alt style2">
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/privateevents.jpg")} alt="" /></div><div className="content">
                           <h2>Its Time To Party!</h2>
                           <p></p>
                           <p> A party is no fun without party games, so we've laid out all our VR party games for you to experience! Your group
                               will be broken down into <span className="mirage-teal">teams of 5</span>, and rotate between different VR activities. 
                           </p>
                           <p>     
                               Save a forest from destruction, stop a bomb from exploding, and
                               guess funny pictures drawn by your friends. 
                           </p>
                           <a id="gamepage-book" className="button small special" href="#bookparty" alt="" >Book Party Event</a>

                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/sherwood-hammer-boss-2.jpg")} alt="" /></div><div className="content">
                           <h2>Sherwood Forest</h2>
                           <p></p>
                           <p> The once healthy Sherwood Forest is being attacked by evil creatures. You are a team of heroes, each
                               granted unique powers by the gods. Your mission is twofold. First, defend the forest's magical obelisk with your life. Second,
                               beat your friends and family in a dance showdown!
                            </p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/ktane.jpg")} alt="" /></div><div className="content">
                           <h2>Keep Talking and Nobody Explodes</h2>
                           <p></p>
                           <p> You wake up and discover that you are locked in a room with a bomb! Carefully communicate with your teammates to figure out how to defuse it, before you are blown to smithereens!
                           </p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/tiltbrush.jpg")} alt="" /></div><div className="content">
                           <h2>VR Pictionary</h2>
                           <p></p>
                           <p>Put your creativity to the test in a game of Pictionary, but in VR! The entire room is your canvas and you are only limited by your own imagination.
                           </p>
                        </div>
                    </section>
                    <section className="spotlight">
                        <div className="image"><img src={require("../images/partyroomteens.jpg")} alt="" /></div><div className="content">
                           <h2>Bonus: Complimentary Party Room!</h2>
                           <p></p>
                           <p> We offer a party room for you free of charge, for you to cater food from nearby restaurants!
                           </p>
                           <p>
                               Note: We sell water and pop, but not alcohol.
                           </p>
                        </div>
                    </section>
                </section>
                    
                <section className="wrapper style5">
                    <div className="inner">
                      <section>
                      <header className="major align-center ">
                      <h3 style={{color:'#2E3842'}}>Experience Features</h3>
                      </header>
                      <div className="row game-features">
                        <div className="col-sm-12 col-md-4 $(medium) align-center">
                          <img className="lazy" src={require("../images/freeroam.png")}/>
                          <h3>Free-Roam<br/> VR</h3>
                          <p>Explore deep forests and sail in an endless ocean. Let your senses be the guide as you physically explore the edge of your imagination.</p>
                        </div>
                        <div className="col-sm-12 col-md-4 $(medium) align-center">
                          <img className="lazy" src={require("../images/collaborative.png")}/>
                          <h3>Collaborative<br/> Adventure</h3>
                          <p>Feel the adrenaline rushing through your body as you work together to discover the unexpected and face the impossible. Are you ready?</p>
                        </div>
                        <div className="col-sm-12 col-md-4 $(medium) align-center">
                          <img className="lazy" src={require("../images/topscore.png")}/>
                          <h3>Compete For <br/> High Score</h3>
                          <p>Based on your performance in virtual reality, we give you both an individual and team score. Duke it out with friends to reach the top!</p>
                        </div>
                      </div>
                      </section>
                    </div>
                </section>

                <article className="wrapper style3 special no-border" style={{padding:'2em 2em 1em 2em'}}>
                   <section>                    
                        <div className="inner">
                            <section style={{margin:'2em 0em 2em 0em'}}>
                                    <div className="row events-occasions" >
                                        <div className="col-sm-12 col-md-6 $(medium) align-center">
                                           <img src={require('../images/pricestamp.png')} style={{width:'50%'}}/>
                                            <h5 style={{
                                                textTransform: 'capitalize',
                                                fontSize: '1.2em',
                                                }}>Price</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '1.2em',
                                                }}>$28/player <br/><br/><br/>
                                            </p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 $(medium) align-center">
                                           <img src={require('../images/moneybackguaranteestamp.png')} style={{width:'50%'}}/>
                                           <h5 style={{
                                                textTransform: 'capitalize',
                                                fontSize: '1.2em',
                                                }}>Guarantee</h5>
                                            <p style={{
                                                marginBottom: '0',
                                                lineHeight: 'normal',
                                                fontSize: '1.2em'
                                            }}>We are so sure that you will totally love the experience that we offer a 100% refund if you are not completely satisfied.</p>
                                        </div>
                                    </div>
                            </section>
                        </div>
                    </section>
                </article>

                <a id="bookparty"></a>
                <section className="pricing wrapper style2">
                    <div className="inner">
                        <div id="message" className="inner fit vertical">
                            <h2>Book Your Party Event Now!</h2>
                            <br/>
                            <p> Let us know what kind of event you have in mind, and a Mirage VR Representative will get back to you within 24 hours. Please include the <span className="mirage-teal">date, time and group size</span> you would like to book for. </p>
                            <Form tag={'partypackage'} />
                        </div>
                    </div>
                </section>
            </article>
        </div>
        )
    }
}


/*Removed Rows */

/* 
<tr style="display:none">
                                                <td style="vertical-align: middle;"><i class="icon fa-clock-o" style="
                                                   color: #ed4933;
                                                   font-size: 3em;
                                                   opacity: 0.8;
                                                   "></i></td>
                                                <td style="
                                                   vertical-align: middle;
                                                   ">
                                                   <div style="
                                                      ">
                                                      <h5 style="text-transform: capitalize;">Length</h5>
                                                      <p style="
                                                         margin-bottom: 0;
                                                         line-height: normal;
                                                         font-size: 0.9em;
                                                         ">
                                                         30 minute reservation<br>
                                                         * 5 minute introduction &amp; virtual training <br>
                                                         * 20 minute game experience <br>
                                                         * 5 minute back-to-reality &amp; debrief
                                                      </p>
                                                   </div>
                                                </td>
                                             </tr>
*/

/* <tr style="display:none">
                                                <td style="vertical-align: middle;"><i class="icon fa-star-o" style="
                                                   color: #ed4933;
                                                   font-size: 3em;
                                                   opacity: 0.8;
                                                   "></i></td>
                                                <td>
                                                   <div>
                                                      <h5 style="
                                                         text-transform: capitalize;
                                                         ">Difficulty</h5>
                                                      <p style="
                                                         margin-bottom: 0;
                                                         line-height: normal;
                                                         font-size: 0.9em;
                                                         ">Available in casual or hardcore mode</p>
                                                   </div>
                                                </td>
                                             </tr>
*/
