import React from 'react';
import Form from './ContactForm';
import Helmet from 'react-helmet';
import ClientLogos from '../components/ClientLogos';

export default class EventsPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mobile: ($(window).width() <= 763)? true : false,
        }
    }

    render(){
        return(
        <article id="main">
            <Helmet>
                <style></style>
                <meta charSet="utf-8" />
                <title>Virtual Reality & Escape Room Events in Ontario | Mirage VR</title>
                <meta name="description" content="Throw a free-roam virtual reality event with us & treat your guests to one of the best events they'll ever go to! Learn about what events pair best with VR & book today!"/>
                <link rel="canonical" href="https://www.miragevr.ca/events"/>
            </Helmet>
            <header className="teambuilding-bg">
                <div className="heavy-text" >
                    <div className="likeh2"><span className="mirage-teal">Virtual Reality</span> Events</div>
                    <p>A new way, a <span className="mirage-teal">better</span> way</p>
                </div>
            </header>

            <ClientLogos />

            <section id="one" className="wrapper style2 special">
                <div class="inner">
                    <header class="major">
                        <h1>Have The Most Fun Throwing a Virtual Reality Event!</h1>
                        <br/><br/>
                        <p>Mirage VR is Canada's premium virtual reality facility, specifically tailored for events & special occasions.
                           Our free-roaming, group virtual reality experiences are built for every type of event, whether its 
                           team building, birthday parties, or pop-ups. Our event space allows us to flexibility accomodate your needs, 
                           while offering world-class entertainment to your guests. Our staff team is friendly, attentative, and knowledgeable, ensuring
                           a superb experience both in and outside of virtual reality. We guarantee that any event done with us
                           will end with amazement, wonder, and a whole lot of laughter.
                        </p>
                    </header>
                </div>
            </section>

            <section id="one" className="wrapper style2 special">
                <div class="inner">
                    <header class="major">
                        <h2>Virtual Reality Events Offered</h2>
                        <br/><br/>
                        <p>
                        We are equipped to handle any kind type of event, due to our modular nature and
                        the flexibility of our space. However, we specialize in handling the following types of events:
                        </p>
                        <ul class="nobullet">
                            <li>Team Building</li>
                            <li>Birthday Parties</li>
                            <li>Private Parties</li>
                            <li>Conferences</li>
                            <li>Holiday Parties</li>
                            <li>Field Trips</li>
                            <li>Mobile Events</li>
                            <li>Pop-ups</li>
                            <li>Launch Parties</li>
                        </ul>
                        <p>
                        We offer different types of experiences and packages depending on the nature of your event and the number
                        of attendees.
                        </p>

                        <ul class="nobullet">
                            <li>Group Experiences</li>
                            <li>Individual Experiences</li>
                            <li>Party Mode</li>
                            <li>Free Roam Virtual Reality</li>
                            <li>Fantasy, Sci-Fi, Puzzle-Solving, Adventure</li>
                            <li>Co-operative & Competitive Experiences</li>
                        </ul>

                        <p>
                        See our experiences page for more details on the experiences offered, and what would best serve your needs.
                        </p>
                    </header>
                </div>
            </section>

            <section id="one" className="wrapper style3 special">
                <div class="inner">
                    <header class="major">
                        <h3>Team Building VR Events</h3>
                            <p>
                            A good team building event is not only fun, but develops a strong bond between team members, 
                            lasting far into the future. Our virtual reality experiences place team members
                            into the shoes of powerful heroes, where they must work together to save the world. There is no acting
                            or role-playing here - participants will be mesmerized as their wildest imaginations come to life around 
                            them. Through virtual reality teamwork, coordination, and strategization, participants 
                            must complete a journey that is both engaging and challenging. With every challenge that team members 
                            overcome, a stronger connection & chemistry is forged between them. Mirage VR specializes in this
                            type of team building experience.
                            </p>
                    </header>
                </div>
            </section>

            <section id="one" className="wrapper style3 special">
                <div class="inner">
                    <header class="major">
                        <h3>Corporate Virtual Reality Events</h3>
                            <p>
                            Planning a corporate party? We'd love to host you! Every event has its own unique requirements, and we're here
                            to accommodate you! You can come to our facility, or we can come to you. Our state-of-the-art technology and experiences 
                            will enhance your corporate brand to your guests. And of course, we provide world class virtual reality to entertain your guests,
                            no matter who they are. Our friendly and knowledgeable staff will be on site to guide you through every 
                            step of the experience. We offer a wide variety of experiences, from fantasy role-playing to zombie-shooting madness. 
                            We have done virtual reality corporate events with companies like Google & Shopify. Will yours be next?
                            </p>
                    </header>
                </div>
            </section>

            <section id="one" className="wrapper style2 special">
                <div class="inner">
                    <header class="major">
                        <h3>Free-Roaming VR Birthday Parties</h3>
                            <p>
                            If you want to have the coolest birthday party on earth, then look no further. Experience a mind-shattering
                            adventure as you and your close ones step into a world beyond your dreams. This is a unique and innovative experience 
                            where you can dance, hug, and battle using your own body! Our virtual reality birthday parties
                            are always filled with wonder, laughter, and a whole lot of funny instagram posts. So whether its your 10th or your
                            50th birthday, our adventures will be an absolute blast! If you can move your body, you can enjoy Mirage VR!
                            We also offer a complimentary party room, free of charge.
                            </p>
                    </header>
                </div>
            </section>

            <section id="two" className="wrapper alt style2">
                <section className="spotlight">
                    <div className="image"><img src={require("../images/kidsbirthdayparty.jpg")}/></div><div className="content">
                    <h4>Birthday Parties For Kids</h4>
                    <br/>
                    <p>Our experiences are made to be suitable for all ages, and our formats tailored for birthday parties. Kids 
                    adore our experiences because they are intuitive and require no learning.</p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src={require("../images/teamwork.jpg")}/></div><div className="content">
                    <h4>Birthday Parties For Adults</h4>
                    <br/>
                    <p>Throw a virtual reality birthday party and escape the boredom of real life. Immerse yourself 
                    and your loved ones into a world filled with the best type of fun and wonder - the feeling that anything is possible. 
                    We specialize in creating these moments for you, that last far into the future.</p>
                    </div>
                </section>
            </section>

            <section className="faq-inline wrapper style3 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                <div class="inner">
                    <header class="major">
                        <h2>Book Your VR Event Today!</h2>
                    </header>
                        <br></br>
                        <p>
                        Let us know what kind of event you have in mind in the form below. We'll coordinate to make it
                        an amazing experience for everyone.
                        </p>
                    
                </div>
            </section>

            <a id='rates' href='#rates'></a>

            <section className="pricing wrapper style5">
                <div className="inner">
                    <p>If accommodation is needed for special events (such as booking the space for an entire afteroon), simply include that in the message.</p>
                    <hr/>

                    <a name="20+"/>
                    <div id="message" className="inner fit vertical">
                        <br/>
                        <Form
                            showOrganization={true}
                            organizationText={'Organization'}
                            messageText={'Please include the date,time and group size you would like to book for.'}
                        />
                    </div>
                </div>
            </section>

            <section className="faq-inline wrapper style7 special no-border" style={{padding: '2em 2em 1em 2em'}}>
                <div className="inner">
                    <header className="major less-heavy-text">
                        <div className="likeh2">See our locations</div>
                    </header>
                    <div className="row">
                        <div className="col-12 col-sm-4"><h3 id="gamepage-book" className="button" onClick={this.openBookModal} alt="">Kitchener, ON</h3></div>
                        <div className="col-12 col-sm-4"><h3 id="gamepage-book" className="button" onClick={this.openBookModal} alt="">Mississauga, ON</h3></div>
                        <div className="col-12 col-sm-4"><h3 id="gamepage-book" className="button" onClick={this.openBookModal} alt="">Burlington, ON</h3></div>
                    </div>
                </div>
            </section>
    </article>
        )
    }
};

