import React from 'react';
import LocationChooser from './modals/LocationChooser';
import NumberChooser from './modals/NumberChooser';

const FootStyle = {
  paddingTop:"0.75rem",
  paddingBottom:"0.25rem",
  margin: "0"
}

const ButtonStyle = {
    padding: "0"
}

export default class BottomNav extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        showModal: false,
        chooseNumber: false,
        mobile: ($(window).width() <= 763)? true : false,
        phoneNum: this.getPhoneNum(),
        onBookingPage: window.location.pathname.indexOf('book') > -1
      }
      this.openModal = this.openModal.bind(this);
      this.exitModal = this.exitModal.bind(this);
      this.showNumber = this.showNumber.bind(this);

    }

    getPhoneNum(){
        var chuang = "15197215399";
        var store = "12268981261";
        var tollfree = "18335685234"
        return tollfree;

        var d = new Date();
        var day = d.getDay();
        var hour = d.getHours();
        if (day == 1 || day == 2) {
            return chuang;
        } else if (day == 3 || day == 4 || day == 5) {
            if(hour < 15) return chuang;
            else return store;
        } else if (day == 6 || day == 0) {
            if (hour < 12) return chuang;
            else return store;
        }
    }

    openModal(){
      this.setState(()=> ({showModal: true}));
    }

    exitModal(){
      this.setState(()=> ({showModal: false}));
      this.setState(()=>({chooseNumber: false}));
    }

    showNumber() {
        this.setState(()=>({chooseNumber: true}));
    }

    componentDidMount() {
        //TAWK.TO
        window.onhashchange = function() { 
            //console.log('url changed');
            if (Tawk_API) {
                Tawk_API.hideWidget();
            }
        };

        $(document).ready(function(){
            Tawk_API = Tawk_API || {};
            Tawk_API.onLoad = function() {
                //console.log("hiding widget");
                Tawk_API.hideWidget();
            };
        });
    }

    goToFAQ() {
        var url = window.location.href;
        if (url.indexOf('birthday')>0) {
            location.href='/faq#Birthday';
        } else if (url.indexOf('teambuilding')>0) {
            location.href="/faq#Teambuilding";
        } else {
            location.href='/faq';
        }
    }

    render(){
      return(
          <div>
            <footer id="bottomNav" className="container">
                <div className="row" style={{backgroundColor:'teal',paddingTop:'0.5em', boxShadow: '2px 2px 20px #333'}}>
                    <div className="col-3 align-center">
                        <a onClick={this.showNumber} className="icon fa-phone"><span className="label">Call</span></a>
                        <p>Call Us</p> 
                    </div>
                    <div className="col-3 align-center">
                        <a onClick={this.openModal} className="icon fa-map-marker"><span className="label">Direction</span></a>
                        <p>Direction</p> 
                    </div>
                    <div className="col-3 align-center">
                        <a onClick={this.goToFAQ} className="icon fa-info"><span className="label">Questions</span></a>
                        <p>FAQ</p> 
                    </div>
                    <div className="col-3 align-center">
                        <a href="javascript:void(Tawk_API.toggle())" className="icon fa-comment"><span className="label">Chat</span></a>
                        <p>Live Chat</p> 
                    </div>
                </div>
            </footer>
            <LocationChooser
            showModal={this.state.showModal}
            exitModal={this.exitModal}
            mobile={this.state.mobile}
            showMap = {true}
            />

            <NumberChooser
            showModal={this.state.chooseNumber}
            exitModal={this.exitModal}
            mobile={this.state.mobile}
            />
          </div>
      )
    }
}

