import React from 'react';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import DiscountSentModal from './DiscountSentModal';

//handles switch what the modal displays at each step, the form content is handled by ModalForm

export default class LeadMagnetModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showModal: false,
            showDiscountSent: false,
            firstName: '',
            lastName: '',
            email: '',
        }
        this.openModal = this.openModal.bind(this);
        this.exitModal = this.exitModal.bind(this);
        this.openDiscountSentModal = this.openDiscountSentModal.bind(this);
        this.exitDiscountSentModal = this.exitDiscountSentModal.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //Modals
        /*this.exitModal = this.exitModal.bind(this);
        this.switchModalUserInfo5 = this.switchModalUserInfo5.bind(this);
        this.switchModalUserInfo10 = this.switchModalUserInfo10.bind(this);
        this.switchModalGroupReservation = this.switchModalGroupReservation.bind(this);
        this.switchModalThankYou = this.switchModalThankYou.bind(this);
        this.switchModalError = this.switchModalError.bind(this);*/
    }

    openModal(){
        this.setState(()=> ({showModal: true}));
    }

    exitModal(){
        this.setState(()=> ({showModal: false}));
    }

    openDiscountSentModal(){
        this.setState(()=> ({showDiscountSent: true}));
        setTimeout(this.exitDiscountSentModal, 1500);
    }

    exitDiscountSentModal(){
        this.setState(()=> ({showDiscountSent: false}));
        this.props.exitModal();
    }

    handleFirstNameChange(e){
        this.setState({firstName: e.target.value});
    }

    handleLastNameChange(e){
        this.setState({lastName: e.target.value});
    }

    handleEmailChange(e){
        this.setState({email: e.target.value});
    }
    
    handleSubmit(e){
        e.preventDefault();

        var firstName = this.state.firstName.trim();
        var lastName = this.state.lastName.trim();
        var email = this.state.email.trim();

        $.ajax({
            url: '/subscribe/leadMagnet',
            type: 'POST',
            data: {
                firstName: firstName,
                lastName: lastName,
                email: email
            },
            success: function(data) {
                this.setState({data: data});
                //console.log("posted to subscribe");
                const cookies = new Cookies();
                cookies.set('firstName', {firstName}, { path: '/' });
                //console.log('cookies:', cookies.get('firstName'));
                cookies.set('lastName', {lastName}, { path: '/' });
                //console.log('cookies:', cookies.get('lastName'));
                cookies.set('email', {email}, { path: '/' });
                //console.log('cookies:', cookies.get('email'));
                this.openDiscountSentModal();
            }.bind(this),
            error: function (err)
            { console.log(err.responseText);
                this.props.switchModalError()
            }.bind(this)
        });

        // Hash to avoid PII.
        var userID = window.stringHash(email);
        ga('set', 'dimension1', userID);
        ga('set', 'userId', userID);
        mixpanel.alias(email);
        mixpanel.identify(email);
        mixpanel.track('Filled new years lead');
    }

    render(){
        return(
        <Modal
                isOpen={this.props.showModal}
                onRequestClose= {this.props.exitModal}
                ariaHideApp={false}
                closeTimeoutMS={200}
                className="vrpopupmodal"
                overlayClassName="overlay"
                >
            <div id="popup-content">
                <article>
                    <span className="image fit"><img src={require("../../images/popsicle.jpg")} alt=""/></span>
                    <h2 className="mirage-white">FREE ICE CREAM FOR <span className="mirage-teal">EVERYONE</span></h2>
                    <br></br>
                    <p>Cool off this summer and get a free <b className="mirage-teal">ICE CREAM </b> 
                    with your VR Session!
                    </p>
                    <span>
                    <a className="button teal" onClick={this.props.exitModal} alt="">OK</a>
                    </span>
                    <br></br>
                    <br></br>
                </article>
                <DiscountSentModal
                    showModal={this.state.showDiscountSent}
                    exitModal={this.exitDiscountSentModal}
                /> 
            </div>
        </Modal>
 
        )
    }
};